import classNames from 'classnames';
import EmptyuserIcon from '../../assets/icons/empty-user-image.svg';
import UserDummyImage from '../../assets/images/UserDummy.svg';
import { getUIAvatar } from '../../adaptors/web/utilsService';


export default function UserThumb(props) {
  let className = classNames({
    'user-thumb ': true,
    [props.className]: props.className ? true : false
  });

  let thumbnail = props.thumbnail;

  if (!props.thumbnail) {
    if (props.firstName != null && props.lastName !=null ) {
      thumbnail = getUIAvatar(props.firstName, props.lastName);
    }
  }

  return (
    <div  className={className}>
      <img src={thumbnail || UserDummyImage} className="img-responsive btn-icon" alt="user thumbnail" width={props.width || "32"} height={props.height || "32"} />
    </div>
  );
}