import classNames from 'classnames';


export default function KeyValue(props) {
  const { keyName="Key", value="Value" } = props;
  let className = classNames({
    'row keyvalue': true,
    [props.className]: props.className ? true : false
  });

  return (
    <div className={className}>
      <div className="col-md-4 col-sm-12">
        <p className="p4">{keyName}</p>
      </div>
      <div className="col-md-5 col-sm-12 value">
        <p className="p4">{value}</p>
      </div>
    </div>
  );
}