import React from 'react';
import filesize from 'filesize';
import Button from '../Button';
import DownloadIcon from '../../assets/icons/download.svg';
import filenameFromUrl from '../../utils/filenameFromUrl';
import { download } from '../../adaptors/xhr/trainingService';

function FileAlert(props) {

  function openFile() {
    // if (props.fileUrl) {
    //   window.open(props.fileUrl, "_blank");
    // }
    props.previewFile({file: props.fileUrl, size: props.fileSize, title: props.name, file_type: props.fileType, sourceFile: props.sourceFile, sourceFileType: props.sourceFileType})
  }

  function downloadFile() {
    var fileName
    if(props.sourceFile){
      fileName = filenameFromUrl(props.sourceFile)
      download(props.sourceFile, fileName);
    }
    else{
      fileName = filenameFromUrl(props.file)
      download(props.file, fileName);
    }
    
  }

  return (
    <div className="file-alert">
      <div className="row">
        <div className="col-lg-8">
          <span>{props.name}</span>
          <small>{props.sourceFileType}</small>
          <small> • </small>
          <small>{`${props.fileSize && !isNaN(props.fileSize) ? filesize(props.fileSize) : ''}`}</small>
        </div>
        <div className="col-lg d-flex justify-content-between align-items-center">
          <Button size="md" text="View" className="mr-2" onClick={openFile} />
          <Button type="outline" size="md" text="Download" onClick={downloadFile} icon={DownloadIcon} />
        </div>
      </div>
      
    </div>
  );
}

export default FileAlert;