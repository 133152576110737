import classNames from 'classnames';
import EmptyuserIcon from '../../assets/icons/empty-user-image.svg'
import UserThumb from '../UserThumb';


export default function ProfileCard(props) {
  let className = classNames({
    'profile-card d-flex justify-content-start align-items-center': true,
    [props.className]: props.className ? true : false
  });

  return (
    <div className={className}>
      <UserThumb 
        className="mr-2" 
        width={72} 
        height={72} 
        thumbnail={props.thumbnail} 
        firstName={props.firstName} 
        lastName={props.lastName} 
      />
      <div className="">
        <h4>{props.firstName} {props.lastName}</h4>
        <p className='p4'>{props.bio}</p>
        <p className="p4 linkedin">{props.linkedinUrl}</p>
      </div>
    </div>
  );
}