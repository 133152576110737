import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import { addModule } from './ducks/actions';
import TopNavbar from '../../components/TopNavbar';
import PageFooter from '../../components/PageFooter/PageFooter';
import GettingStarted from '../../components/GettingStarted';
import GettingStartedFooter from '../../components/GettingStartedFooter'
import { routes } from '../../routes';
import api from '../../adaptors/xhr/api';
import { addGettingStartedContent } from '../Common/ducks/actions';
import { getGettingStartedContent } from '../Common/ducks/selectors';
import NavbarGettingStarted from '../../components/NavbarGettingStarted';


function GettingStartedModuleStructure(props) {
  const fetchContent = async () => {
    const response = await api.Content.gettingStarted();
    if(response.data)  props.addGettingStartedContent(response.data);
  }

  useEffect(() => {
    fetchContent();
  }, []);

  return (
  <>
    <TopNavbar />
    <Navbar />
    <Breadcrumb 
      breadcrumbs={["Home", "Getting Started with TRP", "Module Structure"]} 
      links={["/",  routes.gettingStartedNavigatingTheWebsite,routes.gettingStartedNavigatingTheWebsite]}
      className="getting-started-breadcrumbs"
    />
    <PageHeader heading={ "Getting Started with TRP" } textAlign={"center"}>
      <p className="p2">Short guide to understand module structure</p>
    </PageHeader>
    <NavbarGettingStarted/>
    {/* ..... */}
    <GettingStarted
      title={props.gettingStartedContent?.title} 
      description={props.gettingStartedContent?.description}
      carouselPhotos={props.gettingStartedContent?.carousel_photos}
    />
    <GettingStartedFooter quick_start_guide={props.gettingStartedContent?.quick_start_guide} adaptation_checklist={props.gettingStartedContent?.adaptation_checklist} />
    <PageFooter/>
  </>
  );
}

const mapStateToProps = state => {
  const gettingStartedContent = getGettingStartedContent(state);
  return { gettingStartedContent };
};

export default connect(
  mapStateToProps,
  { addModule, addGettingStartedContent }
)(GettingStartedModuleStructure);