import types from "../types";

const initialState = {
    currentUser: {}
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_USER: {
      const { user } = action.payload;
      return {
        ...state,
        currentUser: user
      };
    }
    case types.LOGOUT_USER:
        return {...state, currentUser: {} }
    default:
      return state;
  }
}