import React, { useEffect, useState, useRef } from 'react';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import timeagoFromDate from '../../utils/timeagoFromDate';
import UserThumb from '../UserThumb';
import Button from '../Button';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import FormInputLarge from '../FormInputLarge';
import { commentSchema, validateComment, calculateWords } from '../../pages/Community/ducks/validations';
import api from '../../adaptors/xhr/api';


const PostComment = React.forwardRef((props, ref) => {

  const [ activeKey, setActiveKey ] = useState('weekly');
  let { path, url } = useRouteMatch();

  let className = classNames({
    'post-comment-section': true,
    [props.className]: props.className ? true : false
  });

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, setFieldError, resetForm}) => {
    if (!props.author?.email) {
      props.promtLogin();
      return;
    }
    // inputRef.current.focus();
    setSubmitting(true);
    const { comment } = values;
    try {
      // create user
      const response = await api.Forum.postComment(props.dicussionId, comment);
      if (response.status === 201) {
        // succesfully created post
        resetForm();
        props.onPostSuccess(props.dicussionId, response.data)
      }
    }
    catch (error) {
      // read server validation errors
      //if (error.response.data?.comment) setFieldError('comment', error.response.data?.comment);
      if (error.response.data?.comment) setFieldError('comment', "Comment cannot exceed 200 words in length");
      console.error(error);
    }
    setSubmitting(false);
  }

  return (
    <div className={className} ref={ref}>

      <div className="d-flex justify-content-start align-items-start my-3">
        <UserThumb
          className="mr-4"
          thumbnail={props.author.profile_picture}
          firstName={props.author?.first_name}
          lastName={props.author?.last_name}
        />
        <Formik
          // validationSchema={commentSchema}
          validate={validateComment}
          onSubmit={submitHandle}
          initialValues={{
            comment: '',
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form className="form" noValidate onSubmit={handleSubmit}>
              <div className="">
                {/* <textarea className="form-control" placeholder="Type to reply...">

                </textarea> */}
                <FormInputLarge
                  name="comment"
                  label=""
                  placeholder="Type to reply..."
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // isValid={touched.comment && !errors.comment}
                  isInvalid={touched.comment && !!errors.comment}
                  feedback={errors.comment}
                  help={`${calculateWords(values.comment)}/200 words`}
                  // ref={inputRef}
                  // ref={ref}
                  formType={"Comment"}
                  linkHandler={props.linkHandler}
                  quillRef={props.quillRef}
                />
                <Button type="primary" size="lg" text={isSubmitting ? "Posting Reply..." : "Post Reply"}  submit minWidth />
              </div>
            </Form>
          )}
          </Formik>


      </div>
    </div>
  );
})

export default PostComment;
