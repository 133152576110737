import types from "../types";
import { combineReducers } from "redux";

function topicsById(state = {}, action) {
  switch (action.type) {
    case types.ADD_TOPIC: {
      const { payload } = action
      const { topicId, name } = payload
      const topicObj = { id: topicId, name: name }
      return {
        ...state,
        [topicId]: topicObj
      }
    }
      
    default:
      return state
  }
}

function alltopics(state = [], action) {
  switch (action.type) {
    case types.ADD_TOPIC: {
      const { payload } = action
      const { topicId } = payload
      return state.concat(topicId)
    }
    default:
      return state
  }
}

const topicsReducer = combineReducers({
  byId: topicsById,
  allIds: alltopics
})

export default topicsReducer;