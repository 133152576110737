import React from 'react';
import {
  Link,
} from "react-router-dom";
import classNames from 'classnames';
import ArrowIcon from '../../assets/icons/arrow.svg';

function ModuleList(props) {
  const { category = "" } = props;
  const modulesInCategory = props.modules.filter(module => module.categoryId == props.category.id)
  let className = classNames({
    "module-list": true,
    [props.className]: props.className ? true : false
  });
  if(modulesInCategory.length === 0){
    return null
  }
  return (
      <div className={className}>
        <ul className="list-group list-group-flush">
          {props.showCategory?<p className="c1 category">{category.name}</p>:null}
          
          {modulesInCategory?.map(module => {
            return (
              <li href="#" key={`module-${module.id}`}  className="list-group-item list-group-item-action active" aria-current="true">
                <Link 
                  className="list-item"
                  key={`module-${module.slug}`} 
                  to={`/training/${module.slug}/`}>
                <div className="d-flex w-100 justify-content-between align-items-center">
                  <div className="module-name" >
                    {module.name}
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={ArrowIcon} className="img-responsive" alt="arrow icon" width="16" height="13.4" />
                  </div>
                </div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
  );
}

export default ModuleList;