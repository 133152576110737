import React from 'react';
import secToMin from '../../utils/secToMin';
import VideoPreview from '../VideoPreview/VideoPreview';

function VideoBlockFrench(props) {
  const trimVideoTitle = (title) => {
    if (title.length >= 30) {
      const newTitle = `${title.slice(0, 35)}....`
      return newTitle
    }
    return title
  }
  return (
    <>
      <div className="container-xl video-block-container">
        <div className="row training-section ">
          {/* <div className="col-md-4 pr-lg-5">
                <h4>Videos</h4>
                <p className="p3 mx-0">{props.videoDecs}</p>
              </div> */}
          <div className="col">
            <div className="container">
              <div className="row row-cols-3">
                {props.videoData.map((item) => {
                  return (
                    <div className="col-4 d-flex" style={{ marginBottom: "40px" }}>
                      <div className="video-thumbnail">
                        <VideoPreview videoData={item} />
                      </div>
                      <div className="video-details">
                        <div className="video-name">{trimVideoTitle(item.video_title)}</div>
                        <div className="video-duration">{secToMin(item.duration)}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoBlockFrench;