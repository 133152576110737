import React, { useEffect, useRef, useState, useMemo } from 'react';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import Button from '../Button';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import AddLinkModal from '../AddLinkModal/AddLinkModal';

function FormInput(props) {
  let className = classNames({
    // "mb-3": true,
    "required": props.required ? true : false,
    [props.className]: props.className ? true : false
  });

  let quillModules = {
    toolbar: {
      handlers: {
        link: props.linkHandler
      },
      container: '#toolbar',
    }
  }

  const modules = useMemo(() => quillModules, []);

  useEffect(() => {
    // const elems = document.getElementsByClassName("ql-link");
    // console.log(elems);
    // if (elems.length > 0) {
    //   elems[0].textContent = "Add Link";
    //   elems[0].classList.add("button-default", "button-tertiary");
    // }

    // if (quillRef && quillRef.current) {
    //   console.log(quillRef.current.getEditor());
    //   setQuillEditor(quillRef.current.getEditor());
    // }
  }, [])

  let buttonClassName = classNames({
    "ql-link": true,
    "w-100": true,
    "button-default": false,
    "button-outline": false,
  });

  return (
    <Form.Group className={className}>
      <div className="d-flex align-items-center justify-content-between">
        {props.label ?
          <Form.Label>{props.label}</Form.Label>
          : null
        }
        {props.formType === "Post" ? <div id="toolbar" className='comment-formats align-self-end'>
          {/* <Button className={buttonClassName} text="<p>Add Link</p>" icon={LinkIcon} type="button" /> */}
          <button className='ql-link p-0 m-0' style={{ width: '100%', border: 'none !important', color: 'black', marginBottom: '0.5rem', fontSize: '13px' }}>
            Add link
          </button>
        </div> : null}
      </div>


      {/* <Form.Control
          as="textarea"
          rows={3}

          // type={props.type}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onBlur={props.handleBlur}
          isValid={props.isValid}
          isInvalid={props.isInvalid}
          disabled={props.disabled}
          ref={props.ref}
          className="mb-0"
        /> */}
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={[
          'link'
        ]}
        name={props.name}
        value={props.value}
        placeholder={props.placeholder}
        onChange={(value) => {
          let e = {
            target: {
              value: value,
              name: props.name,
            }
          }
          props.onChange(e);
        }}
        onBlur={props.handleBlur}
        isValid={props.isValid}
        isInvalid={props.isInvalid}
        disabled={props.disabled}
        ref={props.quillRef}
        className={props.isInvalid ? 'is-invalid' : ''}
      />
      <div className="comment-actions d-flex justify-content-between mt-2">
        {props.help ?
          <span className="p4">{props.help}</span>
          : null
        }
        {props.formType === "Comment" ? <div id="toolbar" className='comment-formats align-self-end'>
          {/* <Button className={buttonClassName} text="<p>Add Link</p>" icon={LinkIcon} type="button" /> */}
          <button className='ql-link p-0 m-0' style={{ width: '100%', border: 'none !important', color: 'black', marginBottom: '0.5rem', fontSize: '13px' }}>
            Add link
          </button>
        </div> : null}
      </div>
      <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback>
    </Form.Group>
  );
}

export default FormInput;
