import React from 'react'
import { routes } from '../../routes';
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";
import { propTypes } from 'react-bootstrap/esm/Image';

function LoginPrompt(props) {
    return (
        <div className="login-prompt" >
            <p><Link onClick={props.promtLogin}>Log in</Link> to post a reply. If you don't have an account <Link to={routes.signup}>Sign up</Link> here.</p>
        </div>
    )
}

export default LoginPrompt
