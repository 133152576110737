import React, { useEffect, useState } from 'react';
import TopNavbar from '../../components/TopNavbar';
import Navbar from '../../components/Navbar';
import News from '../../adaptors/xhr/newsService';
import dateFromString from '../../utils/dateFromString';
import Button from '../../components/Button';
import SubscribeSection from '../../components/SubscribeSection';
import PageFooter from '../../components/PageFooter/PageFooter';
import { addNews } from './ducks/actions';
import { getNews } from './ducks/selectors';
import { connect } from "react-redux";
import isExternalLink from '../../utils/isExternalLink';
import { useLocation } from 'react-router-dom';
import SourceLinkIcon from '../../assets/icons/source-link.svg';

function NewsAndUpdates(props) {
  const [page, setPage] = useState(1)
  const [showButton, setShowButton] = useState(true);

  const fetchData = async () => {
    const response = await News.listWithPagination(page)
    setShowButton(response.data.next ? true : false)
    response.data?.results?.map(news => {
      props.addNews(news)
    })

  }

  const handlePagination = () => {
    setPage(page + 1)
  }

  useEffect(() => {
    fetchData()
  }, [page])

  useEffect(() => {
    fetchData()
  }, [])

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1))
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" })
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }, [location,]);

  return (
    <>
      <TopNavbar />
      <Navbar nosticky />
      <div className='container-xl'>
        <div className='mx-lg-5 px-lg-5 mt-5'>
          <h4>News &amp; updates</h4>
          <div className="news-and-updates-link">
            {props.newsList?.map(item => {
              return (
                <div key={item?.id}>
                  <hr />
                  <div className='row align-items-center news-updates-card' id={`news-${item.id + 1}`}>
                    <div className='col-sm-8 order-md-1 order-2'>
                      <p className='p4'>{dateFromString(item?.created_at)}</p>
                      <h5 className={item.url ? "newsAndUpdatesTitle" : ""}>{item?.title}</h5>
                      <p className='p3'>{item?.content}</p>
                      {item.url ? 
                        <a 
                          href={item.url ? item.url : null} 
                          target={isExternalLink(item.url) ? "_blank" : "_self"} 
                          key={item?.id}
                          className='d-flex align-items-center source-link'
                        >
                          Source
                          <img
                            src={SourceLinkIcon}
                            className='source-link-icon'
                          />
                        </a> 
                      : null}
                    </div>
                    <div className='col-sm-4 order-md-2 order-1 my-md-0 my-3' style={{ minWidth: "276px", minHeight: "162px" }}>
                      <img src={item?.picture} alt={item?.title} width="100%" style={{ margin: "auto", borderRadius: "8px" }} />
                    </div>
                  </div>
                </div>
              )
            })}
            <div className='mx-auto my-4 text-center view-more'>
              {showButton ? <Button type="View more" size="md" text="View more •••" minWidth className="button-outline" onClick={handlePagination} />
                : null}
            </div>

          </div>
        </div>
      </div>
      <SubscribeSection />
      <PageFooter />
    </>
  );
}

const mapStateToProps = state => {
  const newsList = getNews(state);
  return { newsList };
};

export default connect(
  mapStateToProps,
  { addNews }
)(NewsAndUpdates);
