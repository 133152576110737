import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';
import classNames from 'classnames';
import timeagoFromDate from '../../utils/timeagoFromDate';
import UserThumb from '../UserThumb';
import CommentsIcon from '../CommentsIcon';
import { routes } from '../../routes';


function RelatedDiscussions(props) {

  const history = useHistory();

  const [ activeKey, setActiveKey ] = useState('weekly');

  const { discussions } = props;

  let { path, url } = useRouteMatch();

  let className = classNames({
    'related-discussions': true,
    [props.className]: props.className ? true : false
  });

  return (
    <div className={className}>
      <h5>Related discussions</h5>
      <p className="p4">Other threads with similar topic. Or <Link to={routes.discussions}>browse all</Link> discussions.</p>
      {discussions?.map(discussion => {
        return (
        <div className="discussion">
          <div className="d-flex justify-content-start align-items-start mb-2">
            <Link to={`/community/user/${discussion?.author?.id}`}>
               <UserThumb 
              className="mr-2" 
              thumbnail={discussion.author.profile_picture}
              firstName={discussion?.author?.first_name} 
              lastName={discussion?.author?.last_name} 
            />
            </Link>
           
            <div>
              <Link to={`/community/user/${discussion?.author?.id}`}>
                <span className="author">{discussion.author.first_name} {discussion.author.last_name}</span>
              </Link>
              <span className="timestamp-info">posted {timeagoFromDate(discussion.created_at)} ago</span>
            </div>
          </div>
          <Link to={`${routes.discussions}/${discussion.slug}`}><h6>{discussion.title}</h6></Link>
          <CommentsIcon 
            count={discussion?.comment_count} 
            onClick={() => history.push(`${routes.discussions}/${discussion.id}`)} 
            showText
          />
        </div>
        )
      })}
    </div>
  );
}

export default RelatedDiscussions;