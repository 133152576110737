import classNames from 'classnames';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import FormInput from '../../components/FormInput';
import Button from '../../components/Button';
import { subscribeSchema } from '../../pages/Common/ducks/validations';
import api from '../../adaptors/xhr/api';
import SubscribeConfirmation from "./SubscribeConfirmation"
import { useState } from 'react';

export default function SubscribeSection(props) {
  let className = classNames({
    'subscribe-section row': true,
    [props.className]: props.className ? true : false
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

   // `Formik` form submit handle
   const submitHandle = async (values, {setSubmitting, setFieldError, setStatus, resetForm}) => {
    setSubmitting(true);
    const { email } = values;
    try {
      // create user
      const response = await api.Subscription.subscribe(email);
      if (response.status === 200) {
        // succesfully subscribed
        // setEmailSent(values.email);
        setShow(true)
        setStatus({message: "Subscribed. Thank you"})
        resetForm(); 
       
      }
    }
    catch (error) {
      // read server validation errors
      // if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      console.error(error);
      if (error.response.status === 409)
      setFieldError('email', 'Email subscribed already');

    }
    setSubmitting(false);
  }

  return (
    <div className="news-bg">
    <div className="container-xl ">
      <div className={className}>
        <div className="col-12 col-sm-5  align-self-center">
          <h4>Sign up for updates</h4>
          <p className="p4 mb-4 mb-sm-0">Stay updated with what’s new on TRP and other highlights</p>
        </div>
        <div className="col-12 col-sm-7  align-self-center">
        <Formik
          validationSchema={subscribeSchema}
          onSubmit={submitHandle}
          initialValues={{
            email: '',
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            status
          }) => (
            <Form  className="form d-flex flex-column flex-sm-row justify-content-start justify-content-sm-end align-items-start" noValidate onSubmit={handleSubmit}>
              <FormInput 
                type="email"
                // label="Email ID"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Your Email..."
                // isValid={touched.email && !errors.email}
                isInvalid={!!errors.email}
                feedback={errors.email}
                required
                className="mb-2 mb-sm-0"
              />
              <Button size="lg" type="primary" text="Subscribe" minWidth className="d-none d-sm-block ml-0 ml-md-2 mt-0 mt-md-0 square-btn" submit />
              <Button size="lg" type="primary" text="Subscribe" className="d-block d-sm-none ml-0 ml-md-2 mt-0 mt-md-0 square-btn" submit />
              {status && status.message && (
                <div className="message">{status.message}</div>
              )}
            </Form>
          )}
        </Formik>
        </div>
      </div>
    </div>
    <SubscribeConfirmation show={show} handleClose={handleClose}/>
    </div>
  );
}