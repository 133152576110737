import React from 'react';
import { CloseButton } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { routes } from '../../routes';
import { Link } from 'react-router-dom';

export function SubscribeConfirmation(props) {
    const show = props.show;
    const handleClose = props.handleClose;
    console.log(show)
    return (
      <Modal
        show={show}
        backdrop="static"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className='email-subscription-popup'
      >
        <Modal.Body>
            <div className='container'>
                <div className='row align-self-end justify-content-end'>
                    <CloseButton onClick={handleClose} color="#03080C" />
                </div>
                <div className='row custom-header' >Thank you for subscribing to TRP newsletter</div>
                <div className='row custom-body d-block' style={{"fontWeight": 400}}>Your can also now signup to access full features of community and personalized content.<Link to={routes.signup}>Create an account.</Link> </div>
               <button type="button" class="btn btn-outline-primary close-button float-right" onClick={handleClose}>Close</button>
            </div>
        </Modal.Body>
        
      </Modal>
    );
}

export default SubscribeConfirmation;