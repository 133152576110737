import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import timeagoFromDate from '../../utils/timeagoFromDate';
import UserThumb from '../UserThumb';
import api from '../../adaptors/xhr/api';
import Button from '../Button';
import { routes } from '../../routes';


const CommunityPost = React.forwardRef((props, ref) => {
  console.log(props);
  const history = useHistory();
  const [ activeKey, setActiveKey ] = useState('weekly');
  

  const { thread = {
    author: {},
    title: "What goals do you want to achieve with TRP Online Community?",
    body: `<p>Hi TRP Members,</p>

        <p>We’ve launched online community in March this year. And it is already a place for more than 1k community members, who created more than 2k posts! As Community Managers, we would love to provide the best experience for all of you. Hence the questions:</p>
        
        <p>What goals do you want to achieve with TRP Online Community?
        What would be a perfect online community for you?
        What kind of content do you want to see in this community?</p>
        
        <p>Please share your opinion in the comments!</p>`,
    view_count: 30,
    created_at: ""
  } } = props;

  let { path, url } = useRouteMatch();

  let className = classNames({
    'community-post': true,
    [props.className]: props.className ? true : false
  });

  return (
    <div className={className}>
      <h3>{thread?.title}</h3>
      
      <div className="d-flex justify-content-start align-items-start my-3">
        <Link to={`/community/user/${thread?.author?.id}`}>
        <UserThumb 
          className="mr-4" 
          thumbnail={thread?.author?.profile_picture} 
          firstName={thread?.author?.first_name} 
          lastName={thread?.author?.last_name} 
        />
      </Link>
        

        <div className="">
          <div ref={ref}>
            <Link to={`/community/user/${thread?.author?.id}`}>
              <span className="author">{thread?.author?.first_name} {thread?.author?.last_name}</span>
            </Link>
            <span className="timestamp-info">posted {timeagoFromDate(thread?.created_at)}</span>
          </div>
          <p className="p3 mt-2"  dangerouslySetInnerHTML={{ __html: thread?.body }} />

          <div className="post-actions">
            <span>{thread?.view_count} Views</span>
            <span> • </span>
            <span><Button onClick={props.reply} text="Reply" minWidth /></span>
            <span> • </span>
            <span><Button onClick={props.reportAbuse} text="Report" minWidth /></span>
          </div>
        </div>

        
      </div>
    </div>
  );
})

export default CommunityPost;