import React from 'react';
import classNames from 'classnames';
import PresentationSlidesImage from '../../assets/images/presentation_slides.png';

function SlidesBanner(props) {
  let className = classNames({
    "slides-banner d-flex justify-content-between align-items-center": true,
    [props.className]: props.className ? true : false
  });
  return (
    <div className={className}>
      <p className="p4">
        {props.fr 
        ? `Toutes les diapositives sont codées par couleur selon les informations.` 
        : props.es ? `Todas las diapositivas están codificadas por colores según la información.`
        : `All slides are color coded according to information.`}
        <a href="#" onClick={props.showColorGuides}>
          {props.fr 
          ? ` Apprendre encore plus`
          : props.es ? `Aprende Más`
          : `Learn more`}
        </a>
      </p>
      <img src={PresentationSlidesImage} className="img-responsive" alt="presentation slides image" width="127" height="86" />
    </div>
  );
}

export default SlidesBanner;