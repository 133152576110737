import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import classNames from 'classnames';
import Button from '../Button';
import FolderIcon from '../../assets/icons/folder.svg';
import CheckmarkIcon from '../../assets/icons/checkmark.svg';

function BookmarkFolders(props) {
  const { category = "", folders } = props;
  const [ selectedKey, setSelectedKey ] = useState(0);
  let className = classNames({
    "bookmark-list": true,
    [props.className]: props.className ? true : false
  });

  const onClickFolder = (folderId) => {
    setSelectedKey(folderId)
    props.selectedFolder(folderId);
  }

  return (
      <div className={className}>
        <ul className="list-group list-group-flush">
          {folders?.map(folder => {
            return (
              <li href="#" key={`folder-${folder.id}`}  className="list-group-item list-group-item-action active" aria-current="true"
                onClick={(selectedKey) => onClickFolder(folder.id)}>
                <div className="d-flex w-100 justify-content-between align-items-center">
                  <div className="d-flex justify-content-start align-items-center">

                      <img src={FolderIcon} className="img-responsive btn-icon align-middle mr-2" alt="action icon" />
                      <p className="p3 mb-0">{folder.folder_name}</p>

                  </div>
                  <div className="d-flex justify-content-center align-items-center p-2">
                    { folder.id === selectedKey || folder.id === props.selectedKey 
                    ? <img src={CheckmarkIcon} className="img-responsive" alt="arrow icon" width="8" height="6" />
                    : null
                    }
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
  );
}

export default BookmarkFolders;