import { combineReducers } from "redux";
import types from "../types";

const contributorsInitialState = {
    byId: {},
    allIds: [],
    moduleId: null
};


export function contributorsReducer(state = contributorsInitialState, action) {
  switch (action.type) {
    case types.ADD_CONTRIBUTOR: {
      const { id, data, point } = action.payload;
      return {
        ...state,
        allIds: Array.from(new Set([...state.allIds, id])),
        byId: {
          ...state.byId,
          [id]: {
            ...data
          }
        }
      };
    }
    default:
      return state;
  }

}