import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  useRouteMatch, 
  Link
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Navbar from '../../components/Navbar';
import TopNavbar from '../../components/TopNavbar';
import PageContent from '../../components/PageContent';

import PageFooter from '../../components/PageFooter/PageFooter';
import HeroSection from '../../components/HeroSection';
import PartnerLogos from '../../components/PartnerLogos';
import Button from '../../components/Button';
import HighlightsSection from '../../components/HighlightsSection';
import NewsSection from '../../components/NewsSection';
import SubscribeSection from '../../components/SubscribeSection';

import api from '../../adaptors/xhr/api';
import { addNews } from './ducks/actions';
import { setSortFilter } from '../Training/ducks/actions';
import { getNews } from './ducks/selectors';
import { addHomeContent } from '../Common/ducks/actions';
import { getHomeContent } from '../Common/ducks/selectors';
// import SiteSearch from '../SiteSearch/SiteSearch';
import FilePreviewModal from '../../components/FilePreviewModal';

import FileList from '../../components/FileList';
import FilterDropdown from '../../components/FilterDropdown/FilterDropdown';
import {DOWNLOADS_SORT_FILTERS} from "../../constants";
import DownloadIcon from '../../assets/icons/download-white.svg';
import ModuleCard from '../../components/ModuleCard/ModuleCard';
import { getSearchText, getSearchResults } from '../../pages/Common/ducks/selectors';
import { addSearchResults } from '../../pages/Common/ducks/actions'

function SearchResults(props) {

  const searchResults = useSelector(state => getSearchResults(state, props.sortFilter))
  const searchText = useSelector(state => getSearchText(state));

  // const [ searchText, setSearchText ] = useState();
  const [ keyword, setKeyword ] = useState();
  const [ results, setResults ] = useState();
  const [ showResults, setShowResults ] = useState();

  const [ previewModalShow, setPreviewModalShow ] = useState(false);
  const [ previewFileObj, setPreviewFileObj ] = useState(null);

  const previewFile = (fileObj) => {
    setPreviewFileObj(fileObj)
    setPreviewModalShow(true);
  }

  const fetchNews = async () => {
    const response = await api.News.list();
    response.data?.results?.map(news => {
      props.addNews(news)
    })
  }

  // const fetchContent = async () => {
  //   const response = await api.Content.homepage();
  //   if(response.data)  props.addHomeContent(response.data);
  // }

  // useEffect(() => {
  //   fetchNews();
  //   fetchContent();
  // }, [])

  function setFilter(filter) {
    props.setSortFilter(filter);
  }

  return (
    <>
      <TopNavbar/>
      <Navbar siteSearch/>
      {/* <SiteSearch/> */}

      <div className="container-xl">
      <div className="search-results mx-auto mt-2">
        {searchResults &&
        <div className="mt-2 mt-md-0 d-flex justify-content-sm-between align-items-center mb-3">
          <h5>results for ‘{searchText}’</h5>
          <div className="d-none d-md-block" >
            <FilterDropdown 
              onSelect={setFilter} 
              className="mr-2"
              sortFilters={DOWNLOADS_SORT_FILTERS}
              activeFilter={props.sortFilter}
            />
            </div>
            <div className="d-block d-md-none w-100" >
            <FilterDropdown 
              onSelect={setFilter} 
              className="mr-2"
              sortFilters={DOWNLOADS_SORT_FILTERS}
              activeFilter={props.sortFilter}
            />
          </div>
        </div>

        }
        {searchResults?.modules?.length ? searchResults?.modules?.map(module => 
          <ModuleCard
            name={module.name}
            category={module.category?.name}
            description={module.purpose}
            moduleId={module.id}
            key={module.id}
          />  
        ) : null}
        {searchResults?.resources?.length ? searchResults?.resources.map(file => 
          <FileList 
            className=""
            fileId={file.id}
            title={file.title}
            description={file.details}
            fileType={file.file_type}
            fileSize={file.size}
            fileUrl={file.file}
            bookmarkItemsIds={props.bookmarkItemsIds}
            sectionId={file.sectionId}
            sessionfileId={file.sessionfileId}
            addBookmark={props.addBookmark}
            removeBookmark={props.removeBookmark}
            fileObj={file}
            previewFile={previewFile}
            key={file.id}
          />  
        ) : null}
        {!(searchResults?.resources?.length || searchResults?.modules?.length) ? 
        <h6 className='text-center my-5'>No results found</h6> : null}
      </div>
    </div> 
    <FilePreviewModal
      show={previewModalShow}
      onHide={() => setPreviewModalShow(false)}
      fileObj={previewFileObj}
    />
    <PageFooter nonav/>
    </>
    );
  }


const mapStateToProps = state => {
  const sortFilter = state.training.sortFilter;
  return { sortFilter };
};

export default connect(
  mapStateToProps,
  { addSearchResults, setSortFilter }
)(SearchResults);