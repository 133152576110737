import types from "../types";
import { combineReducers } from "redux";

function commentsById(state = {}, action) {
  switch (action.type) {
    case types.ADD_COMMENT: {
      const { payload } = action
      const { commentId, comment } = payload
    
      // Create new Comment object
      const commentObj = { id: commentId, text: comment.comment, author: comment.author, createdAt: comment.created_at, updatedAt: comment.updated_at }
    
      // Insert the new Comment object into the updated lookup table
      return {
        ...state,
        [commentId]: commentObj
      }
    }
      
    default:
      return state
  }
}

function allComments(state = [], action) {
  switch (action.type) {
    case types.ADD_COMMENT: {
      const { payload } = action
      const { commentId } = payload
      // Just append the new Comment's ID to the list of all IDs
      return state.concat(commentId)
    }
    default:
      return state
  }
}

const commentsReducer = combineReducers({
  byId: commentsById,
  allIds: allComments
})

export default commentsReducer;