import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  useRouteMatch, 
  Link,
  Redirect,
  withRouter
} from "react-router-dom";
import { connect } from "react-redux";
import Navbar from '../../components/Navbar';
import TopNavbar from '../../components/TopNavbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageContent from '../../components/PageContent';
import UserBookmarkItem from '../../components/UserBookmarkItem';

import PageFooter from '../../components/PageFooter/PageFooter';
import HeroSection from '../../components/HeroSection';
import PartnerLogos from '../../components/PartnerLogos';
import Button from '../../components/Button';
import HighlightsSection from '../../components/HighlightsSection';
import NewsSection from '../../components/NewsSection';
import ProfileCard from '../../components/ProfileCard';
import ProfileNavbarTabs from '../../components/ProfileNavbarTabs';
import SubscribeSection from '../../components/SubscribeSection';
import { getLoginState } from './ducks/selectors';
import { getActivities } from '../../pages/Community/ducks/selectors';
import { getBookmarkItems, getBookmarksFolders, getBookmarkFolderById, getBookmarkItemsByFolderFilter } from '../../pages/Training/ducks/selectors';
import { addActivity } from '../../pages/Community/ducks/actions';
import { addBookmarkFolder,
  addDefaultBookmarkFolder,
  addBookmarkItem,
  removeBookmarkItem,
  addBookmarkContext,
  setBookmarkFolderFilter,
  renameBookmarkFolder,
  deleteBookmarkFolder, } from '../../pages/Training/ducks/actions'
import { getProfileFetch } from './ducks/operations';
import { routes } from '../../routes';
import {DISCISSIONS_SORT_FILTERS} from "../../constants";
import FilterDropdown from '../../components/FilterDropdown';
import BookmarksFilterNav from '../../components/BookmarksFilterNav';
import ActivityList from '../../components/ActivityList';
import api from '../../adaptors/xhr/api';
import BookmarkRemovePrompt from '../../components/BookmarkRemovePrompt';
import FilePreviewModal from '../../components/FilePreviewModal';
import BookmarkFolderRenameModal from '../../components/BookmarkFolderRenameModal';
import BookmarkDeleteFolderPrompt from '../../components/BookmarkDeleteFolderPrompt';
import SmallActivityFilterNav from '../../components/BookmarksFilterNav/SmallBookmarksFilterNav'

function UserBookmarks(props) {
  const [ modalShow, setModalShow ] = useState(false);
  const [ renameModalShow, setRenameModalShow ] = useState(false);
  const [ deleteModalShow, setDeleteModalShow ] = useState(false);
  const [ previewModalShow, setPreviewModalShow ] = useState(false);
  const [ previewFileObj, setPreviewFileObj ] = useState(null);
  const [ activeKey, setActiveKey ] = useState('all')
  const [ folderFilter, setFolderFilter ] = useState();
  const [ bookmarkItem2Remove, setBookmarkItem2Remove ] = useState();
  console.log('activeKey', activeKey)
  const fetchBookmarks = async () => {
    const response = await api.Bookmarks.folders();
    response.data?.map(bookmark => {
      props.addBookmarkFolder(bookmark);
      if (bookmark.is_default == true) props.addDefaultBookmarkFolder(bookmark);
      // fetch modules
      bookmark.modules?.map(item => {
        props.addBookmarkItem(bookmark.id, `module-${item.id}`, item, "modules");
      })

      // fetch module_session_plans
      bookmark.module_session_plans?.map(item => {
        props.addBookmarkItem(bookmark.id, `module_session_plan-${item.module.id}-${item.id}`, item, "module_session_plans");
      })

      // fetch presentation_slides
      bookmark.presentation_slides?.map(item => {
        props.addBookmarkItem(bookmark.id, `presentation_slides-${item.module.id}-${item.id}`, item, "presentation_slides");
      })

      // fetch evaluation_tools
      bookmark.evaluation_tools?.map(item => {
        props.addBookmarkItem(bookmark.id, `evaluation_tools-${item.module.id}-${item.id}`, item, "evaluation_tools");
      })

      // fetch references
      bookmark.references?.map(item => {
        props.addBookmarkItem(bookmark.id, `references-${item.module.id}-${item.id}`, item, "references");
      })

      // fetch handouts
      bookmark.handouts?.map(item => {
        props.addBookmarkItem(bookmark.id, `handouts-${item.module.id}-${item.id}`, item, "handouts");
      })

    })
  }

  useEffect(() => {
    props.setBookmarkFolderFilter(null);
    fetchBookmarks();
  }, []);

  const handleSettings = () => {
    props.history.push(routes.profileSettings);
  }

  const fetchActivity = async () => {
    const response = await api.Forum.useractivity();
    response.data.results?.map(activity => {
      props.addActivity(activity);
    })
  }

  if (!props.currentUser.email) {
    return (
      <Redirect to={routes.login} />
    )
  }

  const onClickBookmarkBtn = async (folderId, itemId, item) => {
    setModalShow(true);
    setBookmarkItem2Remove(item);
    // props.removeBookmarkItem(folderId, itemId, item);
  }

  const confirmRemoveBookmarkItem = async () => {
    const response = await api.Bookmarks.removebookmarkedItem(
      bookmarkItem2Remove.folderId,
      bookmarkItem2Remove.sessionType,
      bookmarkItem2Remove.pk
    )
    if (response.status === 200) {
      props.removeBookmarkItem(bookmarkItem2Remove.folderId, bookmarkItem2Remove.itemId, bookmarkItem2Remove);
      setModalShow(false);
    }
    
  }

  const previewFile = (fileObj) => {
   setPreviewFileObj(fileObj)
   setPreviewModalShow(true);
   
 }
console.log("test",previewFileObj)
 const selectFolderFilter = (selectedKey) => {
  setActiveKey(selectedKey);
  if (selectedKey === 'all') {
    // setFolderFilter(undefined);
    props.setBookmarkFolderFilter(null);
  }
  else {
    // setFolderFilter(selectedKey);
    props.setBookmarkFolderFilter(selectedKey);
  }
 }

  const clickRenameFolder = () => {
    setRenameModalShow(true);
  }

  const clickDeleteFolder = () => {
    setDeleteModalShow(true);
  }

  const folderRenameSuccess = (folderId, newFolderName) => {
    props.renameBookmarkFolder(folderId, newFolderName);
    setRenameModalShow(false);
  }

  const confirmDeleteFolder = async (folderId) => {
    const response = await api.Bookmarks.deleteFolder(folderId);
    if (response.status === 204) {
      props.setBookmarkFolderFilter(null);
      setActiveKey('all');
      // props.removeBookmarkItem(bookmarkItem2Remove.folderId, bookmarkItem2Remove.itemId, bookmarkItem2Remove);
      await props.selectedFolder?.bookmarkItems?.map(bookmarkItemId => 
        props.removeBookmarkItem(folderId, bookmarkItemId));
      await props.deleteBookmarkFolder(folderId);
      setDeleteModalShow(false);
    }
  }

  return (
    <>
      <TopNavbar/>
      <Navbar/>
      <Breadcrumb breadcrumbs={["Home", "My Account"]} links={["/","/"]}/>
      <div className="profile-header">
        <div className="container-xl">
          <div className="d-flex justify-content-between align-items-center">
          <ProfileCard 
            firstName={props.currentUser.first_name} 
            lastName={props.currentUser.last_name}
            thumbnail={props.currentUser.profile_picture} 
            points={props.currentUser.points} 
            linkedinUrl={props.currentUser.linkedin_url}
            bio={props.currentUser.bio}
          />
            <Button type="outline" size="md" text="Settings" minWidth onClick={handleSettings}  />
          </div>
        </div>
      </div>
      <ProfileNavbarTabs userId={props.currentUser.pk } />
      <PageContent>
      <div className="row">
        <div className="col-xs-12 col-lg-8">
          <div className="">
            <div className=" w-100 mb-4">
               <div>
               <SmallActivityFilterNav  
                onSelect={selectFolderFilter}
                activeKey={activeKey} 
                filters={props.bookmarksFolders.filter(folder => folder.is_default != true)
                  .map(folder=> {return {'id': folder.id, 'name': folder.folder_name}})}
              />
        </div>
              <div className="w-100 mb-3 d-flex align-items-center justify-content-between">
                <h6 className="mb-3 d-none d-lg-block">{props.selectedFolder?.folder_name ? props.selectedFolder.folder_name : 'All bookmarks'}</h6>
                <div className="d-flex">
                {props.selectedFolder?.folder_name 
                ? <>
                  <Button 
                    size="sm" 
                    text="Rename" 
                    className="mx-2 mr-1" 
                    onClick={clickRenameFolder} 
                  />
                  <small className="s1"> • </small>
                  <Button 
                    size="sm" 
                    text="Delete" 
                    className="mx-2 mr-1" 
                    onClick={clickDeleteFolder} 
                  />
                </>
                : null
                }
                
                </div>
              </div>
              
              {/* <FilterDropdown 
                onSelect={(filter) => console.log(filter)} 
                className="mr-2"
                sortFilters={ACTIVITY_SORT_FILTERS}
                activeFilter="Recent"
              /> */}
              {props.bookmarkItemsByFolderFilter.map(item => <UserBookmarkItem 
                className=""
                type={item.type}
                item={item}
                // bookmarkItemsIds={props.bookmarkItemsIds}
                // bookmarks={props.bookmarks}
                // addBookmark={props.addBookmark}
                removeBookmark={onClickBookmarkBtn}
                previewFile={previewFile}
              />)}
              
            </div>

          </div>
        </div>

        <div className="col-xs-12 col-lg-3 offset-lg-1">
          <BookmarksFilterNav
            onSelect={selectFolderFilter}
            activeKey={activeKey} 
            className="my-4 d-none d-lg-block"
            filters={props.bookmarksFolders.filter(folder => folder.is_default != true)
              .map(folder=> {return {'id': folder.id, 'name': folder.folder_name}})}
          />
        </div>
      <div >
    </div>
      </div>
      <BookmarkRemovePrompt
        show={modalShow}
        onHide={() => setModalShow(false)}
        confirmRemove={confirmRemoveBookmarkItem}
        // folders={props.bookmarksFolders}
        // bookmarkContext={props.bookmarkContext}
        // bookmarkInfo={{sessionType: bookmarksessionType, itemId: bookmarkItemId}}
        // onCreatedFolder={props.addBookmarkFolder}
        // bookmarkSuccess={props.addBookmarkItem} // action
      />
      <FilePreviewModal
        show={previewModalShow}
        onHide={() => setPreviewModalShow(false)}
        fileObj={previewFileObj}
      />

      <BookmarkFolderRenameModal
        show={renameModalShow}
        onHide={() => setRenameModalShow(false)}
        renameSuccess={folderRenameSuccess}
        folderId={props.selectedFolder.id}
        folderName={props.selectedFolder.folder_name}
      />

      <BookmarkDeleteFolderPrompt
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        folderId={props.selectedFolder.id}
        confirmDelete={confirmDeleteFolder}
      />

    </PageContent>
    <PageFooter/>
      
      
      
    </>
    );
  }



const mapStateToProps = state => {
  const currentUser = getLoginState(state);
  const activities = getActivities(state);
  const bookmarkFolderFilter = state.training.bookmarkFolderFilter;
  const bookmarkItems = getBookmarkItems(state);
  const bookmarkItemsByFolderFilter = getBookmarkItemsByFolderFilter(state, bookmarkFolderFilter);
  const bookmarksFolders = getBookmarksFolders(state);
  const selectedFolder = getBookmarkFolderById(state, bookmarkFolderFilter);
  return { currentUser, activities, bookmarkItems, bookmarkItemsByFolderFilter, selectedFolder, bookmarksFolders };
};

export default connect(
  mapStateToProps,
  { getProfileFetch, addActivity, 
    addBookmarkFolder, addDefaultBookmarkFolder,
    addBookmarkContext, 
    addBookmarkItem, 
    removeBookmarkItem,
    setBookmarkFolderFilter, 
    renameBookmarkFolder,
    deleteBookmarkFolder, }
)(withRouter(UserBookmarks));