import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../Button";
import Nav from "react-bootstrap/Nav";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import classNames from "classnames";
import SittingIllustration from "../../assets/images/sitting.svg";
import { routes } from "../../routes";
import IconButton from "../IconButton";
import CopyIcon from "../../assets/icons/copy.png";

function FooterModal(props) {
  const { modalHeader, modalBody } = props;

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="contact-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-start align-items-start title-section">
            <h4>{modalHeader}</h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="p3" dangerouslySetInnerHTML={{__html: modalBody}}>
        </p>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default FooterModal;
