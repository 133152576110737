import React, {useContext} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card';
import Button from '../Button';
import FileList from '../FileList';
import SlidesBanner from '../SlidesBanner';
import PlusIcon from '../../assets/icons/plus.svg';
import CrossIcon from '../../assets/icons/cross.svg';

function FaqEntry(props) {
  const currentEventKey = useContext(AccordionContext); // <-- Will update every time the eventKey changes.
  const toggleOnClick = useAccordionToggle(props.eventKey, () => {
    return currentEventKey
  });
  const isCurrentEventKey = currentEventKey === props.eventKey;
  return (
    <Card className="faq-entry">
      <Accordion.Toggle as={Card.Header} eventKey={props.eventKey} >
        <div className="accordion-button d-flex justify-content-between align-items-center faq-question" onClick={toggleOnClick} >
          <h6>{props.question}</h6>
          {isCurrentEventKey?<img src={CrossIcon} className="img-responsive" alt="plus icon" width="16" height="16"  />:<img src={PlusIcon} className="img-responsive" alt="plus icon" width="16" height="16" />}
          {/* <img src={PlusIcon} className="img-responsive" alt="plus icon" width="16" height="16" /> */}
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={props.eventKey}>
        <Card.Body>
          <p className="p3 mx-0" dangerouslySetInnerHTML={{__html: props.answer}}></p>
          {/* {props.files?.map(file => 
            <FileList 
              className=""
              title={file.title}
              description={file.detail}
              fileType={file.file_type}
              fileSize={file.file_size} 
              fileUrl={file.file}
            />
          )} */}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default FaqEntry;