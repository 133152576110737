import Breadcrumb from 'react-bootstrap/Breadcrumb';
import classNames from 'classnames';
import {LinkContainer} from 'react-router-bootstrap';
import { useEffect, useRef } from 'react';

export default function BSBreadcrumb(props) {
  var links = props.links
  if(!props.links){
    links = ["/","/","/"]
  }
  let className = classNames({
    "breadcrumb-div": true,
    "sticky-div": true,
    [props.className]: props.className ? true : false
  });
  const bcRef = useRef(null);

  useEffect(() => {
    if (props?.setHt && bcRef && bcRef?.current) {
      console.log("bcRef", bcRef?.current?.clientHeight);
      props?.setHt(bcRef?.current?.clientHeight);
    }
  }, [bcRef?.current?.clientHeight]);

  useEffect(() => {
    if (props?.navHeight && bcRef?.current) {
      console.log("navHeight", props?.navHeight);
      // navRef.current.style.top = props.bcHt + 'px';
      bcRef.current.style.top = props.navHeight + 'px';
    }
  }, [props?.navHeight]);

  return (
<>
    <div className={className} ref={bcRef}>
      <div className="container-xl sticky">
        <Breadcrumb>
        {props.breadcrumbs
        ?.map((breadcrumb, index) =>
          <LinkContainer to={links[index]}>
            <Breadcrumb.Item key={`breadcrumb-${breadcrumb}`} href={links[index]}>{breadcrumb}</Breadcrumb.Item>
          </LinkContainer>
        )}
        </Breadcrumb>
      </div>
    </div>
</>
  )
}
