import React, { useEffect, useState } from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import NavbarTabs from '../../components/NavbarTabs';
import PageContent from '../../components/PageContent';
import DownloadSection from '../../components/DownloadSection';
import {
  getModuleDetailService,
  getModuleDetailServiceBySlug,
  getModuleSessionPlan,
  getPresentationSlide,
  getEvaluationTools,
  getHandouts,
  getReferences,
  downloadModule
} from '../../adaptors/xhr/trainingService';
import {
  getModuleById,
  getDownloadsBySearchSortFilter
} from './ducks/selectors';
import {
  addModule,
  addDownload,
  setSortFilter,
  setSearchFilter,
  addBookmarkFolder,
  addDefaultBookmarkFolder,
  addBookmarkItem,
  addBookmarkContext,
  addSessionFile,
  removeBookmarkItem,
} from './ducks/actions';
import { addPost } from '../Community/ducks/actions'
import { getLoginState } from '../Common/ducks/selectors';
import { session_types, getBookmarksFolders, getDefaultBookmarkFolderId, getBookmarksFlat,
  getBookmarkItems, getBookmarkItemsList, getBookmarkContextState,
  getSessionpreps, getSessionprepsBySearchSortFilter, getBookmarksBySearchSortFilter } from './ducks/selectors';
import api from '../../adaptors/xhr/api';
import SearchBar from '../../components/SearchBar';
import FilterDropdown from '../../components/FilterDropdown/FilterDropdown';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import DownloadIcon from '../../assets/icons/download-white.svg';
import SearchEmpty from '../../components/SearchEmpty/SearchEmpty';
import BookmarkModal from '../../components/BookmarkModal';
import TopNavbar from '../../components/TopNavbar';
import {DOWNLOADS_SORT_FILTERS} from "../../constants";
import PageFooter from '../../components/PageFooter/PageFooter';
import FilePreviewModal from '../../components/FilePreviewModal';
import { routes } from '../../routes';
import LoginModal from '../../components/LoginModal';
import { findDiscussion } from '../../adaptors/xhr/communityService'

function Downloads(props) {
  console.log('props.bookmarks', props.bookmarks)
  const [ id, setId ] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [ previewModalShow, setPreviewModalShow ] = useState(false);
  const [ previewFileObj, setPreviewFileObj ] = useState(null);

  const [ loginShow, setLoginShow ] = useState(false);

  let { slug } = useParams();
  const module = useSelector((state) => getModuleById(state, id));

  const previewFile = (fileObj) => {
    setPreviewFileObj(fileObj)
    setPreviewModalShow(true);
  }

  const fetchBookmarks = async () => {
    try {
      const response = await api.Bookmarks.folders();
      response.data?.map(bookmark => {
        props.addBookmarkFolder(bookmark);
        if (bookmark.is_default == true) props.addDefaultBookmarkFolder(bookmark);
        // fetch modules
        bookmark.modules?.map(item => {
          props.addBookmarkItem(bookmark.id, `module-${item.id}`, item, "modules");
        })

        // fetch module_session_plans
        bookmark.module_session_plans?.map(item => {
          props.addBookmarkItem(bookmark.id, `module_session_plan-${item.module.id}-${item.id}`, item, "module_session_plans");
        })

        // fetch presentation_slides
        bookmark.presentation_slides?.map(item => {
          props.addBookmarkItem(bookmark.id, `presentation_slides-${item.module.id}-${item.id}`, item, "presentation_slides");
        })

        // fetch evaluation_tools
        bookmark.evaluation_tools?.map(item => {
          props.addBookmarkItem(bookmark.id, `evaluation_tools-${item.module.id}-${item.id}`, item, "evaluation_tools");
        })

        // fetch references
        bookmark.references?.map(item => {
          props.addBookmarkItem(bookmark.id, `references-${item.module.id}-${item.id}`, item, "references");
        })

        // fetch handouts
        bookmark.handouts?.map(item => {
          props.addBookmarkItem(bookmark.id, `handouts-${item.module.id}-${item.id}`, item, "handouts");
        })

      })
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {

    getModuleDetailServiceBySlug(slug)
    .then(response => {
      console.log('Success:', response.data);
      props.addModule(response.data);
      setId(response.data.id);
    })
    .catch((error) => {
      console.error('Error:', error);
    })

    fetchBookmarks();

  }, [])

  useEffect(() => {
    if (id != null) {
      getModuleSessionPlan(id)
      .then(response => {
        response.data.results.map(sessionfile => {
          props.addSessionFile(id, "module_session_plan", `module_session_plan-${id}-${sessionfile.id}`, sessionfile)
        })
        // setModuleSessionPlanCount(response.data.results.length)
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      getPresentationSlide(id)
      .then(response => {
        response.data?.results?.map(sessionfile =>
          props.addSessionFile(id, "presentation_slides", `presentation_slides-${id}-${sessionfile.id}`, sessionfile))
        // setPresentationSlidesCount(response.data.results.length)
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      getEvaluationTools(id)
      .then(response => {
        response.data?.results?.map(sessionfile =>
          props.addSessionFile(id, "evaluation_tools", `evaluation_tools-${id}-${sessionfile.id}`, sessionfile))
          // setEvaluationToolsCount(response.data.results.length)
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      getHandouts(id)
      .then(response => {
        response.data?.results?.map(sessionfile =>
          props.addSessionFile(id, "handouts", `handouts-${id}-${sessionfile.id}`, sessionfile))
          // setHandoutsCount(response.data.results.length)
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      getReferences(id)
      .then(response => {
        response.data?.results?.map(sessionfile =>
          props.addSessionFile(id, "references", `references-${id}-${sessionfile.id}`, sessionfile))
          // setReferencesCount(response.data.results.length)
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      findDiscussion(module.name)
      .then((values) => {
        console.log(values)
          values.data?.results?.map((post) => {
              props.addPost(post)
              props.addModuleDiscussionId(post.id, id)
          })
      }).catch((err) => console.log(err))
    }
  }, [id])

  let moduleBookmarks = []

  useEffect(() => {
    moduleBookmarks = props.bookmarks.filter(file => file.module?.id == id && file.path_type === "FILE")
    console.log("props.bookmarks", props.bookmarks)
  }, [props.bookmarkItemsIds])

  const bookmarks = [
      {
        "id": 11,
        "title": "Basic Slides - Implants Session I: Characteristics of Implants",
        "file": "url//",
        "file_type": "Word docs",
        "file_size": 45,
      }
    ]

  function setFilter(filter) {
    props.setSortFilter(filter);
  }

  function searchDownloads(text) {
    props.setSearchFilter(text);
  }

  function clearSearch() {
    props.setSearchFilter("");
  }

  const addBookmark = (section, fileId, fileObj) => {

    if (props.currentUser.email) {
      console.log("BOOKMARK", section, fileId, fileObj);
      props.addBookmarkContext(section, fileId, fileObj);
      setModalShow(true);
    }
    else
      setLoginShow(true);

  }

  const removeBookmark = async (section, fileId, fileObj) => {
    console.log(">>section", section)
    console.log(">>fileId", fileId)
    console.log(">>fileObj", fileObj)
    const bookmarkItem = await props.bookmarkItems.find(item => item.itemId == fileObj.sessionfileId || item.itemId == fileObj.itemId)
    const response = await api.Bookmarks.removebookmarkedItem(
      bookmarkItem.folderId,
      bookmarkItem.sessionType,
      bookmarkItem.pk
    )
    if (response.status === 200) {
      props.removeBookmarkItem(bookmarkItem.folderId, bookmarkItem.itemId, bookmarkItem);
      setModalShow(false);
    }
  }
console.log(module);


  const [bcHt, setBcHt] = useState(0);

  const setHt = (value) => {
    setBcHt(value);
  }

  return (
  <>
    <TopNavbar />
    <Navbar nosticky />
    <Breadcrumb
        breadcrumbs={["Home", "Training Modules", module.name]}
        links={["/","/training/",`/training/${module.slug}/`]}
        setHt={setHt}
    />
    <PageHeader heading={ module.name }/>
    {module.relatedDiscussionsId && module.relatedDiscussionsId.length
    ?<NavbarTabs discussionsNumbers = {module.relatedDiscussionsId.length} bcHt={bcHt}/>
      :<NavbarTabs discussionsNumbers = {[0]} bcHt={bcHt}/>}
    <PageContent>
      <div className="row">

        <div className="col-xs-12 col-md-6">
          <SearchBar
            className="w-100"
            placeholder="Search by file name or type..."
            onChange={searchDownloads}
            value={props.searchFilter}
          />
        </div>

        <div className="offset-lg-2 col mt-2 mt-md-0 d-flex justify-content-sm-end align-items-center">
          <div className="d-none d-md-block" >
          <FilterDropdown
            onSelect={setFilter}
            className="mr-2"
            sortFilters={DOWNLOADS_SORT_FILTERS}
            activeFilter={props.sortFilter}
          />
          </div>
          <div className="d-block d-md-none w-100" >
          <FilterDropdown
            onSelect={setFilter}
            className="mr-2"
            sortFilters={DOWNLOADS_SORT_FILTERS}
            activeFilter={props.sortFilter}
          />
          </div>
          <div className="" >
          <Button
            type="primary"
            size="md"
            text="Download All"
            icon={DownloadIcon}
            className="min-width-btn"
            minWidth
            onClick={() => downloadModule(id, module.name)}
          />

          </div>
        </div>

      </div>
      <div className="bpy-5">
      <div className="downloads-section mt-4">
      <div className="col d-none d-md-block">
        <li className="row header d-flex align-items-center">
          <div className="col-md-7 mr-5 pl-0">
            <span className="">File Name</span>
          </div>
          <div className="col-md-2 ml-4">
            <span className="filetype">File Type</span>
          </div>
          <div className="col-md-1 ml-4">
            <span className="filesize">Size</span>
          </div>
          <div className="col-md-1">
              {/* <BookmarkButton  /> */}
            {/* <ButtonXs
              type="outline"
              size="sm"
              text="Download"
              icon={DownloadIcon}
              // onClick={downloadFile}
              noHide
            /> */}
          </div>
        </li>
        </div>

      </div>

      {props.bookmarks.length
        ? <DownloadSection
          className="mb-5"
          title="Bookmarked"
          files={props.bookmarks.filter(file => file.moduleId == id && file.path_type === "FILE")}
          previewFile={previewFile}
          bookmarkItemsIds={props.bookmarkItemsIds}
          removeBookmark={removeBookmark}
        />
        : null
      }

      {props.sessionprepfilesFiltered && props.sessionprepfilesFiltered.length
        ? <DownloadSection
            className="mb-5"
            title="All files"
            // Avoid listing reference urls
            // files={props.downloads.filter(file => file.url == null)}
            files={props.sessionprepfilesFiltered.filter(file => file.moduleId == id && file.path_type === "FILE")}
            previewFile={previewFile}
            // onBookmark={() => setModalShow(true)}
            bookmarkItemsIds={props.bookmarkItemsIds}
            addBookmark={addBookmark}
            removeBookmark={removeBookmark}
          />
        : null
      }

      {props.downloads && props.downloads.length === 0 && props.searchFilter
        ? <SearchEmpty clearSearch={clearSearch} />
        : null
      }

      <Alert>
        Need help with downloads or modules? <Link to={routes.gettingStartedFaq}>View Frequently Asked Questions</Link> or <a href="mailto:info@fptraining.org">Contact Us</a>
      </Alert>

    </div>
    <BookmarkModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      folders={props.bookmarksFolders}
      defaultFolderId={props.bookmarkFolderDefaultId}
      bookmarkContext={props.bookmarkContext}
      // bookmarkInfo={{sessionType: bookmarksessionType, itemId: bookmarkItemId}}
      onCreatedFolder={props.addBookmarkFolder}
      bookmarkSuccess={props.addBookmarkItem} // action
    />

    <FilePreviewModal
      show={previewModalShow}
      onHide={() => setPreviewModalShow(false)}
      fileObj={previewFileObj}
    />

    <LoginModal
      show={loginShow}
      onHide={() => setLoginShow(false)}
    />

    </PageContent>
    <PageFooter/>
  </>
  );
}

const mapStateToProps = state => {
  const sessionprepfiles = getSessionpreps(state);

  const sortFilter = state.training.sortFilter;
  const searchFilter = state.training.searchFilter;
  const downloads = getDownloadsBySearchSortFilter(state, sortFilter, searchFilter);
  const sessionprepfilesFiltered = getSessionprepsBySearchSortFilter(state, sortFilter, searchFilter);
  const bookmarkContext = getBookmarkContextState(state);
  const bookmarkItemsIds = getBookmarkItemsList(state);
  const bookmarkItems = getBookmarkItems(state);
  const bookmarksFolders = getBookmarksFolders(state);
  const bookmarkFolderDefaultId = getDefaultBookmarkFolderId(state);
  // const bookmarks = getBookmarkItems(state);
  const bookmarks = getBookmarksBySearchSortFilter(state, sortFilter, searchFilter);
  const currentUser = getLoginState(state);
  return { currentUser, searchFilter, sortFilter, downloads, bookmarksFolders, bookmarkFolderDefaultId, bookmarkItemsIds,
    bookmarkItems, bookmarkContext, bookmarks, sessionprepfiles, sessionprepfilesFiltered
  };
};

export default connect(
  mapStateToProps,
  { addModule,
    addSessionFile,
    addDownload,
    setSortFilter,
    setSearchFilter,
    addBookmarkFolder,
    addDefaultBookmarkFolder,
    addBookmarkContext,
    addBookmarkItem,
    removeBookmarkItem,
    addPost,
  }
)(Downloads);
