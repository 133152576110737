import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";
import createLogger from './middlewares/logger';

export default createStore(
  rootReducer, 
  applyMiddleware(
    thunkMiddleware, 
    createLogger( true )
  )
);
