import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import UserThumb from '../UserThumb';
import { getContributors } from '../../adaptors/xhr/communityService'

function TopContributors(props) {
  useEffect(() => {
    getContributors()
    .then((contributors) => {
      console.log(contributors.data)
    })
  }, [])
  const [ activeKey, setActiveKey ] = useState('weekly');

  const { topContributors=[
    {"user": "Marvin M", "comments": 9}, 
    {"user": "Esther H", "comments": 5}, 
    {"user": "Cameron W", "comments": 7}, 
    {"user": "Cody F", "comments": 3}, 
  ] } = props;

  let { path, url } = useRouteMatch();

  let className = classNames({
    'top-contributors': true,
    [props.className]: props.className ? true : false
  });

  return (
    <div className={className}>
      <h5>Top Contributors</h5>
      <p className="p4">Users who are most active in community</p>

      <Nav variant="pills" defaultActiveKey={activeKey}
        onSelect={(selectedKey) => setActiveKey(selectedKey)}>
        <Nav.Item>
          <Nav.Link eventKey="weekly">Weekly</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="alltime">All time</Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="my-4">
        {topContributors?.map(contributor => {
          return (
            <Link to={`/community/user/${contributor?.user?.id}`}>
            <div className="d-flex justify-content-start align-items-center my-3">
              <UserThumb 
                className="mr-2" 
                thumbnail={contributor?.user?.profile_picture} 
                firstName={contributor?.user?.first_name} 
                lastName={contributor?.user?.last_name} 
              />
              <div className="">
                <div className="p4">{`${contributor?.user?.first_name} ${contributor?.user?.last_name}`}</div>
              </div>
            </div>
            </Link>
          )
        })}
      </div>

      
    </div>
  );
}

export default TopContributors;