import { axiosInstance, axiosAuthenticate } from './xhrConfig';

const Guides = {
  trainingGuidesDesc: () =>
    axiosInstance.get(`/api/training/guides/`),
  generalTrainingGuides: () =>
    axiosInstance.get(`/api/training/guides/general-training-guides/`),
  trainingGuides: () =>
    axiosInstance.get(`/api/training/guides/training-guides/`),
  additionalLinks: () =>
    axiosInstance.get(`/api/training/guides/additional-links/`),
  adaptationChecklists: () =>
    axiosInstance.get(`/api/training/guides/adaptation-checklists/`),
  siteSearch: (keyword) =>
    axiosInstance.get(`api/training/module-resource/search/?keyword=${keyword}`),
};

export default Guides;