import React, { useState } from 'react';
import PauseButton from '../../assets/icons/pausebutton.svg'
import Icon from '../Icon';

function VideoPreview(props) {
     const [openState, setOpenState] = useState(false)

    const revealVideo = () => {
        setOpenState(true)
    }

    const hideVideo = () => {
        setOpenState(false)
    }

    var video_id, video_url;

    const videoLink = props.videoData?.video_url
    if(props.videoData?.platform === "Vimeo") {
        video_id = videoLink.split("/")[3]
        video_url = `https://player.vimeo.com/video/${video_id}`
    }
    else {
        var regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
        video_id = videoLink.match(regExp)[2];
        video_url = `https://www.youtube.com/embed/${video_id}?showinfo=0`
        
    }
    

    return (
       <div className='video-container'>
           <div className='video-thumbnail-container' onClick={revealVideo}>
               <Icon icon={PauseButton} width={48} height={48} className='position-absolute icon-customization' />
                <img src={props.videoData.thumbnail}alt="video" className='thumbnail'/>
           </div>
           {openState?
            <div className="lightbox" onClick={hideVideo}>
                <div className="lightbox-container">  
                    <div className="lightbox-content" >
                        <button onclick="hideVideo('video','youtube')" className="lightbox-close float-right" onClick={hideVideo}>
                            Close | ✕
                        </button>
                     <div className="video-container">
                        <iframe id="youtube" src={video_url} frameborder="0" allowfullscreen></iframe>
                        {/* <iframe id="youtube" src={`https://www.youtube.com/embed/${youtube_video_id}?showinfo=0`} frameborder="0" allowfullscreen></iframe> */}
                    </div>  
                </div>
            </div>
            </div>
           :null}
           
        </div>
    );
}

export default VideoPreview;