import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../components/Button';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import SittingIllustration from '../../assets/images/sitting.svg';
import { routes } from '../../routes';

function CommunityGuidelinesModal({ onContinue, ...props}) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="community-guidelines-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-start align-items-start title-section">
          <h4>Thanks for joining TRP community. Please follow these guidelines.</h4>
          <img src={SittingIllustration} className="img-responsive" alt="arrow icon" width="114" height="120" />
        </div>

        <p className="p3">When leaving comments on Community, we ask that you…</p>

        <h5>Stay on topic</h5>
        <p className="p3">
          Focus on the community, and don’t spam or post unrelated links. We consider off-topic posts ‘spam’,   
          and repeated violation of this guideline may result in account suspension.
        </p>

        <h5>Be respectful</h5>
        <p className="p3">Keep things respectful and constructive. We encourage free expression 
          and an open platform, but we ask that you keep your comments constructive and respectful. 
          Abusive, vulgar or insulting comments won’t be tolerated and will result in account suspension.
        </p>

        <p className="p3 mb-0">
          {/* Read full <Link to={routes.guidelines}>community guidelines</Link> */}
          Read full <a href='https://docs.google.com/document/d/1SJbCMd-iGfVJvciNJl-fOxPyoMRYlElF5IzXC2XcdPc/edit?usp=sharing' target="_blank" rel="noreferrer">community guidelines</a>
        </p>
        <p className="p3">
          For any questions, contact <a href="mailto:info@fptraining.org">info@fptraining.org</a>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col px-0 d-flex align-items-between justify-content-center">
            <Button type="primary" size="lg"  onClick={onContinue} text='Continue'/>
          </div>
        </div>
        
       
      </Modal.Footer>
    </Modal>
  );
}

export default CommunityGuidelinesModal;