import React from 'react';
import Button from '../Button';
import DownloadFileList from '../DownloadFileList';
import SlidesBanner from '../SlidesBanner';
import ButtonXs from '../ButtonXs';
import BookmarkButton from '../BookmarkButton';
import DownloadIcon from '../../assets/icons/download.svg';
import classNames from 'classnames';

function DownloadSection(props) {
  let className = classNames({
    [props.className]: props.className ? true : false
  })
  const { files } = props;
  
  return files.length ? (
    <div className={className}>
      <div className="downloads-section">
          <h6 className="h6">{`${props.title} (${files.length})`}</h6>
          <ul className="list-group list-group-flush">

          {files?.map(file => 
            <DownloadFileList className=""
              bookmarked={props.title === "Bookmarked"}
              title={file.title}
              fileType={file.file_type}
              fileSize={file.size}
              fileUrl={file.file}
              fileObj={file}
              fileId={file.id}
              // onBookmark={props.onBookmark}
              previewFile={props.previewFile}
              bookmarkItemsIds={props.bookmarkItemsIds}
              sectionId={file.sectionId}
              sessionfileId={file.sessionfileId}
              addBookmark={props.addBookmark}
              removeBookmark={props.removeBookmark}
              noBookmark={props.noBookmark}
            />
          )}
          </ul>
        </div>
      </div>
    // </div>
  ): null;
}

export default DownloadSection;