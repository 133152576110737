import React, { useEffect, useRef, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { routes } from '../../routes'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';

const getUrlLast = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)

function NavbarTabs(props) {

  const [ active, setActive ] = useState();

  const tabsRef = useRef(null);

  let { path, url } = useRouteMatch();
  let page = getUrlLast(url);

  useEffect(() => {
    if (tabsRef && props?.bcHt) {
      console.log("tabsRef", props.bcHt);
      tabsRef.current.style.top = props.bcHt + 'px';
    }
  }, [props?.bcHt]);

  return (
    <div className="navbar-tabs" ref={tabsRef}>
       <div className="container-xl">
      <Nav
          activeKey={page || 'overview'}
          onSelect={(selectedKey) => setActive(selectedKey)}
        >
          <Nav.Item>
            <LinkContainer to="./">
              <Nav.Link eventKey="overview">Overview</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to="sessions">
              <Nav.Link eventKey="sessions">Preparing A Session</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to="downloads">
              <Nav.Link eventKey="downloads">All Downloads</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to="related-discussions">
            <Nav.Link eventKey="related-discussions">
              Discuss {props.discussionsNumbers ? `(${props.discussionsNumbers})` : `(0)` }
            </Nav.Link>
            </LinkContainer>
          </Nav.Item>
        </Nav>
      </div>
  </div>
  );
}

export default NavbarTabs;
