import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown'
import cx from "classnames";


export default function FilterDropdown(props) {
  let className = cx({
    'filter-dropdown': true,
    [props.className]: props.className ? true : false
  });

  // const [ activeFilter, setActiveFilter ] = useState();

  return (
    <Dropdown className={className}
      onSelect={(selectedKey) => {
        // setActiveFilter(selectedKey);
        props.onSelect(selectedKey);
      }}
    >
      <Dropdown.Toggle variant="default" id="dropdown-basic">
        Sort by {props.activeFilter || "Default Order"}
      </Dropdown.Toggle>

      <Dropdown.Menu>
      {props.sortFilters? 
      Object.keys(props.sortFilters)?.map(filterKey => {
        const currentFilter = props.sortFilters[filterKey];
        return (
          <Dropdown.Item 
            eventKey={`${currentFilter}`}
            className={cx(
              currentFilter === props.activeFilter && "active"
            )}
          >
            {currentFilter}
          </Dropdown.Item>
        );
      })
      : null}
      </Dropdown.Menu>
    </Dropdown>
  );
}