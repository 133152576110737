import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import classNames from 'classnames';
import PresentationSlidesImage from '../../assets/images/presentation_slides.png';
import { routes } from '../../routes';

function Alert(props) {
  let className = classNames({
    "custom-alert d-flex justify-content-center align-items-center": true,
    [props.className]: props.className ? true : false
  });
  return (
    <div className={className}>
      <p className="p2">
        {props.children}
      </p>
    </div>
  );
}

export default Alert;