import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../components/Button';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import SittingIllustration from '../../assets/images/sitting.svg';
import TrainingToolsList from '../TrainingToolsList';

function AdditionalTrainingTools(props) {

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="additional-training-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="d-flex justify-content-start align-items-start title-section"> */}
          <h4>Additional Training Tools</h4>
          <div className="row mt-4">
            <div className="col">
              <ul className="session-file-list list-group list-group-flush">
              {props.files?.filter(file => file.sectionId === props.sectionId).map(file => 
                <TrainingToolsList 
                  file={file}
                  previewFile={props.previewFile}
                />
              )}
              </ul>
            </div>
          
          
          </div>
          
        {/* </div> */}

        
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}

export default AdditionalTrainingTools;