import types from "./types";
let nextDownloadId = 0;

// Modules
// to add category id in add module
export const addModule = (module, categoryId) => ({
  type: types.ADD_MODULE,
  payload: {
    id: module.id,
    data: module,
    categoryId: categoryId
  }
  
});

export const addModuleCategory = category => ({
  type: types.ADD_MODULE_CATEGORY,
  payload: {
    id: category.id,
    data: category
  }
})

// searching and filtering module category
export const setCategoryFilter = categoryId => ({
  type: types.SET_MODULE_CATEGORY_FILTER,
  payload: {
    category: categoryId
  }
})

export const setModuleSearch = text => ({
  type: types.SET_MODULE_SEARCH,
  payload: {
    filter: text
  }
})

// action for store moduleLearningObjective

export const addModuleLearningObjectives = (data, moduleId) => ({
  type: types.ADD_MODULE_LEARNING_OBJECTIVES,
  payload: {
    id: data.id,
    data,
    moduleId
  }
})

export const setLearningObjectivesInModule = (data,moduleId) => ({
  type: types.SET_LEARNING_OBJECTIVES_IN_MODULE,
  payload: {
    data,
    moduleId
  }
})

// action to store session file
export const addSessionFile = (moduleId, sectionId, sessionfileId, sessionfileObj) => ({
  type: types.ADD_SESSION_FILE,
  payload: {
    moduleId: moduleId,
    sectionId: sectionId,
    sessionfileId: sessionfileId,
    sessionfile: sessionfileObj,
  }
  
});

// Downloads
export const addDownload = (file, sessionType) => ({
  type: types.ADD_DOWNLOADS,
  payload: {
    id: ++nextDownloadId,
    sessionType: sessionType,
    file: file
  }
});

// Bookmarks
export const addBookmarkFolder = folder => ({
  type: types.ADD_BOOKMARK_FOLDER,
  payload: {
    folderId: folder.id,
    folderObj: folder
  }
});

export const addDefaultBookmarkFolder = folder => ({
  type: types.ADD_DEFAULT_BOOKMARK_FOLDER,
  payload: {
    folderId: folder.id,
    folderObj: folder
  }
});

export const renameBookmarkFolder = (folderId, newName) => ({
  type: types.RENAME_BOOKMARK_FOLDER,
  payload: {
    folderId,
    newName
  }
});

export const deleteBookmarkFolder = (folderId) => ({
  type: types.DELETE_BOOKMARK_FOLDER,
  payload: {
    folderId,
  }
});

export const addBookmarkItem = (folderId, itemId, item, sessionType) => ({
  type: types.ADD_BOOKMARK_ITEM,
  payload: {
    folderId: folderId,
    itemId: itemId,
    item: item,
    sessionType: sessionType,
    moduleId: sessionType == "modules" ? item?.id : item.moduleId || item.module?.id
  }
});

export const removeBookmarkItem = (folderId, itemId) => ({
  type: types.REMOVE_BOOKMARK_ITEM,
  payload: {
    folderId: folderId,
    itemId: itemId,
  }
});

export const addBookmarkContext = (sectionType, fileId, fileObj) => ({
  type: types.SET_BOOKMARK_CONTEXT,
  payload: {
    sectionType: sectionType,
    fileId: fileId,
    fileObj: fileObj
  }
});

export const setBookmarkFolderFilter = (folderId) => ({
  type: types.SET_BOOKMARK_FOLDER_FILTER,
  payload: {
    folderId
  }
});


// Training guides actions
export const addGuide = (sectionId, guideObj) => ({
  type: types.ADD_GUIDE,
  payload: {
    guideId: `${sectionId}-${guideObj.id}`,
    sectionId: sectionId,
    guideObj: guideObj
  }
});

// Additional Training Tools actions
export const addTool = (toolObj) => ({
  type: types.ADD_ADDITIONAL_TRAINING_TOOL,
  payload: {
    toolId: toolObj.id,
    toolObj: toolObj
  }
});

export const setSortFilter = filter => ({ type: types.SET_SORT_FILTER, payload: { filter } });

export const setSearchFilter = filter => ({ type: types.SET_SEARCH_FILTER, payload: { filter } });

/**Module Discussion */
export const addModuleDiscussionId = (id, moduleId) => ({
  type: types.ADD_MODULE_DISCUSSION_ID,
  payload: {
    moduleId,
    id
  }
})


// French Materials

export const addFrenchSection = section => ({
  type: types.ADD_FRENCH_SECTION,
  payload: {
    id: section.id,
    sectionName: section.section_name
  }
});

export const addFrenchContent = (content, sectionId) => ({
  type: types.ADD_FRENCH_CONTENT,
  payload: {
    id: content.id,
    content: content,
    sectionId: sectionId
  }
});

// video content
export const addVideo = (videoUrl, videoTitle, duration, thumbnail, cover, module) => ({
  type: types.ADD_VIDEO,
  payload: {
    videoTitle,
    videoUrl,
    duration,
    thumbnail,
    cover,
    module,
}});
// SPANISH Materials

export const addSpanishSection = section => ({
  type: types.ADD_SPANISH_SECTION,
  payload: {
    id: section.id,
    sectionName: section.section_name
  }
});

export const addSpanishContent = (content, sectionId) => ({
  type: types.ADD_SPANISH_CONTENT,
  payload: {
    id: content.id,
    content: content,
    sectionId: sectionId
  }
});

export const addSpanishVideos = (videos, sectionId) => ({
  type: types.ADD_SPANISH_VIDEO,
  payload: {
    videos: videos,
    sectionId: sectionId,
  }
});

export const addFrenchVideos = (videos, sectionId) => ({
  type: types.ADD_FRENCH_VIDEO,
  payload: {
    videos: videos,
    sectionId: sectionId,
  }
});
