import types from "../types";

const initialState = null

const bookmarkFolderFilter = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BOOKMARK_FOLDER_FILTER: {
      return action.payload.folderId;
    }
    default: {
      return state;
    }
  }
};

export default bookmarkFolderFilter;
