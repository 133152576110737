import classNames from 'classnames';
import SearchIcon from '../../assets/icons/search.svg';
import CloseIcon from '../../assets/icons/close.svg';
import { useRef } from 'react';


export default function SearchBar(props) {
  const { placeholder = "Search modules..." } = props;
  let className = classNames({
    "input-group search-bar": true,
    [props.className]: props.className ? true : false
  });
  const inputRef = useRef(null);

  const clearSearch = () => {
    props.clearSearch();
    if (inputRef && inputRef?.current) {
      console.log(inputRef?.current);
    }
  }

  return (
    <div className={className}>
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon1">
            <img src={SearchIcon} className="img-responsive" alt="search icon" />
          </span>
        </div>
        <input className="form-control" 
          value={props.value}
          type="search" placeholder={placeholder} id="example-search-input"
          onChange={(e) => props.onChange(e.target.value)}
          handleKeyDown={props.handleKeyDown}
          autoComplete="off"
          ref={inputRef}
        />
        <div className="input-group-append">
          <span className="input-group-text" id="basic-addon1" onClick={() => { clearSearch() }}>
            <img src={CloseIcon} className={`img-responsive ${props.value === "" ? "opacity-0" : ""}`} alt="search icon" />
          </span>
        </div>
    </div>
  );
}