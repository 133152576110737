export const DOWNLOADS_SORT_FILTERS = {
  DEFAULT: "Default order",
  FILEFORMAT: "Format",
  FILESIZE: "File size"
};


export const DISCISSIONS_SORT_FILTERS = {
  RECENT: "Recent",
  TOPDISCUSSIONS: "Top Discussions",
};

export const ACTIVITY_SORT_FILTERS = {
  RECENT: "Recent",
};