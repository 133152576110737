import classNames from 'classnames';
import ArrowWhite from '../../assets/icons/arrow-white.svg';


export default function ButtonDetailed(props) {
  const { text="Start Preparing for A Session", subtext="Session plans, slides, handouts and more." } = props;
  let className = classNames({
    'd-flex justify-content-between align-items-center': true,
    'button-detailed-primary button-primary btn-cool': 
      props.type === "primary" ? true : false,
    'button-detailed-outline button-outline': props.type === "outline" ? true : false,
    'button-detailed-tertiary button-tertiary': !props.type,
    'w-100': !props.minWidth ? true : false,
    // 'btn-lg': props.size && props.size === "lg",
    // 'btn-sm': props.size && props.size === "sm",
    // 'btn-md': props.size && props.size === "md",
    [props.className]: props.className ? true : false
  });

  return (
    <button className={className} onClick={props.onClick}>
      <div className="d-flex flex-column justify-content-center align-items-start">
        <p className="text">
          {text}
        </p>
        <span className="s1">{subtext}</span>
      </div>
      <div className="">
        <span className="icon">
          <img src={props.icon || ArrowWhite} className="img-responsive" alt="arrow icon" width="16" height="13.4" />
        </span>
      </div>
    </button>
  );
}