import React, { useState, useRef, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';
import { routes } from '../../routes';

const getUrlLast = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)

function ProfileNavbarTabs(props) {

  const [ active, setActive ] = useState();

  let { path, url } = useRouteMatch();
  let page = getUrlLast(url);
  console.log(">>", page)

  return (
 
    <div className="navbar-tabs profile-navbar-tabs">
       <div className="container-xl">
      <Nav
          activeKey={page || 'profile'}
          onSelect={(selectedKey) => setActive(selectedKey)}
        >
          <Nav.Item>
            <LinkContainer to={`/community/user/${props.userId}`}>
              <Nav.Link eventKey="profile">Community</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to={routes.userBookmarks}>
              <Nav.Link eventKey="profileBookmarks">Bookmarks</Nav.Link>
            </LinkContainer>
          </Nav.Item>
        </Nav>
      </div>
  </div>
  );
}

export default ProfileNavbarTabs;