export const routes = {
  // Common
  home: "/",
  about: "/about",
  newsAndUpdates: "/news-and-updates",
  // User
  signup: "/user/signup",
  login: "/user/login",
  userProfile: "/user/profile",
  userBookmarks: "/user/bookmarks",
  profileSettings: "/user/settings",
  resetPassword: "/user/reset-password",
  resetPasswordInput: "/user/reset-password-input",
  resetPasswordConfirm: "/user/reset-password/confirm/:uid/:token",
  verifyEmail: "/user/verify-email",
  //  Training
  training: "/training",
  frenchMaterials: "/materials-in-french",
  spanishMaterials: "/materials-in-spanish",
  moduleOverview: "/training/:slug",
  moduleSessions: "/training/:slug/sessions",
  moduleDownloads: "/training/:slug/downloads",
  moduleDiscussion: "/training/:slug/related-discussions",
  trainingGuides: "/training-guides",
  gettingStartedModuleStructure: "/getting-started/module-structure",
  gettingStartedNavigatingTheWebsite: "/getting-started/navigating-website",
  gettingStartedFaq: "/getting-started/faq",
  // Community
  discussions: "/community/discussions",
  discussion: "/community/discussions/:slug",
  guidelines: "/community/guidelines",
  publicUserProfile: "/community/user/:id",
  // Faq
  faq: "/faq",
  searchResults: "/search",
};