import React from 'react';
import classNames from 'classnames';

function PageHeader(props) {
  let className = classNames({
    'page-header': !props.lg ? true : false,
    'page-header-lg': props.lg ? true : false,
    'text-center-align': props.textAlign ? true :false,
    [props.className]: props.className ? true : false
  });
  return (
  <>
    <div className={className}>
      <div className="container-xl" >
         <h3>{ props.heading }</h3>
         <p className="p2">{props.children }</p>
      </div>
    </div>
  </>
  );
}

export default PageHeader;