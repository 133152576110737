import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import NavbarTabs from '../../components/NavbarTabs';
import PageContent from '../../components/PageContent';
import DiscussionList from '../../components/DiscussionList';
import CommunityWarning from '../../components/CommunityWarning';
import PostActionsModal from '../../components/PostActionsModal';
import LoginModal from '../../components/LoginModal';
import CommunityGuidelines from '../../components/CommunityGuidelinesModal';
import classNames from 'classnames';
import { findDiscussion } from '../../adaptors/xhr/communityService'
import {
  getModuleById,
  getDownloadsBySearchSortFilter
} from './ducks/selectors';
import {
  addModule,
  addModuleDiscussionId
} from './ducks/actions';
import { addPost, addTopic} from '../Community/ducks/actions'
import { getModules } from './ducks/selectors';
import { getModuleDetailServiceBySlug } from '../../adaptors/xhr/trainingService';
import { getPosts, getTopics, getFilteredDiscussionIds, getFilteredDiscussion } from '../Community/ducks/selectors'
import api from '../../adaptors/xhr/api';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import SearchEmpty from '../../components/SearchEmpty/SearchEmpty';
import TopNavbar from '../../components/TopNavbar';
import PageFooter from '../../components/PageFooter/PageFooter';
import { getLoginState } from '../../pages/Common/ducks/selectors';
import { routes } from '../../routes';


function Discussion(props) {
    const [ id, setId ] = useState(null);
    const history = useHistory();
    let { slug } = useParams();
    const module = useSelector((state) => getModuleById(state, id));
    const [guidelinesShow, setGuidelinesShow] = useState(false);
    const [createpostShow, setCreatePostShow] = useState(false);
    const [loginShow, setLoginShow] = useState(false);

    useEffect(() => {
        getModuleDetailServiceBySlug(slug)
        .then(response => {
            console.log('Success:', response.data);
            setId(response.data.id);
            props.addModule(response.data, response.data.category?.id);
            // set page title
            document.title=response.data.name;
        })
        .catch((error) => {
            console.error('Error:', error);
        })

        findDiscussion(module.name)
        .then((values) => {
            values.data?.results?.map((post) => {
                props.addPost(post)
                props.addModuleDiscussionId(post.id, id)
            })
        }).catch((err) => console.log(err))
    }, []);

    const onContinueGuidelines = () => {
        setGuidelinesShow(false);
        history.push(`${routes.discussions}/`);
        // if(props.currentUser.email)
        //     history.push(`${routes.discussions}/`);
        // else
        //     setLoginShow(true);
    }

    // const handlePostCreated = (post) => {
    //     // setCreatePostShow(false);
    //     props.addPost(post);
    //     history.push(`${routes.discussions}/${post.id}/`);
    //     // fetchDiscussions();
    // }
    const [bcHt, setBcHt] = useState(0);

    const setHt = (value) => {
        setBcHt(value);
    }

    return (
        <>
        <TopNavbar />
        <Navbar nosticky />
        <Breadcrumb
            breadcrumbs={["Home", "Training Modules", module.name]}
            links={["/","/training/",`/training/${module.slug}/`]}
            setHt={setHt}
        />
        <PageHeader heading={ module.name }/>
        <NavbarTabs
            discussionsNumbers = {module?.relatedDiscussionsId?.length}
            bcHt={bcHt}
        />
        <PageContent>
        <div className="row">
            <div className="col-xs-12 col-lg-8">
            <div className="training-overview-left">
                <div className="d-flex w-100 mb-4 justify-content-between align-items-center">
                <h5 className="mb-0">Related discussions</h5>

                </div>
                {console.log(module)}
                {/* <DiscussionList discussions={props.posts}/> */}
                {props.posts && props.posts.length
                ?props.modules.map((module) => {
                    if(module.id == id){
                        return(
                            module.relatedDiscussionsId
                            ?module.relatedDiscussionsId?.map((discussionId) => {
                                return(
                                    props.posts?.map((post, index) => {
                                        if(discussionId == post.id){
                                            return(<DiscussionList discussions={[post]}/>)
                                        }
                                    })
                                )
                            })
                        :<SearchEmpty message="No related discussions found" noAction/>)
                    }

                }
                )
                :<SearchEmpty  message="No related discussions found" noAction/>}
            </div>
            </div>

            <div className="col-xs-12 col-lg-3 offset-lg-1">
                <CommunityWarning
                    className="mt-4"
                    showModal={() => setGuidelinesShow(true)}
                />
            </div>
        </div>

        <CommunityGuidelines
            show={guidelinesShow}
            onHide={() => setGuidelinesShow(false)}
            onContinue={onContinueGuidelines}
        />


        <LoginModal
            show={loginShow}
            onHide={() => setLoginShow(false)}
        />
        </PageContent>

        <PageFooter/>
    </>
    );
    }

    const mapStateToProps = state => {
        const posts = getPosts(state);
        const topics = getTopics(state);
        const modules = getModules(state);
        const currentUser = getLoginState(state);
    return { currentUser, posts, topics, modules };
    };

    export default connect(
    mapStateToProps,
    {
        addModule,
        addPost,
        addTopic,
        addModuleDiscussionId
    }
    )(Discussion);
