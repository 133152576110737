import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import classNames from 'classnames';
import SearchIcon from '../../assets/icons/search.svg';
import Button from '../../components/Button';
import FileList from '../../components/FileList';
import FilterDropdown from '../../components/FilterDropdown/FilterDropdown';
import {DOWNLOADS_SORT_FILTERS} from "../../constants";
import DownloadIcon from '../../assets/icons/download-white.svg';
import ModuleCard from '../ModuleCard/ModuleCard';
import api from '../../adaptors/xhr/api';
import { routes } from '../../routes';



// const results = {
//   "modules": [
//       {
//           "id": 1,
//           "category": "Test Category",
//           "name": "Test",
//           "purpose": "Test Purpose",
//           "facilitators_guide": "Test guide",
//           "facilitators_guide_file": "http://127.0.0.1:8000/media/uploads/dummy.pdf",
//           "created_at": "2021-09-01T18:07:51.354648Z",
//           "updated_at": "2021-09-09T07:36:33.653332Z",
//           "language": "English",
//           "view_count": 0
//       }
//   ],
//   "resources": [
//       {
//           "id": 1,
//           "title": "test",
//           "size": 204288,
//           "module": "Test",
//           "details": "test data",
//           "file": "http://127.0.0.1:8000/media/uploads/blabla.ppt",
//           "file_type": "Powerpoint",
//           "created_at": "2021-09-02T16:36:32.248842Z",
//           "updated_at": "2021-09-02T16:36:32.248874Z"
//       },
//       {
//           "id": 2,
//           "title": "test2",
//           "size": 1570024,
//           "module": "Test",
//           "details": "test data",
//           "file": "http://127.0.0.1:8000/media/uploads/file_example_MP4_480_1_5MG.mp4",
//           "file_type": "Video",
//           "created_at": "2021-09-02T16:36:32.289956Z",
//           "updated_at": "2021-09-02T16:36:32.289997Z"
//       },
//       {
//           "id": 1,
//           "title": "Test",
//           "size": 13264,
//           "module": "Test",
//           "details": "test data",
//           "file": "http://127.0.0.1:8000/media/uploads/dummy_PHm0Vfu.pdf",
//           "file_type": "PDF Document",
//           "created_at": "2021-09-02T16:18:39.309770Z",
//           "updated_at": "2021-09-02T16:18:39.309804Z"
//       },
//       {
//           "id": 1,
//           "title": "test",
//           "size": 13264,
//           "module": "Test",
//           "details": "test_data",
//           "file": "http://127.0.0.1:8000/media/uploads/dummy_8ARgdzR.pdf",
//           "file_type": "PDF Document",
//           "created_at": "2021-09-02T16:24:06.141039Z",
//           "updated_at": "2021-09-02T16:24:06.141077Z"
//       },
//       {
//           "id": 1,
//           "title": "test",
//           "size": 89895,
//           "module": "Test",
//           "details": "test data",
//           "file": "http://127.0.0.1:8000/media/uploads/Formal-Resignation-Letter.docx",
//           "file_type": "Word Document",
//           "created_at": "2021-09-02T16:30:07.826307Z",
//           "updated_at": "2021-09-02T16:30:07.826336Z"
//       }
//   ]
// }


export default function Suggestions(props) {

  const { placeholder = "Search for modules and resources..." } = props;
  let className = classNames({
    "suggestions": true,
    [props.className]: props.className ? true : false
  });

  function openFile(fileObj) {
    console.log('openFile');
    if (fileObj?.url) 
      window.open(fileObj?.url, "_blank");
    else
      props.previewFile(fileObj)
  }


  return (
    <>
    <div className={className}>
      <div className="header">
      {props.isSearching ? 
      <div className="footer">
        <p className="p4">Searching •••</p>
      </div>
      : null }
      </div>
      <div className="scroll-results">
      {props.results?.modules?.length ? 
      <div className="section">
        <div className="title"><p className="c1">Modules</p></div>
        <ul class="list-group">
          {props.results.modules.map(module => <Link to={`${routes.training}/${module.id}/`}><li class="list-group-item p3">{module.name}</li></Link> )}
        </ul>
      </div>
      : null }
      {props.results?.resources?.length ? 
      <div className="section">
        <div className="title"><p className="c1">Resources</p></div>
        <ul class="list-group">
          {props.results.resources.map(res => <a href="#" onClick={() => openFile(res)}><li class="list-group-item p3">{res.title}</li></a> )}
        </ul>
      </div>
      : null }
      </div>
    
      {props.results?.modules?.length || props.results?.resources?.length ? 
      <div className="footer">
        <a href="#" onClick={props.showResults}><p className="p4">View all results •••</p></a>
      </div>
      : null }
    </div>
    </>
  );
}