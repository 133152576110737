import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { 
  fetchFaqSections,
  fetchFaqEntriesBySection
} from '../../adaptors/xhr/faqService';
import { addSection, addEntry, addSectionEntryRef } from './ducks/actions';
import { getEntries, getSections } from './ducks/selectors';

import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import NavbarTabs from '../../components/NavbarTabs';
import PageContent from '../../components/PageContent';
import classNames from 'classnames';
import FaqAccordian from '../../components/FaqAccordian/FaqAccordian';
import FaqEntry from '../../components/FaqEntry';
import TopNavbar from '../../components/TopNavbar';
import SectionCard from '../../components/SectionCard';
import PageFooter from '../../components/PageFooter/PageFooter';


export const FaqSection = props => {
  return (
    <FaqAccordian section={props.section.name}>
    {props.entries.filter(entry => entry.section === props.section.id)?.map(entry => {
      return (
        <FaqEntry
          key={`faq-entry-${entry.id}`}
          eventKey={entry.id}
          question={entry.question}
          answer={entry.answer}
        />
      )
    })}
  </FaqAccordian>
  )
}

function Faq(props) {

// ===========================================================================
// Handlers
// ===========================================================================

  /** Fetches Entries for a section from api */
  const addEntries = async (sectionId) => {
    try {
      const entriesApiResponse = await fetchFaqEntriesBySection(sectionId);
      entriesApiResponse.data.results.map(entry => {
        props.addEntry(entry, sectionId);
        props.addSectionEntryRef(sectionId, entry.id);
      })
    }
    catch (error) {
      console.error(error);
    }
    
  }

  const fetchData = async () => {
    try {
      // fetch data from api if store data empty
      if (!props.sections.length) {
        const sectionsApiResponse = await fetchFaqSections();
        sectionsApiResponse.data.results?.map(section => {
          props.addSection(section);
          // Add entries
          addEntries(section.id);
        })
      }
      
    }
    catch (error) {
      console.error(error);
    }
  }


// ===========================================================================
// Hooks
// ===========================================================================
  useEffect(() => {
    fetchData();
  }, [])


  return (
  <>
    <TopNavbar />
    <Navbar />
    <PageHeader lg heading="Frequency Asked Questions">
      <p className="p2">
        Get answer for most common questions. If you need any further help or have suggestions,
        &nbsp;<a href="mailto:info@fptraining.org">contact us</a>.
      </p>
    </PageHeader>

    <PageContent>
      <div className="row">
        <div className="col-xs-12 col-md-8 mx-md-auto">
          {props.sections?.map(section => {
            return (
              <FaqSection key={`faq-section-${section.id}`} section={section} entries={props.entries} />
            )
          })}
          <SectionCard/>
        </div>
      </div>
    </PageContent>
    <PageFooter/>
  </>
  );
}

const mapStateToProps = state => {
  const sections = getSections(state);
  const entries = getEntries(state);
  return { sections, entries };
};

export default connect(
  mapStateToProps,
  { addSection, addEntry, addSectionEntryRef }
)(Faq);