import types from "../types";
import { combineReducers } from "redux";

function searchText(state = "", action) {
  switch (action.type) {
    case types.ADD_SEARCH_TEXT: {
      const { payload } = action
      const { value } = payload
      return value;
    }
      
    default:
      return state
  }
}

function searchResults(state = {}, action) {
  switch (action.type) {
    case types.ADD_SEARCH_RESULTS: {
      const { payload } = action
      const { results } = payload
      return results;
    }
    default:
      return state
  }
}

const searchReducer = combineReducers({
  text: searchText,
  results: searchResults
})

export default searchReducer;