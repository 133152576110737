import types from "../types";
import { combineReducers } from "redux";

function toolsById(state = {}, action) {
  switch (action.type) {
    case types.ADD_ADDITIONAL_TRAINING_TOOL: {
      const { payload } = action
      const { toolId, toolObj } = payload
      const tool = { ...toolObj, toolId: toolId}
      return {
        ...state,
        [toolId]: tool
      }
    }
      
    default:
      return state
  }
}

function allTools(state = [], action) {
  switch (action.type) {
    case types.ADD_ADDITIONAL_TRAINING_TOOL: {
      const { payload } = action
      const { toolId } = payload
      return Array.from(new Set([...state, toolId]))
    }
    default:
      return state
  }
}

const toolsReducer = combineReducers({
  byId: toolsById,
  allIds: allTools
})

export default toolsReducer;