import React, { useEffect, useState }from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../components/Button';
import Nav from 'react-bootstrap/Nav';
import BookmarkFolders from '../../components/BookmarkFolders';
import RenameBookmarkFolder from '../../components/RenameBookmarkFolder';
import Alert from 'react-bootstrap/Alert';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import * as Yup from 'yup';
import FolderIcon from '../../assets/icons/folder.svg';
import api from '../../adaptors/xhr/api';

const bookmarkFolderSchema = Yup.object().shape({
  name: Yup.string()
  .min(1, "Folder name must have at least 1 characters")
  .max(20, "Folder name can't be longer than 20 characters")
  .required("Folder name is required"),
});

function BSModal(props) {
  const [ folderSelected, setFolderSelected ] = useState();
  const [ errors, setErrors ] = useState();
  const [ currentFoldername, setCurrentFoldername ] = useState();
  const {folders = [
    {id: 0, "name": "Saved Resources"},
    {id: 2, "name": "Others"}
  ]} = props;

  console.log(props.folderName)

  useEffect(() => {
    setCurrentFoldername(props.folderName);
  }, [])

  const selectedFolderHandler = folderId => {
    setFolderSelected(folderId);
    setErrors(null);
  }

  const createdFolderHandle = (newFolder) => {
    props.onCreatedFolder(newFolder);
    // Selcted created folder
    // selectedFolderHandler(newFolder.id)
  }

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, setFieldError, setStatus, resetForm}) => {
    setSubmitting(true);
    const { name } = values;
    try {
      // create folder
      const response = await api.Bookmarks.renameFolder(props.folderId, name);
      if (response.status === 200) {
        // succesfully created folder
        // setEmailSent(values.email);
        props.renameSuccess(response.data?.id, response.data?.folder_name);
        resetForm();  
      }
    }
    catch (error) {
      // read server validation errors
      // if (error.response.data?.non_field_errors)
      setFieldError('name', 'Unable to rename folder');
    }
    setSubmitting(false);
  }

  const onChangeFolderName = (text) => {
    console.log(text)
    setCurrentFoldername(text);
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="bookmark-folder-rename-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h6>Rename Folder</h6>
        </Modal.Title>
      </Modal.Header>
      <Formik
          validationSchema={bookmarkFolderSchema}
          onSubmit={submitHandle}
          initialValues={{
            name: props.folderName,
          }}
          onChange={props.onChange}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            status
          }) => (
            <Form className="form" noValidate onSubmit={handleSubmit}>
      <Modal.Body>
        {props.folders?.length 
          ? <BookmarkFolders 
              folders={props.folders}
              selectedFolder={selectedFolderHandler}
              className="mb-2" 
            /> 
          : null
        }
        {/* <RenameBookmarkFolder 
          onChange={onChangeFolderName} 
          onCreatedFolder={createdFolderHandle} 
          value={props.folderName}
        /> */}
        
              <div className="input-group rename-bookmark-folder">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <img src={FolderIcon} className="img-responsive" alt="search icon" />
                  </span>
                </div>
                <Form.Control 
                  value={props.name}
                  type="text" 
                  placeholder="Type folder name..." 
                  id="example-search-input"
                  name="name"
                  defaultValue={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // isValid={touched.email && !errors.email}
                  isInvalid={touched.name && !!errors.name}
                  feedback={errors.name}
                  required
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </div> 
           
       
        {/* {errors
        ?<Alert variant="danger" className="mt-3">
          {`${errors}`}
        </Alert>
        : null} */}
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col px-0 d-flex align-items-between justify-content-center">
            <Button size="lg"  onClick={props.onHide} text='Cancel' className="mr-2"/>
            <Button type="primary" size="lg"  submit text={isSubmitting ? 'Saving...' : 'Save'} disabled={isSubmitting} />
          </div>
        </div>
        
       
      </Modal.Footer>
       </Form>
       )}
     </Formik>
    </Modal>
  );
}

export default BSModal;