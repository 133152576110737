import types from "../types";
import { combineReducers } from "redux";

function guidesById(state = {}, action) {
  switch (action.type) {
    case types.ADD_GUIDE: {
      const { payload } = action
      const { guideId, sectionId, guideObj } = payload
      const guide = { ...guideObj, sectionId: sectionId, guideId: guideId}
      return {
        ...state,
        [guideId]: guide
      }
    }
      
    default:
      return state
  }
}

function allGuides(state = [], action) {
  switch (action.type) {
    case types.ADD_GUIDE: {
      const { payload } = action
      const { guideId } = payload
      return Array.from(new Set([...state, guideId]))
    }
    default:
      return state
  }
}

const guidesReducer = combineReducers({
  byId: guidesById,
  allIds: allGuides
})

export default guidesReducer;