import { combineReducers } from "redux";
import { postsReducer } from "./posts";
import commentsReducer from "./comments";
import topicsReducer from "./topics";
import activitiesReducer from "./activities";
import { contributorsReducer } from "./contributors";
import usersReducer from "./users";


export default combineReducers( {
  topics: topicsReducer,
  posts: postsReducer,
  comments: commentsReducer,
  activities: activitiesReducer,
  contributors: contributorsReducer,
  users: usersReducer,
});