import React, { useEffect, useState, useRef } from 'react';
import {
  Redirect,
  withRouter
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Navbar from '../../components/Navbar';
import TopNavbar from '../../components/TopNavbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageContent from '../../components/PageContent';
import PageFooter from '../../components/PageFooter/PageFooter';
import ProfileCard from '../../components/ProfileCard';
import Button from '../../components/Button';
import ProfileNavbarTabs from '../../components/ProfileNavbarTabs';
import { getLoginState } from '../Common/ducks/selectors';
import { getUserById, getActivities, getActivitiesPost, getActivitiesComment, getActivitiesByUserId, getTopics, getTopicById } from '../../pages/Community/ducks/selectors';
import { addUser, addActivity, addTopic, clearActivity, deleteActivity, updateActivity } from '../../pages/Community/ducks/actions';
import { getProfileFetch } from '../../pages/Common/ducks/operations';
import { routes } from '../../routes';
import {ACTIVITY_SORT_FILTERS} from "../../constants";
import FilterDropdown from '../../components/FilterDropdown';
import ActivityFilterNav from '../../components/ActivityFilterNav';
import PublicActivityList from '../../components/ActivityList/PublicActivityList';
import api from '../../adaptors/xhr/api';
import { useParams } from 'react-router-dom';
import DeleteActivityModal from '../../components/DeleteActivityModal';
import EditCommentModal from '../../components/EditCommentModal';
import PostActionsModal from '../../components/PostActionsModal/PostActionsModal';
import AddLinkModal from '../../components/AddLinkModal/AddLinkModal';


function PublicUserProfile(props) {
  const [activeKey, setActiveKey] = useState('all');
  // const [user, setUser] = useState()
  const [showEditPost, setShowEditPost] = useState(false);
  const [editPostContent, setEditPostContent] = useState({});

  const [showEditComment, setShowEditComment] = useState(false);
  const [editCommentId, setEditCommentId] = useState(0);
  const [editComment, setEditComment] = useState("");

  const [showDeleteActivity, setShowDeleteActivity] = useState(false);
  const [deleteActivityType, setDeleteActivityType] = useState("");
  const [deleteActivityId, setDeleteActivityId] = useState(0);
  const [deleteActivityContent, setDeleteActivityContent] = useState({});

  let { id } = useParams();

  const user = useSelector((state) => getUserById(state, id));
  const activities = useSelector((state) => getActivitiesByUserId(state, id))

  const fetchTopics = async () => {
    const response = await api.Forum.topics();
    response.data?.results?.map(topic => {
      props.addTopic(topic)
    })
  }
  useEffect(() => {
    !props?.topics.length && fetchTopics();
  }, [])


  useEffect(() => {
    fetchActivity();
    fetchUser();
  }, [id]);

  const fetchUser = async () => {
    const response = await api.Auth.userProfile(id);
    props.addUser(response.data);
    // setUser(response.data)

  }

  const fetchActivity = async () => {
    const response = await api.Forum.useractivityById(id);

    response.data?.results?.map(activity => {
      props.addActivity(id, activity);
    })
  }

  const handleSettings = () => {
    props.history.push(routes.profileSettings);
  }

  const editActivity = (activityType, item) => {
    switch (activityType) {
      case "post":
        setEditPostContent(item?.post);
        setShowEditPost(true);
        break;
      case "comment":
        setEditCommentId(item?.comment?.id);
        setEditComment(item?.comment?.comment);
        setShowEditComment(true);
        break;
      default:
        break;
    }
  }

  const getTopicById = (topicId) => {
    return props.topics?.find(topic => topic?.id === topicId)?.name;
  }

  const deleteActivity = (activityType, item) => {
    setDeleteActivityType(activityType);
    setDeleteActivityId(item?.id);
    switch (activityType) {
      case "post":
        setDeleteActivityContent(item?.post);
        break;
      case "comment":
        setDeleteActivityContent(item?.comment);
        break;
      default:
        break;
    }
    console.log(activityType)
    setShowDeleteActivity(true);
  }

  const quillRef = useRef(null);
  const [quillEditor, setQuillEditor] = useState(null);
  const [addLinkShow, setAddLinkShow] = useState(false);
  const [selection, setSelection] = useState("");
  const [rangeIndex, setRangeIndex] = useState(0);

  const linkCallback = (link) => {
    quillRef.current.getEditor().format('link', link);
  }

  const newTextLinkCallback = (text, link) => {
    // let range = quillRef.current.getEditor().getSelection();
    // let contents = quillRef.current.getEditor().getContents();
    // quillRef.current.getEditor().setContents(contents.slice(0, rangeIndex) + text + contents.slice(rangeIndex));
    // quillRef.current.getEditor().formatText(rangeIndex, text.length, 'link', link);
    quillRef.current.getEditor().insertText(rangeIndex, text, 'link', link);
  }

  const linkHandler = (value) => {
    // if (value) {
      let range = quillRef.current.getEditor().getSelection();
      // if (range == null || range.length === 0) {
      //     return;
      // }
      console.log(range);
      if (range) {
        setRangeIndex(range.index);
      }

      setSelection(quillRef.current.getEditor().getText(range.index, range.length));

      setAddLinkShow(true);

      // let tooltip = quillRef.current.getEditor().theme.tooltip;
      // tooltip.edit('link', '');
      // console.log(tooltip);
    // } else {
    //   quillRef.current.getEditor().format('link', false)
    //   console.log("no value");
    // }
  };

  return (
    <>
      <TopNavbar/>
      <Navbar/>
      {
        props.currentUser.pk == id
        ?
        <Breadcrumb
          breadcrumbs={["Home", "My Account"]}
          links={["/", "#"]}
          className="profile-breadcrumbs"
        />
        :
        <Breadcrumb
          breadcrumbs={["Home", "Community", "People", `${user.first_name} ${user.last_name}`]}
          links={["/","/community/discussions","/community/discussions", "#"]}
          className="profile-breadcrumbs"
        />
      }
      <div className="public-profile-header">
        <div className="container-xl">
          <div className="d-flex justify-content-between align-items-center">
            <ProfileCard
              firstName={user?.first_name}
              lastName={user?.last_name}
              thumbnail={user?.profile_picture}
              points={user?.points}
              linkedinUrl={user?.linkedin_url}
              bio={user?.bio}
            />
            {props.currentUser.pk == id ? <Button type="outline" size="md" text="Settings" minWidth onClick={handleSettings}  /> : null}
          </div>
        </div>
      </div>
      {
        props.currentUser.pk == id ?
        <ProfileNavbarTabs userId={id} className="profile-navbar-tabs"/> :
        null
      }
      <PageContent>
      <div className="row">
        <div className="col-xs-12 col-lg-8">
          <div className="training-overview-left">
            <div className="d-flex w-100 mb-4 justify-content-between align-items-center">
              {/* <h5 className="mb-0">All discussions</h5> */}
              <FilterDropdown
                onSelect={(filter) => console.log(filter)}
                className="mr-2"
                sortFilters={ACTIVITY_SORT_FILTERS}
                activeFilter="Recent"
              />
            </div>
            {
              activeKey === 'all' ?
                <PublicActivityList
                  activities={activities}
                  userProfile={user}
                  editActivity={editActivity}
                  deleteActivity={deleteActivity}
                  activityEditable={props.currentUser.pk == id}
                />
                :
                activeKey === '1'?
                  <PublicActivityList
                    activities={activities.filter(activity => activity.type === 'post')}
                    userProfile={user}
                    filterKey={"post"}
                    editActivity={editActivity}
                    deleteActivity={deleteActivity}
                    activityEditable={props.currentUser.pk == id}
                  />
                  :
                  <PublicActivityList
                    activities={activities.filter(activity => activity.type === 'comment')}
                    userProfile={user}
                    filterKey={"comment"}
                    editActivity={editActivity}
                    deleteActivity={deleteActivity}
                    activityEditable={props.currentUser.pk == id}
                  />
            }
          </div>
        </div>

        <div className="col-xs-12 col-lg-3 offset-lg-1">
          <ActivityFilterNav
            onSelect={(selectedKey) => setActiveKey(selectedKey)}
            activeKey={activeKey}
            className="my-4 d-none d-lg-block"
          />
        </div>
      </div>

      <DeleteActivityModal
        show={showDeleteActivity}
        onHide={() => setShowDeleteActivity(false)}
        activityType={deleteActivityType}
        activityContent={deleteActivityContent}
        onDeleteComplete={() => {
          console.log("Deleting activity");
          props.deleteActivity(id, deleteActivityId);
        }}
      />

      <EditCommentModal
        linkHandler={linkHandler}
        quillRef={quillRef}
        show={showEditComment}
        onHide={() => setShowEditComment(false)}
        editCommentId={editCommentId}
        editComment={editComment}
        onUpdateComment={(data) => {
          console.log("Edit Comment data", data)
          props.clearActivity();
          fetchActivity();
        }}
      />

      <PostActionsModal
        className={addLinkShow ? "modal-backdrop" : ""}
        linkHandler={linkHandler}
        quillRef={quillRef}
        show={showEditPost}
        onHide={() => setShowEditPost(false)}
        onActionComplete={() => {
          props.clearActivity();
          fetchActivity();
          setShowEditPost(false);
        }}
        topics={props.topics}
        actionType={"Edit"}
        postContent={{
          id: editPostContent?.id,
          topic: editPostContent?.category,
          title: editPostContent?.title,
          body: editPostContent?.body
        }}
      />

      <AddLinkModal
        show={addLinkShow}
        onHide={() => setAddLinkShow(false)}
        text={selection}
        linkCallback={linkCallback}
        newTextLinkCallback={newTextLinkCallback}
      />

      </PageContent>
      <PageFooter/>
    </>
    );
  }



const mapStateToProps = state => {
  const currentUser = getLoginState(state);
  // const activities = getActivities(state);
  // const post = getActivitiesPost(state)
  // const comments = getActivitiesComment(state)
  const topics = getTopics(state);
  return { currentUser, topics };
};

export default connect(
  mapStateToProps,
  { getProfileFetch, addUser, addActivity, addTopic, clearActivity, deleteActivity }
)(withRouter(PublicUserProfile));
