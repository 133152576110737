import React from 'react';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form'

function FormSelect(props) {
  let className = classNames({
    // "mb-4": true,
    "required": props.required ? true : false,
    [props.className]: props.className ? true : false
  });
  return (
      <Form.Group className={className}>
        {props.label ?
          <Form.Label>{props.label}</Form.Label>
          : null
        }
        {/* <Form.Select> */}
        <Form.Control 
          as="select"  
          className="form-select"
          name={props.name}
          value={props.value}
          // value = {
          //   props.options.filter(option => 
          //      option === props.value)
          // }
          defaultValue={props.defaultValue}
          onChange={props.onChange} 
          onBlur={props.handleBlur}
          isValid={props.isValid}
          isInvalid={props.isInvalid}
          disabled={props.disabled}
        >
          <option value="">Select</option>
          {props.plainOptions 
          ? props.options?.map((option, idx) => <option value={option} key={"option-" + idx}>{option}</option>)
          : props.options?.map((option, idx) => <option value={option.id} key={"option-" + idx}>{option.name}</option>)
          }
          
        </Form.Control>
        {/* <select className="form-select">
          <option selected>Select</option> */}
          {/* {props.options?.map(option => <option value={option.id}>{option.name}</option>)} */}
        {/* </Form.Select> */}
        {/* </select> */}
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback>
      </Form.Group>
  );
}

export default FormSelect;