import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import CookieConsent from "react-cookie-consent";
import { routes } from './routes';
import Modules from './pages/Training/Modules';
import Overview from './pages/Training/Overview';
import Sessions from './pages/Training/Sessions';
import Downloads from './pages/Training/Downloads';
import moduleDiscussion from './pages/Training/moduleDiscussions';
import Faq from './pages/Faq/Faq';
import Home from './pages/Common/Home';
import DiscussionHome from './pages/Community/DiscussionHome';
import Discussion from './pages/Community/Discussion';
import './App.scss';
import Guides from './pages/Training/Guides';
import SignupPage from './pages/Common/SignupPage';
import LoginPage from './pages/Common/LoginPage';
import ResetPasswordPage from './pages/Common/ResetPasswordPage';
import ResetPasswordConfirmPage from './pages/Common/ResetPasswordConfirmPage';
import EmailVerify from './pages/Common/EmailVerify';
import About from './pages/Common/About';
import ProfileSettings from './pages/Common/ProfileSettings';
import GettingStartedModuleStructure from './pages/Training/GettingStartedModuleStructure';
import GettingStartedFaq from './pages/Training/GettingStartedFaq';
import UserBookmarks from './pages/Common/UserBookmarks';
import ScrollToTop from './components/ScrollTOTop/ScrollToTop';
import FrenchMaterials from './pages/Training/FrenchMaterials';
import SearchResults from './pages/Common/SearchResults';
import GettingStartedNavigatingTheWebsiteite from './pages/Training/GettingStartedNavigatingTheWebsiteite';
import SpanishMaterials from './pages/Training/SpanishMaterials';
import NewsAndUpdates from './pages/Common/NewsAndUpdates';
import publicUserProfile from './pages/Community/UserProfile';
import { getProfileFetch } from './pages/Common/ducks/operations';
import { getIsExpanded } from './pages/Common/ducks/selectors';
import ResetPasswordInputPage from './pages/Common/ResetPasswordInputPage';
import CommunityGuidelines from './pages/Common/CommunityGuidelines';
import FeedbackButton from './components/FeedbackButton';

function App(props) {

  useEffect(() => {
    props.getProfileFetch();
  }, []);

  return (
    <Router>
      <ScrollToTop />
       <div className={`App ${props.isExpanded ? 'overflow-hidden' : ''}`}>
        <Switch>
          <Route exact path={routes.home} component={Home} />
          <Route exact path={routes.about} component={About} />
          <Route exact path={routes.gettingStartedModuleStructure} component={GettingStartedModuleStructure} />
          <Route exact path={routes.gettingStartedFaq} component={GettingStartedFaq} />
          <Route exact path={routes.gettingStartedNavigatingTheWebsite} component={GettingStartedNavigatingTheWebsiteite} />
          <Route exact path={routes.trainingGuides} component={Guides} />
          <Route exact path={routes.training} component={Modules} />
          <Route exact path={routes.frenchMaterials} component={FrenchMaterials} />
          <Route exact path={routes.spanishMaterials} component={SpanishMaterials} />
          <Route exact path={routes.moduleOverview} component={Overview} />
          <Route exact path={routes.moduleSessions} component={Sessions} />
          <Route exact path={routes.moduleDownloads} component={Downloads} />
          <Route exact path={routes.moduleDiscussion} component={moduleDiscussion} />
          <Route exact path={routes.faq} component={Faq} />
          <Route exact path={routes.signup} component={SignupPage} />
          <Route exact path={routes.login} component={LoginPage} />
          {/* <Route exact path={routes.userProfile} component={UserProfile} /> */}
          <Route exact path={routes.userBookmarks} component={UserBookmarks} />
          <Route exact path={routes.profileSettings} component={ProfileSettings} />
          <Route exact path={routes.resetPassword} component={ResetPasswordPage} />
          <Route exact path={routes.resetPasswordInput} component={ResetPasswordInputPage} />
          <Route exact path={routes.resetPasswordConfirm} component={ResetPasswordConfirmPage} />
          <Route exact path={routes.verifyEmail} component={EmailVerify} />
          <Route exact path={routes.discussions} component={DiscussionHome} />
          <Route exact path={routes.discussion} component={Discussion} />
          <Route exact path={routes.publicUserProfile} component={publicUserProfile}/>
          <Route exact path={routes.searchResults} component={SearchResults} />
          <Route exact path={routes.newsAndUpdates} component={NewsAndUpdates} />
          <Route exact path={routes.guidelines} component={CommunityGuidelines} />
          <Route render={() => <h3>404: page not found</h3>} />
        </Switch>
        <CookieConsent
          location="bottom"
          buttonText="I agree"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" ,background:"#FFFFFF" }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
        <FeedbackButton />
       </div>
    </Router>
  );
}

const mapStateToProps = state => {
  const isExpanded = getIsExpanded(state);
  return {
    isExpanded,
  };
};


export default connect(
  mapStateToProps,
  { getProfileFetch, getIsExpanded }
)(App);
