import types from "../types";
import { combineReducers } from "redux";

function usersById(state = {}, action) {
  switch (action.type) {
    case types.ADD_USER: {
      const { payload } = action
      const { userId, userObj } = payload
      return {
        ...state,
        [userId]: userObj
      }
    }
      
    default:
      return state
  }
}

function allUsers(state = [], action) {
  switch (action.type) {
    case types.ADD_USER: {
      const { payload } = action
      const { userId  } = payload
      if(state.indexOf(userId) === -1){
        return state.concat(userId)
      }
    }
    default:
      return state
  }
}

const usersReducer = combineReducers({
  byId: usersById,
  allIds: allUsers
  })

export default usersReducer;