import React, { useEffect, useState } from 'react';
import {
  useParams
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Navbar from '../../components/Navbar';
import PageHeader from '../../components/PageHeader';
import PageContent from '../../components/PageContent';
import DownloadSection from '../../components/DownloadSection';
import {
  downloadSpanishMaterials,
  getSpanishMaterialVideosBySectionId,
  getSpanishMaterialVideoCoverBySectionId
} from '../../adaptors/xhr/trainingService';
import {
  getModuleById,
  getDownloadsBySearchSortFilter,
  getSpanishSections,
  getSpanishContentBySearchFilter,
  getSpanishContent,
  getSpanishVideos,
} from './ducks/selectors';
import {
  addModule,
  addDownload,
  setSortFilter,
  setSearchFilter,
  addBookmarkFolder,
  addBookmarkItem,
  addBookmarkContext,
  addSessionFile,
  removeBookmarkItem,
  addSpanishSection,
  addSpanishContent,
  addSpanishVideos,
} from './ducks/actions';
import { addColorGuides } from '../Common/ducks/actions';
import { getLoginState, getColorGuidesContent } from '../Common/ducks/selectors';
import { getBookmarksFolders, getBookmarkItems, getBookmarkItemsList, getBookmarkContextState, getSessionpreps } from './ducks/selectors';
import api from '../../adaptors/xhr/api';
import SearchBar from '../../components/SearchBar';
import Button from '../../components/Button';
import DownloadIcon from '../../assets/icons/download-white.svg';
import SearchEmpty from '../../components/SearchEmpty/SearchEmpty';
import BookmarkModal from '../../components/BookmarkModal';
import TopNavbar from '../../components/TopNavbar';
import PageFooter from '../../components/PageFooter/PageFooter';
import FilePreviewModal from '../../components/FilePreviewModal';
import LoginModal from '../../components/LoginModal';
import SlidesBanner from '../../components/SlidesBanner';
import colorGuide from '../../adaptors/xhr/colorGuideService';
import ColorGuide from '../../components/ColorGuide';
import VideoBlock from '../../components/VideoBlock/VideoBlock';
import VideoBlockSmall from '../../components/VideoBlockSmall';
import VideoBlockSpanish from '../../components/VideoBlockSpanish/VideoBlockSpanish';
import TrainingAccordian from '../../components/TrainingAccordian/TrainingAccordian';
import { Accordion } from 'react-bootstrap';

function SpanishMaterials(props) {
  console.log("props", props);
  const [modalShow, setModalShow] = useState(false);
  const [ previewModalShow, setPreviewModalShow ] = useState(false);
  const [ previewFileObj, setPreviewFileObj ] = useState(null);
  const [ colorGuideShow, setColorGuideShow ] = useState(false);
  const [ loginShow, setLoginShow ] = useState(false);

  let { id } = useParams();
  const module = useSelector((state) => getModuleById(state, id));

  const [videos, setVideos] = useState({});

  const fetchVideo = async (id) => {
    const response = await getSpanishMaterialVideosBySectionId(id);
    setVideos([...videos, response.data]);
  }

  const previewFile = (fileObj) => {
    setPreviewFileObj(fileObj)
    setPreviewModalShow(true);
  }

  const fetchColorGuide = async () => {
    const response = await colorGuide.spanishContent();
    if(response.data) {
      props.addColorGuides(response.data);
    }
  }

  const showColorGuides = () => {
    setColorGuideShow(true)
  }

  const fetchContent = async () => {
    const response = await api.Training.spanishMaterials();
    console.log("res",response);
    if (response.status === 200) {
      response.data.map(async (section) => {
        props.addSpanishSection(section);
        section.content.map(contentItem => {
          props.addSpanishContent(contentItem, section.id);
          // fetchVideo(section.id);
        });
        const resp = await getSpanishMaterialVideosBySectionId(section.id);
        if (resp.status === 200) {
          // videos[section.id] = resp.data;
          // setVideos(videos)
          console.log(section.id, resp.data)
          props.addSpanishVideos(resp.data, section.id)
          // resp.data.map(async (video) => {
            // props.addSpanishVideo(video, section.id);
            // videos[section.id] = videos[section.id] && videos[section.id].length ? [video, ...videos[section.id]] : [video]
            // setVideos({ [section.id]: videos[section.id] && videos[section.id].length ? [...videos[section.id], ...video] : video, ...videos })
          // })
        }
        console.log("videos", props.spanishVideos)
      })
    }
  }

  useEffect(() => {
    clearSearch();
    fetchContent();
    fetchColorGuide();
  }, [])

  function searchDownloads(text) {
    props.setSearchFilter(text);
  }

  function clearSearch() {
    props.setSearchFilter("");
  }


  return (
  <>
    <TopNavbar />
    <Navbar nosticky />
    {/* <Breadcrumb breadcrumbs={["Home", "Training", module.name]} links={["/", "/training", "/"]}/> */}
    <PageHeader heading="Materiales en Español"/>
    {/* <NavbarTabs/> */}
    <PageContent>
      <div className="row other-language-materials">
        <div className="col">
          <p className="p3">
            En ce moment, nous n'avons pas toutes les composantes de tous les modules de formation en français. Ici, veuillez trouver les collections de diapositives pour les séances de formation ainsi qu'un guide du formateur pour le module sur le «Services de Counseling»en planification familiale. Le Guide du formateur contient des informations sur le plan d’ensemble du module, une description du matériel utilisé et des objectifs d’apprentissage visés, des conseils sur la façon d’exécuter les jeux de rôle, la clé de correction des tests préliminaires et des tests de contrôle, des études de cas et des conseils sur la façon de dispenser la formation clinique.
          </p>
        </div>
        <div className="col-sm-4">
          <SlidesBanner es showColorGuides={showColorGuides} className="my-4"/>
        </div>
      </div>

      <div className="row">

        <div className="col-xs-12 col-md-6">
          <SearchBar
            className="w-100"
            placeholder="Search by file name or type..."
            onChange={searchDownloads}
            value={props.searchFilter}
          />
        </div>

        <div className="offset-lg-2 col mt-2 mt-md-0 d-flex justify-content-sm-end align-items-center">
          <div className="" >
          <Button
            type="primary"
            size="md"
            text="Download All files"
            icon={DownloadIcon}
            className="min-width-btn"
            minWidth
            onClick={() => downloadSpanishMaterials()}
          />

          </div>
        </div>

      </div>
      <div className="bpy-5">
      <div className="downloads-section mt-4">
      <div className="col d-none d-md-block">
        <li className="row header d-flex align-items-center">
          <div className="col-md-7 mr-5 pl-0">
            <span className="">File Name</span>
          </div>
          <div className="col-md-2 ml-4">
            <span className="filetype">File Type</span>
          </div>
          <div className="col-md-1 ml-4">
            <span className="filesize">Size</span>
          </div>
        </li>
        </div>

      </div>

      {/* {props.bookmarks.length
        ? <DownloadSection
          title="Bookmarked"
          files={props.bookmarks}
          previewFile={previewFile}
        />
        : null
      } */}

      {props.spanishSections && props.spanishSections.length && props.spanishContent.length
        ? props.spanishSections.map(section =>
          <>
            <DownloadSection
              className="mb-5"
              title={section.sectionName}
              // Avoid listing reference urls
              files={props.spanishContent?.filter(file => file.sectionId === section.id)}
              previewFile={previewFile}
              // onBookmark={() => setModalShow(true)}
              // bookmarkItemsIds={props.bookmarkItemsIds}
              // addBookmark={addBookmark}
              // removeBookmark={removeBookmark}
              noBookmark={true}
            />
            { props.spanishVideos && props.spanishVideos[section.id] && props.spanishVideos[section.id].length > 0 ?
              <div id="videos" className='d-none d-sm-block'>
              <VideoBlockSpanish videoData={
                props.spanishVideos[section.id]
              } videoDecs={""} id="videos"/>
            </div> : null}

            { props.spanishVideos && props.spanishVideos[section.id] && props.spanishVideos[section.id].length > 0 ?
              <Accordion className='w-100 materials-video-block-small'>
                <div className="d-block d-sm-none">
                  <VideoBlockSmall
                    moduleId={section.id}
                    eventKey="5"
                    title="Videos"
                    sectionId="videos"
                    description=""
                    videoData={props.spanishVideos[section.id]}
                  />
                </div>
              </Accordion>: null}
          </>
        )
        : null
      }

      {props.spanishContent && props.spanishContent.length === 0 && props.searchFilter
        ? <SearchEmpty clearSearch={clearSearch} />
        : null
      }



    </div>
    <BookmarkModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      folders={props.bookmarksFolders}
      bookmarkContext={props.bookmarkContext}
      onCreatedFolder={props.addBookmarkFolder}
      bookmarkSuccess={props.addBookmarkItem} // action
    />

    <ColorGuide
      show={colorGuideShow}
      onHide={() => setColorGuideShow(false)}
      content={props.colorGuide}
    />

    <FilePreviewModal
      show={previewModalShow}
      onHide={() => setPreviewModalShow(false)}
      fileObj={previewFileObj}
    />

    <LoginModal
      show={loginShow}
      onHide={() => setLoginShow(false)}
    />

    </PageContent>
    <PageFooter/>
  </>
  );
}

const mapStateToProps = state => {
  const sessionprepfiles = getSessionpreps(state);
  const sortFilter = state.training.sortFilter;
  const searchFilter = state.training.searchFilter;
  const downloads = getDownloadsBySearchSortFilter(state, sortFilter, searchFilter);
  const bookmarkContext = getBookmarkContextState(state);
  const bookmarkItemsIds = getBookmarkItemsList(state);
  const bookmarksFolders = getBookmarksFolders(state);
  const bookmarks = getBookmarkItems(state);
  const currentUser = getLoginState(state);
  const spanishSections = getSpanishSections(state);
  const spanishContent = getSpanishContentBySearchFilter(state, searchFilter);
  const spanishVideos = getSpanishVideos(state);
  // console.log("spanishVideo", spanishVideo);
  const colorGuide = getColorGuidesContent(state);
  return { currentUser, searchFilter, sortFilter, downloads,
    bookmarksFolders, bookmarkItemsIds, bookmarkContext, bookmarks, sessionprepfiles,
    spanishSections, spanishContent, colorGuide, spanishVideos};
};

export default connect(
  mapStateToProps,
  { addModule,
    addSessionFile,
    addDownload,
    setSortFilter,
    setSearchFilter,
    addBookmarkFolder,
    addBookmarkContext,
    addBookmarkItem,
    removeBookmarkItem,
    addSpanishSection,
    addSpanishContent,
    addColorGuides,
    addSpanishVideos
  }
)(SpanishMaterials);
