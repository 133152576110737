import React from 'react';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';
import classNames from 'classnames';
import { routes } from '../../routes';

function DiscussionFilterNav(props) {

  const { filters=[
    {"id": "all", "name": "All discussions"}, 
    {"id": 1, "name": "Modules and resources"},
    {"id": 2, "name": "Using the platform"},
    {"id": 3, "name": "Facilitator tips and techniques"},
    {"id": 4, "name": "TRP in Action"}
  ] } = props;

  let { path, url } = useRouteMatch();

  let className = classNames({
    'discussion-filter-nav filter-nav': true,
    [props.className]: props.className ? true : false
  });

  return (

    <div className={className}>
      <Nav 
        defaultActiveKey={props.activeKey || "all"}
        className="flex-column discussion-nav"
        onSelect={(selectedKey) => props.onSelect(selectedKey)}>
          <LinkContainer to={routes.discussions}><Nav.Link eventKey="all"><h6>All discussions</h6></Nav.Link></LinkContainer>
          {filters?.map(f => <LinkContainer to={`?topic=${f.id}`}><Nav.Link key={`filter-${f.id}`} eventKey={f.id} href={`?topic=${f.id}`}><h6>{f.name}</h6></Nav.Link></LinkContainer> )}
      </Nav>
    </div>
  );
}

export default DiscussionFilterNav;