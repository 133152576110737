import React from 'react';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';

function FormInput(props) {
  let className = classNames({
    // "mb-3": true,
    "required": props.required ? true : false,
    [props.className]: props.className ? true : false
  });
  return (
      <Form.Group className={className} controlId={`form-${props.name}`}>
        {props.label ?
          <Form.Label>{props.label}</Form.Label>
          : null
        }
        <Form.Control 
          type={props.type} 
          name={props.name}
          value={props.value}
          placeholder={props.placeholder} 
          onChange={props.onChange} 
          onBlur={props.handleBlur}
          isValid={props.isValid}
          isInvalid={props.isInvalid}
          disabled={props.disabled}
        />
        {props.help ?
        <span className="p4">{props.help}</span>
        : null
        }
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback>
      </Form.Group>

  );
}

export default FormInput;