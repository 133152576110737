import types from "../types";

const initialState = {
    byIds: {},
    allIds: []
};

export function videoReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_VIDEO: {
      const { videoUrl, videoTitle, duration, thumbnail, cover, module } = action.payload;
      return {
        [module]: {
          videoUrl,
          videoTitle,
          duration,
          thumbnail,
          cover,
        }
      };
    }
    default:
      return state;
  }
}