import React from 'react';

function NumberedList(props) {
  return (
      <ul className="list-group list-group-flush">
        {props.list?.map((item, index) => {
          return (
            <li href="#" key={`numlist-${index}`} className="list-group-item list-group-item-action active" aria-current="true">
              <div className="d-flex w-100 justify-content-start align-items-center">
                <div className="index d-flex justify-content-center align-items-center">
                  <span>{index+1}</span>
                </div>
                <div>
                  <h6 className="mb-1 title-class">{item.title}</h6>
                  <p className="p4 mb-1">{item.details}</p>
                </div>
              </div>
            </li>
          )
        })}
        
      </ul>
  );
}

export default NumberedList;