import React from 'react';
import classNames from 'classnames';
import ButtonXs from '../ButtonXs';
import BookmarkIcon from '../../assets/icons/bookmark.svg';
import BookmarkIconFill from '../../assets/icons/bookmark-fill.svg';

function BookmarkButton(props) {

  let className = classNames({
    'bookmark-btn mx-2': true,
    [props.className]: props.className ? true : false
  });

  return (
    <div className={className}>
    <ButtonXs 
      size="sm" 
      text="View" 
      icon={props.bookmarked ? BookmarkIconFill : BookmarkIcon} 
      onClick={props.bookmarked ? props.removeBookmark : props.addBookmark}
      noHide 
    />
    </div>
  );
}

export default BookmarkButton;