//Reducer for handeling categories of the modules in training
import { combineReducers } from "redux";
import types from "../types";


export const initialState = {
  byId: {
    "module_session_plan": {
      "name": "Module Session Plan",
      "sectionId": "module_session_plan",
      "description": "The session plan describes the subject content, method of instruction, learning activities, resources and materials that will support you in preparing for and conducting the session. It includes a training schedule that provides a breakdown of the module by day, time, and topic." 
    },
    "presentation_slides": {
      "name": "Presentation slides",
      "sectionId": "presentation_slides",
      "description": "The presentation slides provide technical information on the subject matter. The text for these slides can be found in the speaker notes."
    },
    "evaluation_tools": {
      "name": "Evaluation Tools",
      "sectionId": "evaluation_tools",
      "description": "The module contains tools for evaluating the knowledge and skill of trainees. These include pre- and post-knowledge assessment questionnaires having  objective questions, with instructions for scoring the tests and  competency-based skills checklists for assessing the acqired skills during and after the training. It also contains the course evaluation tool."
    },
    "handouts": {
      "name": "Handouts",
      "sectionId": "handouts",
      "description": "The module has a set of handouts with  specific information and key take away messages  and Job aids to support learning at workplace. Handouts should be printed and distributed to the learners."
    },
    "references": {
      "name": "References",
      "sectionId": "references",
      "description": "The main references for the implants module as well as for other modules of the TRP are the World Health Organization’s four cornerstones of family planning guidance."
    }
  },
  allIds: ["module_session_plan", "presentation_slides", "evaluation_tools", "handouts", "references"],
};


export function sessionprepSectionsReducer(state = initialState, action) {
  return state;
}
