import React from 'react';
import {
  withRouter,
  Link
} from "react-router-dom";
import classNames from 'classnames';
import PresentationSlidesImage from '../../assets/images/presentation_slides.png';
import Button from '../Button';
import { routes } from '../../routes';




function ModuleCard(props) {

  let className = classNames({
    "module-card": true,
    "alert ": true,
    [props.className]: props.className ? true : false
  });

  return (
    <div className={className}>
      <Link to={`${routes.training}/${props.moduleId}/`}><p className="p3 mb-1">{props.name}</p></Link>
      {/* <p className="p2">Contraceptive Implants</p> */}
      <p className="p4 mb-0"><b>Module</b> • {props.category}</p>
      <p className="p4">{props.description}</p>
    </div>
  );
}

export default withRouter(ModuleCard);