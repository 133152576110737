import classNames from 'classnames';


export default function Button(props) {
  let className = classNames({
    'd-block d-sm-none': !props.noHide ? true : false,
    'button-xs d-flex justify-content-center align-items-center': true,
    'button-primary btn-cool': props.type === "primary" ? true : false,
    'button-outline': props.type === "outline" ? true : false,
    'button-tertiary': !props.type,
    'btn-lg': props.size && props.size === "lg",
    'btn-sm': props.size && props.size === "sm",
    'btn-md': props.size && props.size === "md",
    [props.className]: props.className ? true : false
  });

  return (
    <button onClick={props.onClick} className={className}>
      <img src={props.icon} className="img-responsive btn-icon" alt="action icon" width="12" height="12" />
    </button>
  );
}