import axios from 'axios';
import config from '../../config';
import LocalStorageService from '../../adaptors/storage/localStorageService';

const localStorageService = LocalStorageService.getService();

const getAuthToken = () => {
  return localStorage.token;
}


// console.log(">>>>>CONFIG", config)
// Axios config
const axiosInstance = axios.create({
  baseURL: config.apiGateway.URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const axiosAuthenticate = axios.create({
  baseURL: config.apiGateway.URL,
  headers: {
    'Content-Type': 'application/json',
    // 'Authorization': `Bearer ${getAuthToken()}` 
  }
});

axiosInstance.defaults.timeout = 10000;

//request interceptor to add the auth token header to requests
axiosAuthenticate.interceptors.request.use(
  (config) => {
    // const accessToken = localStorage.getItem("token");
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);



export { axiosInstance, axiosAuthenticate };