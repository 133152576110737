import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import SearchIcon from '../../assets/icons/search.svg';
import Button from '../../components/Button';
import FileList from '../../components/FileList';
import FilterDropdown from '../../components/FilterDropdown/FilterDropdown';
import { DOWNLOADS_SORT_FILTERS } from "../../constants";
import DownloadIcon from '../../assets/icons/download-white.svg';
import ModuleCard from '../ModuleCard/ModuleCard';
import Suggestions from '../Suggestions';
import api from '../../adaptors/xhr/api';
import FilePreviewModal from '../FilePreviewModal';
import { getSearchText, getSearchResults } from '../../pages/Common/ducks/selectors';
import { connect, useDispatch } from 'react-redux';
import { addSearchText, addSearchResults } from '../../pages/Common/ducks/actions'
import { routes } from '../../routes';
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from 'react-bootstrap';


function SiteSearch(props) {
  const history = useHistory();
  const location = useLocation();

  const { placeholder = "Search for modules and resources..." } = props;
  let className = classNames({
    "site-search input-group search-bar": true,
    [props.className]: props.className ? true : false
  });

  const [searchText, setSearchText] = useState();
  const [isSearching, setIsSearching] = useState(false);
  const [keyword, setKeyword] = useState();
  const [results, setResults] = useState();
  const [showResults, setShowResults] = useState();

  const [previewModalShow, setPreviewModalShow] = useState(false);
  const [previewFileObj, setPreviewFileObj] = useState(null);

  const inputRef = useRef(null);

  const previewFile = (fileObj) => {
    setPreviewFileObj(fileObj)
    setPreviewModalShow(true);
  }

  const search = async () => {
    if (searchText?.length) {
      setIsSearching(true);
      setKeyword(searchText);
      const response = await api.Guides.siteSearch(searchText);
      console.log(response.data)
      setResults(response.data);
      setIsSearching(false);
      return response.data;
    }
  }

  const onChangeHandle = async (value) => {
    setShowResults(false);
    setSearchText(value);

    if (searchText && searchText.length > 2) {
      // if (searchText.length % 2 === 0) {
      setTimeout(() => {
        search();
      }, 1000);

      // }
    }
    else {
      setResults(null);
      // props.saveResult(null);
    }
  }

  const handleClickShowResults = () => {
    setShowResults(true);
    if (results) {
      props.saveResult(results);
    }
    if (searchText)
    props.addSearchText(searchText);
    history.push(routes.searchResults);
  }

  const handleClickSearchButton = async () => {
    if (searchText && searchText.length ) {
      search().then(() => {
        if (location.pathname != routes.searchResults) {
          history.push(routes.searchResults);
        }
        props.addSearchText(searchText);
        props.addSearchResults(results);
        inputRef?.current?.blur();
      })
    }
  }

  const handleEnterKey = () => {
    if (searchText?.length) {
      search().then((searchResults) => {
        if (location.pathname !== routes.searchResults) {
          console.log(location.pathname);
          history.push(routes.searchResults);
        }
        props.addSearchText(searchText);
        props.addSearchResults(searchResults);
        console.log("results:", results);
        console.log("searchResults:", searchResults);
        inputRef?.current?.blur();
      })
    }
  }

  useEffect(() => {
    setSearchText("");
  }, [])

  return (
    <div className="search-results-container">
      <div className={className}>
        <div className="container-xl d-flex justify-content-center align-items-center ">
          <div className="holder">
            <input className=""
              ref={inputRef}
              defaultValue={props.searchText || ''}
              value={searchText}
              type="search" placeholder={placeholder} id="example-search-input"
              onChange={(e) => onChangeHandle(e.target.value)}
              onKeyDown={async (event) => {
                if (event.key === "Enter") {
                  handleEnterKey();
                }
              }}
              autocomplete="off"
            />
            <div className="dropdown">
              <Suggestions
                isSearching={isSearching}
                results={results}
                showResults={handleClickShowResults}
                previewFile={previewFile}
              />
            </div>
          </div>

          <Button
            type="primary"
            size="lg"
            className="my-2 ml-3 square-btn"
            text={isSearching ? <Spinner animation="border" size="sm" className="mx-4" /> : "Search"}
            disabled={isSearching}
            minWidth
            onClick={() => {
                inputRef?.current?.focus();
                handleEnterKey();
            }}
            />
        </div>
      </div>

      {/* {showResults ? <div className="container-xl">
      <div className="search-results mx-auto mt-2">
        {props.searchResults &&
        <div className="mt-2 mt-md-0 d-flex justify-content-sm-between align-items-center mb-3">
          <h5>results for ‘{keyword}’</h5>
          <div className="d-none d-md-block" >
            <FilterDropdown
              // onSelect={setFilter}
              className="mr-2"
              sortFilters={DOWNLOADS_SORT_FILTERS}
              activeFilter={props.sortFilter}
            />
            </div>
            <div className="d-block d-md-none w-100" >
            <FilterDropdown
              // onSelect={setFilter}
              className="mr-2"
              sortFilters={DOWNLOADS_SORT_FILTERS}
              activeFilter={props.sortFilter}
            />
          </div>
        </div>

        }
        {props.searchResults?.modules?.length ? results?.modules?.map(module =>
          <ModuleCard
            name={module.name}
            category={module.category}
            description={module.purpose}
            moduleId={module.id}
          />
        ) : null}
        {props.searchResults?.resources?.length ? results.resources.map(file =>
          <FileList
            className=""
            fileId={file.id}
            title={file.title}
            description={file.details}
            fileType={file.file_type}
            fileSize={file.size}
            fileUrl={file.file}
            bookmarkItemsIds={props.bookmarkItemsIds}
            // sectionId={file.sectionId}
            // sessionfileId={file.sessionfileId}
            // addBookmark={props.addBookmark}
            // removeBookmark={props.removeBookmark}
            fileObj={file}
            previewFile={previewFile}
          />
        ) : null}
      </div>
    </div> : null } */}
      <FilePreviewModal
        show={previewModalShow}
        onHide={() => setPreviewModalShow(false)}
        fileObj={previewFileObj}
      />
    </div>
  );
}


const mapStateToProps = state => {
  const searchText = getSearchText(state);
  const searchResults = getSearchResults(state);
  return { searchText, searchResults };
};

export default connect(
  mapStateToProps,
  { addSearchText, addSearchResults }
)(SiteSearch);
