import React, { useEffect } from 'react';
import { connect } from "react-redux";
import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import { addModule } from './ducks/actions';
import TopNavbar from '../../components/TopNavbar';
import PageFooter from '../../components/PageFooter/PageFooter';
import GettingStartedFooter from '../../components/GettingStartedFooter'
import { routes } from '../../routes';
import api from '../../adaptors/xhr/api';
import { addNavigatingTheWebsiteContent } from '../Common/ducks/actions';
import { getNavigatingTheWebsiteContent } from '../Common/ducks/selectors';
import NavbarGettingStarted from '../../components/NavbarGettingStarted';
import { addGettingStartedContent } from '../Common/ducks/actions';
import { getGettingStartedContent } from '../Common/ducks/selectors';


function GettingStartedNavigatingTheWebsite(props) {
  const fetchContent = async () => {
    const navigatingTheWebsiteResponse = await api.Content.navigatingTheWebsite();
    if(navigatingTheWebsiteResponse)  props.addNavigatingTheWebsiteContent(navigatingTheWebsiteResponse.data);
     const gettingStartedResponse = await api.Content.gettingStarted();
    if(gettingStartedResponse.data)  props.addGettingStartedContent(gettingStartedResponse.data);
  }

  useEffect(() => {
    fetchContent();
  }, []);
  console.log(props.navigatingTheWebsiteContent);

  return (
  <>
    <TopNavbar />
    <Navbar />
    <Breadcrumb
      breadcrumbs={["Home", "Getting Started with TRP", "Navigating The Website"]}
      links={["/", routes.gettingStartedNavigatingTheWebsite,  routes.gettingStartedNavigatingTheWebsite]}
      className="getting-started-breadcrumbs"
    />
    <PageHeader heading={ "Getting Started with TRP" } textAlign={"center"}>
      <p className="p2">Short guide to understand module structure</p>
    </PageHeader>
    <NavbarGettingStarted/>
    {/* ..... */}

    <div className='container-xl'>
      <div className='col-12 col-md-10 mx-auto' style={{marginTop: "3rem"}}>
        <h4 style={{marginBottom: '16px'}}>{props.navigatingTheWebsiteContent?.title}</h4>
        <p 
          className="p3" 
          dangerouslySetInnerHTML={{__html: props.navigatingTheWebsiteContent?.body}}
          style={{fontSize: '18px', marginBottom: '64px'}}
        />
           
        {
          props.navigatingTheWebsiteContent?.image ? 
            <img
              className="d-block img-responsive img-fluid"
              src={props.navigatingTheWebsiteContent.image}
              alt="navigating the site"
              // width="598"
              // height="424"
              style={{
                margin: 'auto',
                  marginBottom: "2.5rem",
              }}
            />
          : null
        }
    
     
      {props.navigatingTheWebsiteContent?.sub.map((item) => {
       return (
         <div>
          <h5>{item?.subtitle}</h5>

          <p className="p2" style={{fontSize: "16px", fontWeight: "200", color: "#49545C"}}>
            {item?.subtitle_body}
          </p>

          { 
            item?.image ? 
              <img
                className="d-block img-responsive img-fluid"
                src={item?.image}
                alt="navigating the site"
                // width="598"
                // height="424"
                style={{
                  margin: 'auto',
                  marginBottom: "2.5rem",
                }}
              />
              :
              null
          }
        </div>
       )
      })}
      </div>
    </div>

    <GettingStartedFooter quick_start_guide={props.gettingStartedContent?.quick_start_guide} adaptation_checklist={props.gettingStartedContent?.adaptation_checklist} />

    <PageFooter/>
  </>
  );
}

const mapStateToProps = state => {
  const navigatingTheWebsiteContent = getNavigatingTheWebsiteContent(state);
  const gettingStartedContent = getGettingStartedContent(state);
  return { navigatingTheWebsiteContent, gettingStartedContent };
};

export default connect(
  mapStateToProps,
  { addModule, addNavigatingTheWebsiteContent, addGettingStartedContent }
)(GettingStartedNavigatingTheWebsite);