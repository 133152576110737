import types from "../types";

const initialState = {
    byIds: {},
    allIds: []
};

export function faqentriesReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_ENTRY: {
      const { id, entry, sectionId } = action.payload;
      return {
        ...state,
        allIds: [...state.allIds, id],
        byIds: {
          ...state.byIds,
          [id]: {
            ...entry,
            section: sectionId
          }
        }
      };
    }
    default:
      return state;
  }
}