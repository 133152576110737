import types from "../types";
import { combineReducers } from "redux";

function sessionFilesById(state = {}, action) {
  switch (action.type) {
    case types.ADD_SESSION_FILE: {
      const { payload } = action
      const { moduleId, sectionId, sessionfileId, sessionfile } = payload;
      return {
        ...state,
        [sessionfileId]: {
          ...sessionfile,
          sectionId,
          sessionfileId,
          moduleId: moduleId
        }
      }
    }
      
    default:
      return state
  }
}

function allSessionFiles(state = [], action) {
  switch (action.type) {
    case types.ADD_SESSION_FILE: {
      const { payload } = action;
      const { sessionfileId } = payload;
      return Array.from(new Set(state.concat(sessionfileId)))
    }
    default:
      return state
  }
}

const sessionFilesReducer = combineReducers({
  byId: sessionFilesById,
  allIds: allSessionFiles
})

export default sessionFilesReducer;