import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

function TrainingAccordian(props) {
  return (
    <div className="container-xl">
      <div className="row training-section">
      <p className="col p4">Click on a section to view and download resources.</p>
      <Accordion 
        // defaultActiveKey="0" 
        className="w-100"
      >
        {props.children}
      </Accordion>

      </div>
      
    </div>
  );
}

export default TrainingAccordian;