import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../components/Button';
import Alert from 'react-bootstrap/Alert';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import SittingIllustration from '../../assets/images/sitting.svg';
import FormInput from '../FormInput';
import FormInputLarge from '../FormInputLarge';
import FormSelect from '../FormSelect';
import { reportSchema } from '../../pages/Community/ducks/validations';
import api from '../../adaptors/xhr/api';


function ReportAbuseModal(props) {

  const [ nonFieldErrors, setNonFieldErrors ] = useState([]);

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, resetForm}) => {
    setSubmitting(true);
    const { abuseType, message } = values;
    console.log(abuseType, message )
    try {
      // submit report 
      const response = await api.Forum.reportAbuse(
        props.discussionId, 
        props.targetCommentId, 
        abuseType,
        props.targetType,
        message
      );
      if (response.status === 201) {
        // succesfully reported 
        resetForm();  
        // close modal
        props.onHide();
      }
    }
    catch (error) {
      // read server validation errors
      if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      console.error(error);
    }
    setSubmitting(false);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="report-abuse-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header>
      <Formik
          validationSchema={reportSchema}
          onSubmit={submitHandle}
          initialValues={{
            abuseType: '',
            message: '',
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form className="form" noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <div>
                <h4>Report Inappropriate Content</h4>
                <p className="p3">
                  We’re sorry that something is wrong. 
                  Please choose a reason for reporting this content. 
                </p>
                {nonFieldErrors.length 
                  ? <Alert variant="danger" className="mt-3">
                      {nonFieldErrors?.map((error, index) => `${error}`)}
                    </Alert>
                  : null
                }
              </div>
                <Form.Check 
                  type="radio"
                  label="This content is spam"
                  value="content_spam"
                  name="abuseType"
                  onChange={handleChange}
                  id="abuse-type-1"
                />
                <Form.Check 
                  type="radio"
                  label="This contains private information"
                  value="private_information"
                  name="abuseType"
                  onChange={handleChange}
                  id="abuse-type-2"
                />
                <Form.Check 
                  type="radio"
                  label="It’s misleading or harmful"
                  value="misleading_or_harmful"
                  name="abuseType"
                  onChange={handleChange}
                  id="abuse-type-3"
                />
                <Form.Check 
                  type="radio"
                  label="This breaks TRP community guidelines"
                  value="breaks_TRP_guidelines"
                  name="abuseType"
                  onChange={handleChange}
                  id="abuse-type-4"
                />
                <Form.Check 
                  type="radio"
                  label="Other"
                  value="other"
                  name="abuseType"
                  onChange={handleChange}
                  isInvalid={!!errors.abuseType}
                  feedback={errors.abuseType}
                  id="abuse-type-5"
                />
              
              <FormInput 
                name="message"
                label=""
                placeholder="Add comment (optional)..."
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                // isValid={touched.message && !errors.message}
                isInvalid={touched.message && !!errors.message}
                feedback={errors.message}
                required
                className="mt-4"
              />
            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <div className="col px-0 d-flex align-items-between justify-content-center">
                  <Button size="lg"  onClick={props.onHide} text='Cancel' className="mr-2"/>
                  <Button type="primary" size="lg" text={isSubmitting ? "Reporting..." : "Submit Report"}  submit/>
                </div>
              </div>
              
            
            </Modal.Footer>
        </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default ReportAbuseModal;