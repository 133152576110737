import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Form from 'react-bootstrap/Form';
import api from '../../adaptors/xhr/api';
import TopNavbar from '../../components/TopNavbar';
import FormInput from '../../components/FormInput';
import FormInputLarge from '../../components/FormInputLarge';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import EmailSentSvg from '../../assets/icons/email-sent.svg';
import AuthPageHolder from '../../components/AuthPageHolder';


function EmailVerify(props) {

  const [emailSent, setEmailSent] = useState();
  const [emailFail, setEmailFail] = useState();

  const resendEmail = async () => {
    try {
      // resend verification email
      setEmailFail(false);
      const response = await api.Auth.registerResendEmail(props.email);
      if (response.status === 200) {
        // succesfully sent email
        setEmailSent(true);
        // resetForm();  
      }
    }
    catch (error) {
      // read server validation errors
      // if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      // if (error.response.data?.email) setFieldError('email', error.response.data?.email);
      setEmailFail(true);
      console.error(error);
    }
  }

  return (
  <>
  <AuthPageHolder>
    <div className="auth-page h-100 w-100 mx-auto">
      <div className="content mx-auto">
        {/* <h4 className="text-center">Log in to your account</h4> */}
        <div className="section-middle text-center">
          <Icon icon={EmailSentSvg} width={64} height={64} />
          <h4 className="mb-2">Check your email</h4>
          <p className="p4 mb-5">
            {props.type} sent to {props.email}
          </p>
          <div className="frame text-center">
            <p className="p4">Did not get the email?</p>
            <p className="p4">Check spam folder or <a href="#" onClick={() => props.resendEmail(props.email)}>Click here</a> to receive a new link.</p>
          </div>
        </div>
      </div>
    </div>
  </AuthPageHolder>
  </>
  );
}

const mapStateToProps = state => {
  return { };
};

export default connect(
  mapStateToProps,
  {  }
)(EmailVerify);