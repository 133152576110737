const dev = {
  s3: {
    BUCKET: `${process.env.REACT_APP_DEV_S3_DEPLOY_BUCKET_NAME}`
  },
  apiGateway: {
    URL: `${process.env.REACT_APP_DEV_API_GATEWAY_URL}`
  }
};

const prod = {
  s3: {
    BUCKET: `${process.env.REACT_APP_PROD_S3_DEPLOY_BUCKET_NAME}`
  },
  apiGateway: {
    URL: `${process.env.REACT_APP_PROD_API_GATEWAY_URL}`
  }
};

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev;

export default {
  // Add common config values here
  ...config
};