import * as Yup from 'yup';

export const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
  .min(2, "First name must have at least 2 characters")
  .max(100, "First name can't be longer than 100 characters")
  .required("First name is required"),

  lastName: Yup.string()
  .min(2, "Last name must have at least 2 characters")
  .max(100, "Last name can't be longer than 100 characters")
  .required("Last name is required"),

  email: Yup.string()
  .email("Must be a valid email address")
  .max(100, "Email must be less than 100 characters")
  .required("Email is required"),

  password: Yup.string()
  .required("Password is required")
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  ),
});

export const passwordResetSchema = Yup.object().shape({

  password: Yup.string()
  .required("Password is required")
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  ),

  confirmPassword: Yup.string()
  .required("Password is required")
  .when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Both password need to be the same"
    )
  })

});


export const loginSchema = Yup.object().shape({
  email: Yup.string()
  .email("Must be a valid email address")
  .max(100, "Email must be less than 100 characters")
  .required("Email is required"),

  password: Yup.string()
  .required("Password is required")
});

const re = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;


export const profileSchema = Yup.object().shape({
  firstName: Yup.string()
  .min(2, "First name must have at least 2 characters")
  .max(100, "First name can't be longer than 100 characters")
  .required("First name is required"),

  lastName: Yup.string()
  .min(2, "Last name must have at least 2 characters")
  .max(100, "Last name can't be longer than 100 characters")
  .required("Last name is required"),

  bio: Yup.string()
  .min(2, "Bio must have at least 2 characters")
  .max(150, "Bio can't be longer than 150 characters"),

  linkedinUrl: Yup.string()
  .matches(re, { message: 'URL is not valid', excludeEmptyString: true })
  .nullable(),

  profilePhoto: Yup.mixed(),

  timezone: Yup.string()
  .required("Timezone is required"),
});


export const subscribeSchema = Yup.object().shape({
  email: Yup.string()
  .email("Must be a valid email address")
  .max(100, "Email must be less than 100 characters")
  .required("Email is required"),
});