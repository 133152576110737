import classNames from 'classnames';


export default function Button(props) {
  let className = classNames({
    'separator': true,
    [props.className]: props.className ? true : false
  });

  return (
    <div className={className}>&nbsp;</div>
  );
}