import React, { useEffect, useState } from 'react';
import {
  useHistory
} from "react-router-dom";
import { connect } from "react-redux";
import Navbar from '../../components/Navbar';
import PageContent from '../../components/PageContent';
import { 
  getTrainingModuleCategories, 
  getTrainingModulesByCategory
} from '../../adaptors/xhr/trainingService';
import { addModule, addModuleCategory, setCategoryFilter, setModuleSearch, setSearchFilter } from './ducks/actions';
import { getModules, getModuleCategories, getModuleCategoriesByFilter, getModuleCategoryFilter, getModuleSearch, getModuleSearchText } from './ducks/selectors';
import FilterNav from '../../components/FilterNav/FilterNav';
import ModuleList from '../../components/ModuleList/ModuleList';
import SearchBar from '../../components/SearchBar';
import Button from '../../components/Button';
import Separator from '../../components/Separator';
import QuestionIcon from '../../assets/icons/question.svg';
import TopNavbar from '../../components/TopNavbar';
import PageFooter from '../../components/PageFooter/PageFooter';
import { routes } from '../../routes';
import SearchEmpty from '../../components/SearchEmpty/SearchEmpty';
function Modules(props) {
  const history = useHistory();
  const [ categoryId ] = useState('all');
  var [showCategory, setShowCategory] = useState(false);

  useEffect(() => {
    if(props.moduleCategoriesFiltered.length === 1){
    setShowCategory(true)
  }
  else{
    setShowCategory(false)
  }
  })

  const handleGetStarted = () => {

    history.push(routes.gettingStartedModuleStructure);

  }

  // Module categories using store
  useEffect(() => {
    clearSearch();
    props.setCategoryFilter(null);
    getTrainingModuleCategories()
    .then((allCategory) => {
      allCategory.data.results.map((category) => {
        props.addModuleCategory(category)
        getModulesByCategory(category.id)
      })
    })
    .catch(error => console.log('Error', error));
  }, [])

  // module search
  function searchModules(text){
    props.setSearchFilter(text);
    setModuleSearchText(text);
  }

  function clearSearch() {
    props.setSearchFilter("");
    setModuleSearchText("");
  }

  // function to store modules by category in store
  function getModulesByCategory(categoryId) {
    getTrainingModulesByCategory(categoryId)
    .then((modules) => {
      modules.data.results.map((module) => {
        props.addModule(module, categoryId)
      })
    })
  }


  function selectedCategory(selectedKey) {
    if(selectedKey === "all") {
      props.setCategoryFilter(null)
      return;
    }
    props.setCategoryFilter(parseInt(selectedKey))
  }

  let bottomSection = (
      <>
      <Button 
        type="outline" 
        size="md" 
        text="Getting started with modules"
        icon={QuestionIcon}
        onClick={handleGetStarted} 
      />
      <p className="p4 my-4">
        Need help with modules reach us on <a href="mailto:info@fptraining.org">info@fptraining.org</a>
      </p>
      </>
  )

  const [moduleSearchText, setModuleSearchText] = useState("");

  useEffect(() => {
    setModuleSearchText(props.moduleSearchText);
  }, [props.moduleSearchText]);
  
  return (
  <>
    <TopNavbar />
    <Navbar />
    <PageContent>
      <div className="row mx-lg-5 px-lg-5 mt-2">
        <div className="col-sm-7 training-index-left">
          <h3 className="mb-4 mb-md-5">Training Modules</h3>
            <FilterNav 
              className="d-none d-sm-block" 
              filters={props.moduleCategories} 
              onSelect={(selectedKey) => selectedCategory(selectedKey)}
              activeKey={categoryId}
            />
            <div className="bottom-section d-none d-sm-block">
              <Separator />
              { bottomSection }
            </div>
        </div>

        <div className="col-sm pl-lg-5">
          <SearchBar 
            onChange={searchModules} 
            className="mb-4 mb-md-5" 
            value={moduleSearchText} 
            clearSearch={clearSearch}
          />
          {console.log(props.moduleCategoriesFiltered.length)}
          {props.moduleSearch && props.moduleSearch.length
           ?props.moduleCategoriesFiltered.map((category) => {
            return (
              <ModuleList modules={props.moduleSearch} category={category} showCategory={showCategory}/>
            )
          })
            : <SearchEmpty clearSearch={clearSearch} message="Sorry, we couldn’t find any modules for your keyword." />
          }
            {/* Mobile Screen only */}
          <div className="bottom-section d-block d-sm-none">
            { bottomSection }
          </div>
        </div>

      </div>
    </PageContent>
    <PageFooter/>
  </>
  );
}

const mapStateToProps = state => {
  const modules = getModules(state);
  const moduleSearchText = getModuleSearchText(state)
  const moduleSearch = getModuleSearch(state, moduleSearchText)
  const moduleCategories = getModuleCategories(state);
  const moduleCategoryFilter = getModuleCategoryFilter(state);
  const moduleCategoriesFiltered = getModuleCategoriesByFilter(state, moduleCategoryFilter);
  return { modules,moduleSearch, moduleCategories, moduleCategoryFilter, moduleCategoriesFiltered };
};

export default connect(
  mapStateToProps,
  { addModule, addModuleCategory, setCategoryFilter, setModuleSearch, setSearchFilter }
)(Modules);