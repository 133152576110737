import { combineReducers } from "redux";
import types from "../types";

const initialState = {
    byIds: {},
    allIds: []
};

export function downloadReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_DOWNLOADS: {
      const { id, file, sessionType } = action.payload;
      return {
        ...state,
        allIds: [...state.allIds, id],
        byIds: {
          ...state.byIds,
          [id]: {
            ...file,
            sessionType: sessionType
          }
        }
      };
    }
    default:
      return state;
  }
}