import types from "../types";

const initialState = "";

export const searchFilter = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SEARCH_FILTER: {
      return action.payload.filter;
    }
    default: {
      return state;
    }
  }
};
