import React from 'react';
import Button from '../Button';
import DownloadIcon from '../../assets/icons/download.svg';
import filenameFromUrl from '../../utils/filenameFromUrl';
import { download } from '../../adaptors/xhr/trainingService';

function GettingStartedFooterSection(props) {

  function openFile() {
    props.previewFile();
  }

  function downloadFile() {
    const fileName = filenameFromUrl(props.fileUrl)
    download(props.fileUrl, fileName);
  }

  return (
    <div className="file-alert-getting-started">
      <div className="row">
        <div className="col-lg-9 ">
          <h5>{props.name}</h5>
          <p className="p3 mb-0">{props.body}</p>
        </div>
        <div className="col-lg d-flex justify-content-between align-items-center">
          <Button size="md" text="View" className="mr-2" onClick={openFile} />
          <Button type="outline" size="md" text="Download" onClick={downloadFile} icon={DownloadIcon} />
        </div>
      </div>
      
    </div>
  );
}

export default GettingStartedFooterSection;