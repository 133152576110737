import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  withRouter,
  useLocation,
  useHistory,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { passwordResetSchema } from './ducks/validations';
import Alert from 'react-bootstrap/Alert';
import TopNavbar from '../../components/TopNavbar';
import FormInput from '../../components/FormInput';
import FormInputLarge from '../../components/FormInputLarge';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';
import AuthPageHolder from '../../components/AuthPageHolder';
import EmailVerify from './EmailVerify';
import api from '../../adaptors/xhr/api';
import PasswordResetSuccess from './PasswordResetSuccess';
import { routes } from '../../routes';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ResetPasswordInput(props) {
  const history = useHistory();

  const [ resetComplete, setResetComplete ] = useState();
  const [ nonFieldErrors, setNonFieldErrors ] = useState();
  const [ uid, setUid ] = useState();
  const [ token, setToken ] = useState();

  let query = useQuery();

  useEffect(() => {
    try {
      let resetUrl = query.get("url");
      let page = resetUrl.split('/');
  
      setUid(page[8]);
      setToken(page[9]);
  
      console.log(page);
    }
    catch (error) {
      history.push(routes.login)
    }
    
  }, []);

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, resetForm}) => {
    setSubmitting(true);
    const { password, confirmPassword } = values;
    try {
      const response = await api.Auth.resetPasswordConfirm(password, confirmPassword, uid, token);
      if (response.status === 200) {
        console.log(response.data);
        setResetComplete(true);
        // props.history.push('verify-email')
      }
    }
    catch (error) {
      // read server validation errors
      // if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      console.error(error);
      if (error.response.data.token) setNonFieldErrors("The password reset link is either invalid or expired.")
    }
    setSubmitting(false);
  }

  if(resetComplete) {
    return <PasswordResetSuccess/>
  }

  return (
  <>
  <AuthPageHolder>
    <div className="auth-page h-100 w-100 mx-auto">
      <div className="content mx-auto">
        <h4 className="text-center">Create new password</h4>
        <div className="section-middle">
          <p className="p2">
            Please create a new password for your account.
          </p>
          <Formik
            validationSchema={passwordResetSchema}
            onSubmit={submitHandle}
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form className="form" noValidate onSubmit={handleSubmit}>
                {nonFieldErrors
                ?<Alert variant="danger" className="mt-3">
                  {`${nonFieldErrors}`}
                </Alert>
                : null}
                <FormInput 
                  type="password"
                  name="password"
                  label="Password" 
                  value={values.password} 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder=""
                  // isValid={touched.email && !errors.email}
                  isInvalid={touched.password && !!errors.password}
                  feedback={errors.password}
                  required
                  help="Use at least 8 characters including a number and a uppercase letter."
                />
                 <FormInput 
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password" 
                  value={values.confirmPassword} 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder=""
                  // isValid={touched.email && !errors.email}
                  isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                  feedback={errors.confirmPassword}
                  required
                />
                
                <Button 
                  size="lg" 
                  type="primary" 
                  // text="Send password reset email" 
                  // onClick={() => props.history.push('verify-email')} 
                  text={isSubmitting ? "Please wait..." : "Reset Password"} 
                  submit 
                  disabled={isSubmitting}  
                />
                <div className="frame text-center">
                  <p className="p4"><Link to="login">Back to Log in</Link></p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  </AuthPageHolder>
  </>
  );
}

const mapStateToProps = state => {
  return { };
};

export default connect(
  mapStateToProps,
  {  }
)(withRouter(ResetPasswordInput));