import classNames from 'classnames';


export default function IconButton(props) {
  let className = classNames({
    "icon-btn img-responsive": true,
    [props.className]: props.className ? true : false
  });

  return (
    <img 
      src={props.icon} 
      className={className} 
      alt="action icon" 
      width={props.width} 
      height={props.height} 
      onClick={props.onClick}
      data-bs-toggle={props.toggle}
      data-bs-placement={props.placement}
      title={props.title}
    />
  );
}