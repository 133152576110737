import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import {
  useRouteMatch
} from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap';
import { routes } from '../../routes';

const getUrlLast = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)

function NavbarGettingStarted(props) {

  const [ active, setActive ] = useState();

  let { path, url } = useRouteMatch();
  let page = getUrlLast(url);
  return (
 
    <div className="navbar-tabs navbar-tabs-getting-started ">
       <div className="container-xl">
      <Nav
          activeKey={page || 'navigating-the-website'}
          onSelect={(selectedKey) => setActive(selectedKey)}
          className="navbar-class justify-content-sm-start justify-content-lg-center"
          
        >
          <Nav.Item >
            <LinkContainer to={routes.gettingStartedNavigatingTheWebsite}>
              <Nav.Link eventKey="navigating-the-website">Navigating the Website</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to={routes.gettingStartedModuleStructure}>
              <Nav.Link eventKey="module-structure">Module Structure</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to={routes.gettingStartedFaq}>
              <Nav.Link eventKey="faq">Frequently Asked Questions</Nav.Link>
            </LinkContainer>
          </Nav.Item>
        </Nav>
      </div>
  </div>
  );
}

export default NavbarGettingStarted;