import React,{useState} from 'react';

const ToggleSwitch = ({ id, isOn, handleChange}) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleChange}
        className="switch-checkbox"
        id={id}
        type="checkbox"
      />
      <label
        style={{ background: isOn && '#97e19f' }}
        className="switch-label"
        htmlFor={id}
      >
        <span className={`switch-button`} />
      </label>
    </>
  );
};

export default ToggleSwitch;