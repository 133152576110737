const ADD_MODULE = "training/duck/ADD_MODULES";
const ADD_MODULE_CATEGORY = "training/duck/ADD_MODULE_CATEGORY";
const ADD_MODULE_LEARNING_OBJECTIVES = "training/duck/ADD_MODULE_LEARNING_OBJECTIVES"

const ADD_SESSION_FILE = "training/duck/ADD_SESSION_FILE";

const SET_MODULE_CATEGORY_FILTER = "training/duck/SET_MODULE_CATEGORY_FILTER";
const SET_LEARNING_OBJECTIVES_IN_MODULE = "training/duck/SET_LEARNING_OBJECTIVES_IN_MODULE"
const SET_MODULE_SEARCH = "training/duck/SET_MODULE_SEARCH";

const ADD_DOWNLOADS = "training/downloads/duck/ADD_DOWNLOADS";
const SET_SORT_FILTER = "training/downloads/duck/SET_SORT_FILTER";
const SET_SEARCH_FILTER = "training/downloads/duck/SET_SEARCH_FILTER";

const ADD_BOOKMARK_FOLDER = "training/duck/ADD_BOOKMARK_FOLDER";
const ADD_DEFAULT_BOOKMARK_FOLDER = "training/duck/ADD_DEFAULT_BOOKMARK_FOLDER";
const RENAME_BOOKMARK_FOLDER = "training/duck/RENAME_BOOKMARK_FOLDER";
const DELETE_BOOKMARK_FOLDER = "training/duck/DELETE_BOOKMARK_FOLDER";
const ADD_BOOKMARK_ITEM = "training/duck/ADD_BOOKMARK_ITEM";
const REMOVE_BOOKMARK_ITEM = "training/duck/REMOVE_BOOKMARK_ITEM";
const SET_BOOKMARK_CONTEXT = "training/duck/SET_BOOKMARK_CONTEXT";
const SET_BOOKMARK_FOLDER_FILTER = "training/duck/SET_BOOKMARK_FOLDER_FILTER";

const ADD_MODULE_DISCUSSION_ID = "training/duck/ADD_MODULE_DISCUSSION_ID"
const ADD_GUIDE = "training/duck/ADD_GUIDE";
const ADD_ADDITIONAL_TRAINING_TOOL = "training/duck/ADD_ADDITIONAL_TRAINING_TOOL";

const ADD_FRENCH_SECTION = "training/duck/ADD_FRENCH_SECTION";
const ADD_FRENCH_CONTENT = "training/duck/ADD_FRENCH_CONTENT";
const ADD_FRENCH_VIDEO = "training/duck/ADD_FRENCH_VIDEO";

const ADD_VIDEO = "training/duck/ADD_VIDEO";
const ADD_SPANISH_CONTENT = "training/duck/ADD_SPANISH_CONTENT";
const ADD_SPANISH_SECTION = "training/duck/ADD_SPANISH_SECTION";
const ADD_SPANISH_VIDEO = "training/duck/ADD_SPANISH_VIDEO";

export default {
  ADD_MODULE,
  ADD_SESSION_FILE,
  ADD_DOWNLOADS,
  SET_SORT_FILTER,
  SET_SEARCH_FILTER,
  ADD_MODULE_CATEGORY,
  ADD_MODULE_LEARNING_OBJECTIVES,
  SET_LEARNING_OBJECTIVES_IN_MODULE,
  SET_MODULE_CATEGORY_FILTER,
  SET_MODULE_SEARCH,
  ADD_BOOKMARK_FOLDER,
  ADD_DEFAULT_BOOKMARK_FOLDER,
  RENAME_BOOKMARK_FOLDER,
  DELETE_BOOKMARK_FOLDER,
  SET_BOOKMARK_FOLDER_FILTER,
  ADD_BOOKMARK_ITEM, 
  REMOVE_BOOKMARK_ITEM,
  SET_BOOKMARK_CONTEXT,
  ADD_MODULE_DISCUSSION_ID,
  ADD_GUIDE,
  ADD_ADDITIONAL_TRAINING_TOOL,
  ADD_FRENCH_SECTION,
  ADD_FRENCH_CONTENT,
  ADD_FRENCH_VIDEO,
  ADD_VIDEO,
  ADD_SPANISH_CONTENT,
  ADD_SPANISH_SECTION,
  ADD_SPANISH_VIDEO,
};