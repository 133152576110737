
/** FAQ Sections */
export const getSectionsState = store => store.faq.sections;

export const getSectionsList = store =>
  getSectionsState(store) ? getSectionsState(store).allIds : [];

export const getSectionsById = (store, id) =>
  getSectionsState(store) ? { ...getSectionsState(store).byIds[id], id } : {};

export const getSections = store =>
  getSectionsList(store).map(id => getSectionsById(store, id));


/** FAQ Entries */
export const getEntriesState = store => store.faq.entries;

export const getEntriesList = store =>
  getEntriesState(store) ? getEntriesState(store).allIds : [];

export const getEntriesById = (store, id) =>
  getEntriesState(store) ? { ...getEntriesState(store).byIds[id], id } : {};

export const getEntries = store =>
  getEntriesList(store).map(id => getEntriesById(store, id));