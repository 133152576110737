import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Form from 'react-bootstrap/Form';
import api from '../../adaptors/xhr/api';
import TopNavbar from '../../components/TopNavbar';
import FormInput from '../../components/FormInput';
import FormInputLarge from '../../components/FormInputLarge';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import DoneSvg from '../../assets/icons/done.svg';
import AuthPageHolder from '../../components/AuthPageHolder';
import { routes } from '../../routes';


function PasswordResetSuccess(props) {

  return (
  <>
  <AuthPageHolder>
    <div className="auth-page h-100 w-100 mx-auto">
      <div className="content mx-auto">
        <div className="section-middle text-center">
          <Icon icon={DoneSvg} width={64} height={64} />
          <h4 className="mb-2">Password Updated</h4>
          <p className="p4 mb-5">
            Your password has been updated!
          </p>
          <div className="frame text-center">
            <p className="p4"><a href={routes.login}>Login</a></p>
          </div>
        </div>
      </div>
    </div>
  </AuthPageHolder>
  </>
  );
}

const mapStateToProps = state => {
  return { };
};

export default connect(
  mapStateToProps,
  {  }
)(PasswordResetSuccess);