import { axiosInstance, axiosAuthenticate } from './xhrConfig';

const Bookmarks = {

  folders: () =>
    axiosAuthenticate.get(`/api/training/bookmarks/`),
  // discussions: () =>
  //   axiosInstance.get(`/api/community/discussion/`),
  createFolder: (folder_name) =>
    axiosAuthenticate.post(`/api/training/bookmarks/`, { folder_name }),
  renameFolder: (folderId, folder_name) =>
    axiosAuthenticate.put(`/api/training/bookmarks/${folderId}/`, { folder_name }),
  deleteFolder: (folderId) =>
    axiosAuthenticate.delete(`/api/training/bookmarks/${folderId}/`),
  // postById: (id) =>
  //   axiosAuthenticate.get(`/api/community/discussion/${id}/`),
  // comments: (discussionId) =>
  //   axiosAuthenticate.get(`/api/community/discussion/${discussionId}/comments/`),
  bookmarkItem: (folderId, type, itemId) =>
    axiosAuthenticate.post(`/api/training/bookmarks/${folderId}/add/`, {type: type, id: itemId}),
  removebookmarkedItem: (folderId, type, itemId) =>
    axiosAuthenticate.post(`/api/training/bookmarks/${folderId}/delete/`, {type: type, id: itemId}),
    
};

export default Bookmarks;