import types from "../types";
import { DOWNLOADS_SORT_FILTERS } from "../../../../constants";

const initialState = DOWNLOADS_SORT_FILTERS.DEFAULT;

export const sortFilter = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SORT_FILTER: {
      return action.payload.filter;
    }
    default: {
      return state;
    }
  }
};
