import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import timeagoFromDate from '../../utils/timeagoFromDate';
import UserThumb from '../UserThumb';
import Button from '../Button';
import { routes } from '../../routes';


function Comments(props) {

  const [ activeKey, setActiveKey ] = useState('weekly');

  const { comments, count } = props;

  let { path, url } = useRouteMatch();

  let className = classNames({
    'comments-list': true,
    [props.className]: props.className ? true : false
  });

  useEffect(() => {
    console.log('comments', comments)
  }, [props])

  return (
    <div className={className}>
      <h5>{count === 0 ? `No` : count} {count === 1 ? `Comment` : `Comments`}</h5>
      {comments?.reverse().map(comment => {
        return (
        <div className="comment d-flex justify-content-start align-items-start my-3">
          <Link to={`/community/user/${comment?.author?.id}`}>
            <UserThumb 
            className="mr-4" 
            thumbnail={comment.author.profile_picture}
            firstName={comment?.author?.first_name} 
            lastName={comment?.author?.last_name} 
          />
          </Link>
          

          <div className="">
            <div>
              <Link to={`/community/user/${comment?.author?.id}`}>
                <span className="author">{comment.author.first_name} {comment.author.last_name}</span>
              </Link>
              
              <span className="timestamp-info"> {timeagoFromDate(comment.updatedAt)}</span>
            </div>
            <p className="p3 mt-2"  dangerouslySetInnerHTML={{ __html: comment.text }} />

            <div className="comment-actions">
              {/* <span>{comment.likes_count} Likes</span> */}
              {/* <span> • </span> */}
              <span><Button onClick={() => props.reply(comment.id)} text="Reply" minWidth /></span>
              <span> • </span>
              <span><Button onClick={() => props.reportAbuse(comment.id)} text="Report" minWidth /></span>
            </div>
          </div>
        </div>
        )
      })}
    </div>
  );
}

export default Comments;