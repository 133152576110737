import types from "../types";

const initialState = {
  fileId: null,
  sectionType: null,
  fileObj: null,
};

export const bookmarkContextReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_BOOKMARK_CONTEXT: {
      const { payload } = action;
      const { sectionType, fileId, fileObj } = payload;
      return {
        sectionType, 
        fileId,
        fileObj
      }
    }
    default: {
      return state;
    }
  }
};
