import types from "../types";
import { combineReducers } from "redux";

const initialState = {
  byId: {},
  byUserId: {},
  allIds: [],
}

function activitiesReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_ACTIVITY: {
      const { payload } = action
      const { userId, activityId, activityObj } = payload
      return {
        ...state,
        allIds: Array.from(new Set([...state.allIds, activityId])),
        byId: {
          ...state.byId,
          [activityId]: {
            ...state.byId[activityId],
            ...activityObj
          }
        },
        byUserId: {
          ...state.byUserId,
          [userId]: state.byUserId[userId]?.length ? Array.from(new Set(state.byUserId[userId]?.concat(activityId))) : [activityId],
        }
      }
    }
    case types.UPDATE_ACTIVITY: {
      const { payload } = action;
      const { activityId, activityObj } = payload
      return {
        ...state,
        byId: {
          ...state.byId,
          [activityId]: {
            ...state.byId[activityId],
            ...activityObj
          }
        },
      }
    }
    case types.DELETE_ACTIVITY: {
      const { payload } = action;
      const { userId, activityId } = payload;
      const activitiesById = {...state.byId};
      const activitiesByUserId = {...state.byUserId};
      const activitiesAllIds = [...state.allIds];
      delete activitiesById[activityId];
      activitiesByUserId[userId].splice(activitiesByUserId[userId].indexOf(activityId), 1);
      activitiesAllIds.splice(activitiesAllIds.indexOf(activityId), 1);
      return {
        byId: {...activitiesById},
        byUserId: {...activitiesByUserId},
        allIds: Array.from(new Set([...activitiesAllIds])),
      };
    }
    case types.CLEAR_ACTIVITY: {
      return {
        ...initialState,
      };
    }
    default:
      return state
  }
}


// function activitiesById(state = {}, action) {
//   switch (action.type) {
//     case types.ADD_ACTIVITY: {
//       const { payload } = action
//       const { activityId, activityObj } = payload
//       return {
//         ...state,
//         [activityId]: activityObj
//       }
//     }
      
//     default:
//       return state
//   }
// }

// function activitiesByUserId(state = {}, action) {
//   switch (action.type) {
//     case types.ADD_ACTIVITY: {
//       const { payload } = action
//       const { userId, activityId } = payload
//       return {
//         ...state,
//         [userId]: state[userId]?.length ? Array.from(new Set(state[userId]?.concat(activityId))) : [activityId],
//       }
//     }
      
//     default:
//       return state
//   }
// }

// function allactivities(state = [], action) {
//   switch (action.type) {
//     case types.ADD_ACTIVITY: {
//       const { payload } = action
//       const { activityId  } = payload
//       if(state.indexOf(activityId) === -1){
//         return state.concat(activityId)
//       }
//     }
//     default:
//       return state
//   }
// }

// const activitiesReducer = combineReducers({
//   byId: activitiesById,
//   allIds: allactivities,
//   byUserId: activitiesByUserId,
//   })

export default activitiesReducer;