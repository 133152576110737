import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  useRouteMatch
} from "react-router-dom";
import { connect } from "react-redux";
import api from '../../adaptors/xhr/api';
import Navbar from '../../components/Navbar';
import TopNavbar from '../../components/TopNavbar';
import PageContent from '../../components/PageContent';
import Icon from '../../components/Icon';
import ResourceSvg from '../../assets/icons/resources.svg';
import VisitorsSvg from '../../assets/icons/visitors.svg';
import CommunitySvg from '../../assets/icons/community.svg';
import CountriesSvg from '../../assets/icons/countries.svg';
// import ChatSvg from '../../assets/icons/chat.svg';
import PageFooter from '../../components/PageFooter/PageFooter';
import PartnerLogos from '../../components/PartnerLogos';
import { addAboutContent } from '../Common/ducks/actions';
import { getAboutContent } from '../Common/ducks/selectors';

function About(props) {

  const fetchContent = async () => {
    const response = await api.Content.about();
    console.log(response.data)
    if(response.data)  props.addAboutContent(response.data);
  
  }

  useEffect(() => {
    fetchContent();
  }, []);

  return (
    <>
      <TopNavbar/>
      <Navbar />
      <PageContent>
        <div className="about-top-section">
          <h3 className="d1 mb-3">{props.aboutContent?.title}
          </h3>
          <p className="p3" dangerouslySetInnerHTML={{__html: props.aboutContent?.description}}></p>
        </div>
      </PageContent>

      <div className="bg-highlight">
        <div className="container-xl">
          <div className="about-highlights  row">
            <div className="col-xs-12 col-sm">
              <Icon icon={ResourceSvg} width={23} height={27} />
              <h3>{props.aboutContent?.number_1}</h3>
              <h5>{props.aboutContent?.title_1}</h5>
              <p className="p3">{props.aboutContent?.short_description_1}</p>
            </div>

            <div className="col-xs-12 col-sm">
              <Icon icon={VisitorsSvg} width={23} height={27} />
              <h3>{props.aboutContent?.number_2}</h3>
              <h5>{props.aboutContent?.title_2}</h5>
              <p className="p3">{props.aboutContent?.short_description_2}</p>
            </div>

            <div className="col-xs-12 col-sm">
              <Icon icon={CountriesSvg} width={28} height={28} />
              <h3>{props.aboutContent?.number_3}</h3>
              <h5>{props.aboutContent?.title_3}</h5>
              <p className="p3">{props.aboutContent?.short_description_3}</p>
            </div>

            <div className="col-xs-12 col-sm">
              <Icon icon={CommunitySvg} width={27} height={24} />
              <h3>{props.aboutContent?.number_4}</h3>
              <h5>{props.aboutContent?.title_4}</h5>
              <p className="p3">{props.aboutContent?.short_description_4}</p>
            </div>
        </div>
        </div>
      </div>

      <PageContent className="about-page">
        <div className="row">
          <div className="col-xs-12 col-sm-3">
            <h4 className="h4-regular">Background</h4>
          </div>
          <div className="col-xs-12 col">
            <p className="p3" dangerouslySetInnerHTML={{__html: props.aboutContent?.background}}/>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-3">
            <h4 className="h4-regular-small">Development of the TRP has been led by</h4>
          </div>
          <PartnerLogos/>
        </div>

        <div className="row contribution">
          <div className="col-xs-12 col-sm-3 label">
            <h4 className="h4-regular-small">
              Contribution from technical and training experts 
              representing multiple agencies and organizations
            </h4>
          </div>
          <div className="col-xs-12 col values">
            <div className="row p-0"> 
              <div className="col-xs-12 col-6">
                <p className="p3"><a href="https://www.cdc.gov/">Centers for Disease Control</a></p>
                <p className="p3"><a href="https://www.chemonics.com/">Chemonics</a></p>
                <p className="p3"><a href="https://www.engenderhealth.org/">Engenderhealth</a></p>
                <p className="p3"><a href="http://www.fhi360.org/en/index.htm">FHI 360</a></p>
                <p className="p3"><a href="http://www.ibpinitiative.org/">IBP Initiative</a></p>
                <p className="p3"><a href="http://www.irh.org/">Institute for Reproductive Health</a></p>
                <p className="p3"><a href="http://www.ippf.org/">International Planned Parenthood Federation</a></p>
              </div>

              <div className="col-xs-12 col-6">
                <p className="p3"><a href="http://www.intrahealth.org/">IntraHealth International</a></p>
                <p className="p3"><a href="http://www.jhpiego.org/">Jhpiego</a></p>
                <p className="p3"><a href="https://www.jhu.edu/">The Johns Hopkins University</a></p>
                <p className="p3"><a href="http://www.msh.org/">Management Sciences for Health</a></p>
                <p className="p3"><a href="https://www.mariestopes.org/">Marie Stopes International</a></p>
                <p className="p3"><a href="https://www.pathfinder.org/">Pathfinder</a></p>
                <p className="p3"><a href="http://www.popcouncil.org/">Population Council</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-3">
            <h4 className="h4-regular">Contact Us</h4>
          </div>
          <div className="col-xs-12 col">
            <p className="p3">
            For trainers looking for help or to provide suggestions, feedback contact us at <a href="mailto:info@fptraining.org">info@fptraining.org</a>
            </p>
          </div>
        </div>
      </PageContent>

      <PageFooter/>
      
    </>
    );
  }

  const mapStateToProps = state => {
    const aboutContent = getAboutContent(state);
    return { aboutContent };
  };
  

  export default connect(
    mapStateToProps,
    { addAboutContent }
  )(About);