import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../components/Button';
import Nav from 'react-bootstrap/Nav';
import BookmarkFolders from '../../components/BookmarkFolders';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import FormInput from '../FormInput';
import * as Yup from 'yup';
import classNames from 'classnames';
import FolderIcon from '../../assets/icons/folder.svg';
import api from '../../adaptors/xhr/api';

const bookmarkFolderSchema = Yup.object().shape({
  name: Yup.string()
  .min(1, "Folder name must have at least 1 characters")
  .max(20, "Folder name can't be longer than 20 characters")
  .required("Folder name is required"),
});

function RenameBookmarkFolder(props) {
  const {folders = [
    {id: 0, "name": "Saved Resources"},
    {id: 2, "name": "Others"}
  ]} = props;

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, setFieldError, setStatus, resetForm}) => {
    setSubmitting(true);
    const { name } = values;
    try {
      // create folder
      const response = await api.Bookmarks.createFolder(name);
      if (response.status === 201) {
        // succesfully created folder
        // setEmailSent(values.email);
        props.onCreatedFolder(response.data);
        resetForm();  
      }
    }
    catch (error) {
      // read server validation errors
      // if (error.response.data?.non_field_errors)
      setFieldError('name', 'Unable to create folder');
    }
    setSubmitting(false);
  }

  const handleNameChange = (e, { value }, setFieldValue) => {
    setFieldValue('name', value);
    // ... the rest of your implementation
    props.onChange(value)
  }

  return (
    <>
    <Formik
          validationSchema={bookmarkFolderSchema}
          onSubmit={submitHandle}
          initialValues={{
            name: props.value,
          }}
          onChange={props.onChange}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            status
          }) => (
            <Form className="form" noValidate onSubmit={handleSubmit}>
              <div className="input-group rename-bookmark-folder">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <img src={FolderIcon} className="img-responsive" alt="search icon" />
                  </span>
                </div>
                <Form.Control 
                  value={props.value}
                  type="text" 
                  placeholder="Type folder name..." 
                  id="example-search-input"
                  name="name"
                  defaultValue={values.name}
                  onChange={props.onChange}
                  onBlur={handleBlur}
                  // isValid={touched.email && !errors.email}
                  isInvalid={touched.name && !!errors.name}
                  feedback={errors.name}
                  required
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </div> 
            </Form>
        )}
    </Formik>
    </>
  );
}

export default RenameBookmarkFolder;