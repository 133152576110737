import React from 'react';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';

function ActivityFilterNav(props) {

  const { filters } = props;

  let { path, url } = useRouteMatch();

  let className = classNames({
    'discussion-filter-nav filter-nav': true,
    [props.className]: props.className ? true : false
  });

  return (

    <div className={className}>
      <Nav 
        defaultActiveKey={props.activeKey || "all"}
        className="flex-column"
        onSelect={(selectedKey) => props.onSelect(selectedKey)}>
           <Nav.Link key={`filter-all`} eventKey='all'><h6>All Bookmarks</h6></Nav.Link>
          {filters?.map(f => <Nav.Link key={`filter-${f.id}`} eventKey={f.id}><h6>{f.name}</h6></Nav.Link> )}
      </Nav>
    </div>
  );
}

export default ActivityFilterNav;