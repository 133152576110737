import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import TopNavbar from '../../components/TopNavbar';
import FormInput from '../../components/FormInput';
import FormInputLarge from '../../components/FormInputLarge';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';
import AuthPageHolder from '../../components/AuthPageHolder';
import api from '../../adaptors/xhr/api';
import axios from 'axios';
import EmailVerify from './EmailVerify';
import { registrationSchema } from './ducks/validations';


function Signup(props) {

  const [ emailSent, setEmailSent ] = useState();
  const [ nonFieldErrors, setNonFieldErrors ] = useState([]);

  const resendEmail = async (email) => {
    try {
      // resend verification email
      // setEmailFail(false);
      const response = await api.Auth.registerResendEmail(email);
      if (response.status === 200) {
        // succesfully sent email
        // setEmailSent(true);
      }
    }
    catch (error) {
      // setEmailFail(true);
      console.error(error);
    }
  }

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, setFieldError, resetForm}) => {
    setSubmitting(true);
    const { firstName, lastName, email, password } = values;
    try {
      // create user
      const response = await api.Auth.register(firstName, lastName, email, password);
      if (response.status === 201) {
        // succesfully created user
        setEmailSent(values.email);
        resetForm();  
      }
    }
    catch (error) {
      // read server validation errors
      if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      if (error.response.data?.email) setFieldError('email', error.response.data?.email);
      console.error(error);
    }
    setSubmitting(false);
  }

  if(emailSent) {
    return <EmailVerify email={emailSent} type="Verification email" resendEmail={resendEmail} />
  }
  

  return (
  <>
  <AuthPageHolder>
    <div className="auth-page h-100 w-100 mx-auto">
      <div className="content mx-auto">
        <h4 className="text-center">Create your TRP account</h4>
        <div className="section-middle">
        {nonFieldErrors.length ? 
        <Alert variant="danger">
          {nonFieldErrors?.map((error, index) => `${error}`)}
        </Alert>
        : null
        }
      
        <Formik
          validationSchema={registrationSchema}
          onSubmit={submitHandle}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            password: '',
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <Form className="form" noValidate onSubmit={handleSubmit}>
              <FormInput
                type="text"
                label="First Name"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                isValid={touched.firstName && !errors.firstName}
                isInvalid={touched.firstName && !!errors.firstName}
                feedback={errors.firstName}
                required
              />
              <FormInput 
                type="text"
                label="Last Name"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                isValid={touched.lastName && !errors.lastName}
                isInvalid={touched.lastName && !!errors.lastName}
                feedback={errors.lastName}
                required
              />
              <FormInput 
                type="email"
                label="Email ID"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                isValid={touched.email && !errors.email}
                isInvalid={touched.email && !!errors.email}
                feedback={errors.email}
                required
              />
              <FormInput 
                type="password"
                label="Create Password"
                help="Use at least 8 characters including a number and a uppercase letter."
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder=""
                isValid={touched.password && !errors.password}
                isInvalid={touched.password && !!errors.password}
                feedback={errors.password}
                required
              />
              <Button 
                size="lg" 
                type="primary" 
                text={isSubmitting ? "Creating account..." : "Create account"} 
                submit 
                disabled={isSubmitting} 
              />
            </Form>
          )}
          </Formik>
          <p className="p4 my-4 text-center">By creating an account, you agree to the TRP’s <a href="#">Terms of Service</a>.</p>
          <div className="frame text-center">
            <p className="p4">Already a user? <Link to="login">Log in</Link></p>
          </div>
        </div>
      </div>
    </div>
  </AuthPageHolder>
  </>
  );
}

const mapStateToProps = state => {
  return { };
};

export default connect(
  mapStateToProps,
  {  }
)(Signup);