import types from "./types";

export const addPost = (post) => ({
  type: types.ADD_POST,
  payload: {
    id: post.id,
    post: post,
  }
});

export const clearPost = () => ({
  type: types.CLEAR_POST,
})

export const addComment = (postId, comment) => ({
  type: types.ADD_COMMENT,
  payload: {
    postId: postId,
    commentId: comment.id,
    comment: comment,
  }
});

export const addTopic = (topic) => ({
  type: types.ADD_TOPIC,
  payload: {
    topicId: topic.id,
    name: topic.name,
  }
});

export const setTopicFilter = topicId => ({
  type: types.SET_TOPIC_FILTER,
  payload: {
    topic: topicId
  }
});

export const setSortFilter = filter => ({
  type: types.SET_SORT_FILTER,
  payload: {
    filter: filter
  }
});

export const setSearchFilter = (searchText) => ({
  type: types.SET_DISCUSSION_SEARCH_FILTER,
  payload: {
    searchText
  }
});

// users
export const addUser = (userObj) => ({
  type: types.ADD_USER,
  payload: {
    userId: userObj.pk,
    userObj: userObj,
  }
});

// activities
export const addActivity = (userId, activityObj) => ({
  type: types.ADD_ACTIVITY,
  payload: {
    userId: userId,
    activityId: activityObj.id,
    activityObj: activityObj,
  }
});

export const updateActivity = (activityObj) => ({
  type: types.UPDATE_ACTIVITY,
  payload: {
    activityId: activityObj.id,
    activityObj: activityObj,
  }
});

export const deleteActivity = (userId, activityId) => ({
  type: types.DELETE_ACTIVITY,
  payload: {
    userId: userId,
    activityId: activityId,
  }
});

export const clearActivity = () => ({
  type: types.CLEAR_ACTIVITY,
});

// to add Contributors
export const addContributor = (contributor) => ({
  type: types.ADD_CONTRIBUTOR,
  payload: {
    id: contributor.id,
    data: contributor
  }
});

// to add related discussion for a Post
export const addPostRelatedDiscussion = (mainPostId, relatedPostId ) => ({
  type: types.ADD_POST_RELATED_DISCUSSION,
  payload: {
    mainPostId: mainPostId,
    relatedPostId: relatedPostId,
  }
});

// export const addSectionEntryRef = (sectionId, entryId) => ({
//   type: types.ADD_SECTION_ENTRY_REF,
//   payload: {
//     id: sectionId,
//     entryId: entryId,
//   }
// });

// export const setSortFilter = filter => ({ type: types.SET_SORT_FILTER, payload: { filter } });

// export const setSearchFilter = filter => ({ type: types.SET_SEARCH_FILTER, payload: { filter } });
