import React, { useEffect, useState }from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Button from '../../components/Button';
import Nav from 'react-bootstrap/Nav';
import BookmarkFolders from '../../components/BookmarkFolders';
import AddBookmarkFolder from '../../components/AddBookmarkFolder';
import Alert from 'react-bootstrap/Alert';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import api from '../../adaptors/xhr/api';

function BSModal(props) {
  const [ folderSelected, setFolderSelected ] = useState();
  const [ errors, setErrors ] = useState();
  const {folders = [
    {id: 0, "name": "Saved Resources"},
    {id: 2, "name": "Others"}
  ]} = props;

  const selectedFolderHandler = folderId => {
    console.log(folderId);
    setFolderSelected(folderId);
    setErrors(null);
  }

  const createdFolderHandle = (newFolder) => {
    props.onCreatedFolder(newFolder);
    console.log(newFolder);
    // Selcted created folder
    selectedFolderHandler(newFolder.id)
  }

  const submitBookmark = async () => {
    // let defaultFolder = props.folders.find(folder => folder.is_default == true);
    let folderId = folderSelected;
    if (!folderSelected) {
      if (props.defaultFolderId != undefined) {
        setFolderSelected(props.defaultFolderId);
        folderId = props.defaultFolderId;
      }
      else {
        setErrors(errorState => "Unable to save bookmark. Please try again");
        return;
      }
    }
    const response = await api.Bookmarks.bookmarkItem(
        folderId,
        props.bookmarkContext.sectionType,
        props.bookmarkContext.fileId
      )
    if (response.status === 200) {
      props.onHide();
      if (props.type==="module") {
        // Module bookmark
        props.bookmarkSuccess(folderId, `module-${props.bookmarkContext.fileObj.id}`, props.bookmarkContext.fileObj, "modules");
      }
      else {
        props.bookmarkSuccess(folderId, props.bookmarkContext.fileObj.sessionfileId, props.bookmarkContext.fileObj, props.bookmarkContext.fileObj.sectionId);
      }
    } 
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="bookmark-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h6>Save to bookmarks</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {props.folders.length ?
        <>
        <BookmarkFolders 
            folders={props.folders.filter(folder => folder.is_default != true)}
            selectedFolder={selectedFolderHandler}
            className="mb-2" 
            selectedKey={folderSelected}
          /> 

        <AddBookmarkFolder onCreatedFolder={createdFolderHandle} />
        <p className="p3 mt-4">
          To remove or manage folders go to profile
        </p>
        {errors
        ?<Alert variant="danger" className="mt-3">
          {`${errors}`}
        </Alert>
        : null}
        </>
        : <Spinner animation="border" variant="primary" />}
      </Modal.Body>
      {props.folders.length ? 
      <Modal.Footer>
        <div className="row">
          <div className="col px-0 d-flex align-items-between justify-content-center">
            <Button size="lg"  onClick={props.onHide} text='Cancel' className="mr-2"/>
            <Button type="primary" size="lg"  onClick={submitBookmark} text='Save Bookmark'/>
          </div>
        </div>
      </Modal.Footer>
      : null}
    </Modal>
  );
}

export default BSModal;