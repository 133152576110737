import React from 'react';
import {
  Link,
} from "react-router-dom";
import Button from '../Button';
import ButtonXs from '../ButtonXs';
import BookmarkButton from '../BookmarkButton';
import BookmarkIcon from '../../assets/icons/bookmark.svg';
import BookmarkIconFill from '../../assets/icons/bookmark-fill.svg';
import DownloadIcon from '../../assets/icons/download.svg';
import filenameFromUrl from '../../utils/filenameFromUrl';
import { download } from '../../adaptors/xhr/trainingService';
import filesize from 'filesize';
// import BookmarkModal from '../../components/BookmarkModal';
function DownloadFileList(props) {

  // const [modalShow, setModalShow] = useState(false);

  let fileType = "";
  switch(props.fileType) {
    case "Word Document":
      fileType = "Word Doc";
      break;
    case "PDF Document":
      fileType = "PDF";
      break;
    case "Presentation Slide":
      fileType = "PPT"
      break;
    default:
      fileType = "Document"
      break
  }

  const fileUrl = props.fileObj.preview_file? props.fileObj.preview_file: props.fileUrl;
  const file_type = props.fileObj.preview_file? "PDF Document": props.fileType;

  function openFile() {
    console.log('openFile');
    // window.open(props.fileUrl, "_blank");
    // props.previewFile({file: fileUrl, size: props.fileSize, title: props.fileObj.title, file_type: file_type, sourceFile: props.fileObj.file, sourceFileType: props.fileObj.file_type})
    if (props.fileObj?.path_type == "URL" ) 
    {
      window.open(props.fileObj?.url, "_blank");
    }
    else {
      props.previewFile(props.fileObj);
    }
  }

  function downloadFile() {
    const fileName = filenameFromUrl(props.fileUrl)
    download(props.fileUrl, fileName);
  }

  return (
    <>
        <li href="#" className="list-group-item list-group-item-action" aria-current="true">
          <div className="d-flex w-100 justify-content-between align-items-center">

            <div className="row d-flex w-100 justify-content-between align-items-center">
              <div className="col-xs-12 col-11 col-md-8">
                <p className="p3 mb-0"><a href="#" onClick={openFile}>{props.title}</a></p>
              </div>

              {/* Only for XS */}
              <div className="col-xs-12 col-11 col-md d-block d-sm-none">
                <small className="p3">{fileType}</small>
                <small className="p3"> • </small>
                <small className="p3">{`${props.fileSize && !isNaN(props.fileSize) ? filesize(props.fileSize) : ''}`}</small>
              </div>

              <div className="col-xs-6 col-md-2 d-none d-sm-block">
                <small className="p3">{props.fileObj?.path_type == "FILE" ? fileType : 'Reference Link'}</small>
              </div>
              <div className="col-xs-6 col-md-1 d-none d-sm-block">
                <small className="p3">{`${props.fileSize && !isNaN(props.fileSize) ? filesize(props.fileSize) : ''}`}</small>
              </div>
            </div>

            <div className="col-1 col-md-1 d-flex justify-content-end align-items-center">
              {props.noBookmark ? 
              null
              :<BookmarkButton 
                // onClick={props.onBookmark} 
                bookmarked={props.bookmarked === true ? true : props.bookmarkItemsIds?.includes(props.sessionfileId)}   
                addBookmark={() => props.addBookmark(props.sectionId, props.fileId, props.fileObj)}
                removeBookmark={() => props.removeBookmark(props.sectionId, props.fileId, props.fileObj)}
              />}
              {props.fileObj?.path_type == "FILE" ?
              <ButtonXs 
                // type="outline" 
                size="sm" 
                text="Download"  
                icon={DownloadIcon} 
                onClick={downloadFile}
                noHide 
              />
              : null
              }
              
            </div>

          </div>
        </li>
      </>
  );
}

export default DownloadFileList;