import React, { useEffect, useState }from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../components/Button';
import Nav from 'react-bootstrap/Nav';
import BookmarkFolders from '../../components/BookmarkFolders';
import AddBookmarkFolder from '../../components/AddBookmarkFolder';
import Alert from 'react-bootstrap/Alert';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import api from '../../adaptors/xhr/api';

function BSModal(props) {
  const [ errors, setErrors ] = useState();



  // const submitBookmark = async () => {
  //   if (!folderSelected) {
  //     setErrors(errorState => "Please select a folder to bookmark");
  //     return;
  //   }
  //   const response = await api.Bookmarks.bookmarkItem(
  //       folderSelected,
  //       props.bookmarkContext.sectionType,
  //       props.bookmarkContext.fileId
  //     )
  //   if (response.status === 200) {
  //     props.onHide();
  //     if (props.type==="module") {
  //       // Module bookmark
  //       props.bookmarkSuccess(folderSelected, props.bookmarkContext.fileObj.id, props.bookmarkContext.fileObj);
  //     }
  //     else {
  //       props.bookmarkSuccess(folderSelected, props.bookmarkContext.fileObj.sessionfileId, props.bookmarkContext.fileObj);
  //     }
  //   } 
  // }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="bookmark-remove-prompt"
    >
      {/* <Modal.Header closeButton> */}
        {/* <Modal.Title id="contained-modal-title-vcenter">
          <h6>Save to bookmarks</h6>
        </Modal.Title> */}
      {/* </Modal.Header> */}
      <Modal.Body>

        <h6 className="mt-4">
        Do you want to remove this link from your bookmarks?
        </h6>
        {errors
        ?<Alert variant="danger" className="mt-3">
          {`${errors}`}
        </Alert>
        : null}
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col px-0 d-flex align-items-between justify-content-center">
            <Button size="lg"  onClick={props.onHide} text='Cancel' className="mr-2"/>
            <Button type="warning" size="lg" onClick={props.confirmRemove} text='Remove Bookmark'/>
          </div>
        </div>
        
       
      </Modal.Footer>
    </Modal>
  );
}

export default BSModal;