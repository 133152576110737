import React from 'react';
import Nav from 'react-bootstrap/Nav';
import classNames from 'classnames';

function SmallActivityFilterNav(props) {

  const { filters } = props;

  let className = classNames({
    'discussion-filter-nav filter-nav d-lg-none': true,
    [props.className]: props.className ? true : false
  });

  return (

    <div className={className}>
      <Nav 
        defaultActiveKey={props.activeKey || "all"}
        className="flex-row"
        style={{"overflowX": "auto", "flexWrap": "nowrap"}}
        onSelect={(selectedKey) => props.onSelect(selectedKey)}>
           <Nav.Link key={`filter-all`} eventKey='all'><h6>All Bookmarks</h6></Nav.Link>
          {filters?.map(f => <Nav.Link key={`filter-${f.id}`} eventKey={f.id}><h6>{f.name}</h6></Nav.Link> )}
      </Nav>
    </div>
  );
}

export default SmallActivityFilterNav;