import types from "../types";

const initialState = {
    byId: {},
    allIds: []
};

export function spanishContentReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_SPANISH_CONTENT: {
      const { id, content, sectionId } = action.payload;
      return {
        ...state,
        allIds: Array.from(new Set(state.allIds.concat(id))),
        byId: {
          ...state.byId,
          [id]: {
            ...content,
            sectionId: sectionId
          }
        }
      };
    }
    default:
      return state;
  }
}