import React, { useEffect, useState, useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { findDiscussion } from '../../adaptors/xhr/communityService'
import {DISCISSIONS_SORT_FILTERS} from "../../constants";
import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import NavbarTabs from '../../components/NavbarTabs';
import PageContent from '../../components/PageContent';
import classNames from 'classnames';
import FaqAccordian from '../../components/FaqAccordian/FaqAccordian';
import FaqEntry from '../../components/FaqEntry';
import TopNavbar from '../../components/TopNavbar';
import SearchBar from '../../components/SearchBar';
import FilterDropdown from '../../components/FilterDropdown';
import DiscussionList from '../../components/DiscussionList';
import Button from '../../components/Button';
import DiscussionFilterNav from '../../components/DiscussionFilterNav';
import TopContributors from '../../components/TopContributors';
import CommunityWarning from '../../components/CommunityWarning';
import CommunityGuidelines from '../../components/CommunityGuidelinesModal';
import PostActionsModal from '../../components/PostActionsModal';
import LoginModal from '../../components/LoginModal';
import PageFooter from '../../components/PageFooter/PageFooter';
import api from '../../adaptors/xhr/api';
import { addPost,clearPost, addTopic, setSearchFilter, addContributor, setTopicFilter, setSortFilter } from './ducks/actions';
import { getPosts, getTopics, getTopicFilter, getSortFilter, getSearchFilter, getFilteredDiscussionIds, getFilteredDiscussion, getContributors } from './ducks/selectors';
import { getLoginState } from '../../pages/Common/ducks/selectors';
import { routes } from '../../routes';
import Paginator from '../../components/Paginator';
import AddLinkModal from '../../components/AddLinkModal/AddLinkModal';


function DiscussionHome(props) {
  const history = useHistory();
  const topicFilter=history.location.search.split("=")[1]
  const [guidelinesShow, setGuidelinesShow] = useState(false);
  const [createpostShow, setCreatePostShow] = useState(false);
  const [loginShow, setLoginShow] = useState(false);
  const [ sortFilter, setSortFilter ] = useState(DISCISSIONS_SORT_FILTERS.RECENT);
  const [page, setPage] = useState(1)
  const [totalDiscussions, setTotalDiscussions] = useState()
  const [startCount, setStartCount] = useState(1)
  const [disableState, setDisableState] = useState("null")
  const [disablePrev, setDisablePrev] = useState(true)
  const [disableNext, setDisableNext] = useState(false)
  const pageSize = 5;


  useEffect(() => {
    props.setSortFilter(DISCISSIONS_SORT_FILTERS.TOPDISCUSSIONS);
  }, [])

  const handleKeyDown = (event) => {
    console.log("eventkey", event.key)
    if (event.key === 'Enter') {
      console.log('do validate')
      findDiscussion(props.searchFilter)
      .then((response) => {
        response.data?.results.map(post => {
          props.addPost(post);
        })
      })
      .catch((err) => console.log(err))
    }
  }

  /** fetch top contributors */
  const fetchContributors = async () => {
    const response = await api.Forum.topContributors();
    response?.data?.map(contributor => {
      props.addContributor(contributor);
    })
  }

  /** search discussions **/
  function searchDiscussion(text) {
    props.setSearchFilter(text)

    findDiscussion(text)
    .then((response) => {
      response.data?.results.map(post => {
        props.addPost(post);
      })
    })
    .catch((err) => console.log(err))
  }

  /** hooks */
  const fetchTopics = async () => {
    const response = await api.Forum.topics();
    response.data?.results?.map(topic => {
      props.addTopic(topic)
    })
  }

  useEffect(() => {
    !props.topics?.length && fetchTopics();
    // fetchTopics();
    fetchDiscussions();
    fetchContributors();
  }, []);

  useEffect(() => {
    fetchDiscussions();
  }, [props.sortFilter, props.topicFilter])

  /** handles */
  const fetchDiscussions = async (pageInput=page) => {
    props.clearPost()
    try {
      let sortBy = "top-discussions";
      switch(props.sortFilter) {
        case DISCISSIONS_SORT_FILTERS.RECENT:
          sortBy = "recent";
          break;
        case DISCISSIONS_SORT_FILTERS.TOPDISCUSSIONS:
          sortBy = "top-discussions";
          break;
        default:
          sortBy = "top-discussions"
          break
      }
      if(!props.topicFilter) {
        console.log(sortBy, pageInput, page)
        const response = await api.Forum.discussions(sortBy, pageSize, pageInput);
        response.data?.results.map(post => {
          props.addPost(post);
        })
        setTotalDiscussions(response?.data?.count)

        // if(response.data.previous === null) setDisableState("previous")
        // if(response.data.next === null) setDisableState("next")
        // if(response.data.previous === null && response.data.next === null) setDisableState("all")
        // else setDisableState("null")
        // return;
        if(response.data.previous === null) {
          setDisablePrev(true)
        } else {
          setDisablePrev(false)
        }
        if(response.data.next === null) {
          setDisableNext(true)
        } else {
          setDisableNext(false)
        }
        if(response.data.previous === null && response.data.next === null) {
          setDisablePrev(true)
          setDisableNext(true)
        }
        return;
      }
      else {
        const response = await api.Forum.discussionsByTopic(props.topicFilter, sortBy,  pageSize, pageInput);
        response.data?.results.map(post => {
          props.addPost(post);
        })
      }


    }
    catch (error) {
      console.error(error);
    }

  }

  // const fetchDiscussionsByTopic = async (topicId) => {
  //   try {
  //     let sortBy = "top-discussions";
  //     switch(props.sortFilter) {
  //       case DISCISSIONS_SORT_FILTERS.RECENT:
  //         sortBy = "recent";
  //         break;
  //       case DISCISSIONS_SORT_FILTERS.TOPDISCUSSIONS:
  //         sortBy = "top-discussions";
  //         break;
  //       default:
  //         sortBy = "top-discussions"
  //         break
  //     }
  //     const response = await api.Forum.discussionsByTopic(topicId, sortBy);
  //     response.data?.results.map(post => {
  //       props.addPost(post);
  //     })
  //   }
  //   catch (error) {
  //     console.error(error);
  //   }
  // }

  const handlePostCreated = (post) => {
    // setCreatePostShow(false);
    props.addPost(post);
    history.push(`${routes.discussions}/${post.slug}/`);
  }

  const handleClickCreate = () => {
    if(props.currentUser.email)
      setGuidelinesShow(true);
    else
      setLoginShow(true);
  }

  const selectedTopic = (selectedKey) => {
    if(selectedKey == "all") {
      props.setTopicFilter(null);
      fetchDiscussions();
      return;
    }
    props.setTopicFilter(parseInt(selectedKey))
    // fetchDiscussionsByTopic(selectedKey)
  }

  const onChangeSortFilter = (sortFilter) => {
    props.setSortFilter(sortFilter);
  }

  const onContinueGuidelines = () => {
    setGuidelinesShow(false);
    if(props.currentUser.email)
      setCreatePostShow(true)
    else
      setLoginShow(true);
  }

  //pagination on clicks
  const handleNextPage = () => {
    // if(disableState === "all") return
    if(!disableNext) {
      setPage(page+1)
      setStartCount(startCount+pageSize)
    }
  }

  const handlePreviousPage = () => {
    // if(disableState === "all") return
    if(!disablePrev) {
      setPage(page-1)
      setStartCount(startCount-pageSize)
    }
  }

  useEffect(() => {
    fetchDiscussions()
  }, [page])

  const quillRef = useRef(null);
  const [quillEditor, setQuillEditor] = useState(null);
  const [addLinkShow, setAddLinkShow] = useState(false);
  const [selection, setSelection] = useState("");
  const [rangeIndex, setRangeIndex] = useState(0);

  const linkCallback = (link) => {
    quillRef.current.getEditor().format('link', link);
  }

  const newTextLinkCallback = (text, link) => {
    // let range = quillRef.current.getEditor().getSelection();
    // let contents = quillRef.current.getEditor().getContents();
    // quillRef.current.getEditor().setContents(contents.slice(0, rangeIndex) + text + contents.slice(rangeIndex));
    // quillRef.current.getEditor().formatText(rangeIndex, text.length, 'link', link);
    quillRef.current.getEditor().insertText(rangeIndex, text, 'link', link);
  }

  const linkHandler = (value) => {
    // if (value) {
      let range = quillRef.current.getEditor().getSelection();
      // if (range == null || range.length === 0) {
      //     return;
      // }
      console.log(range);
      if (range) {
        setRangeIndex(range.index);
      }

      setSelection(quillRef.current.getEditor().getText(range.index, range.length));

      setAddLinkShow(true);

      // let tooltip = quillRef.current.getEditor().theme.tooltip;
      // tooltip.edit('link', '');
      // console.log(tooltip);
    // } else {
    //   quillRef.current.getEditor().format('link', false)
    //   console.log("no value");
    // }
  };

  return (
  <>
    <TopNavbar />
    <Navbar />
    <PageHeader lg heading="Community">
      <p className="p2">Get help from your peers and discuss ideas with like-minded people from the community.</p>
      <div className=" community-search mx-auto">
        <SearchBar onChange={searchDiscussion} placeholder="Search for a topic or questions..." />
      </div>

    </PageHeader>
    <PageContent>
      <div className="row">
        <div className="col-xs-12 col-lg-8">
          <div className="training-overview-left">
            <div className="d-flex w-100 mb-4 justify-content-between align-items-center">
              <h5 className="mb-0">{props.topics?.find(topic => topic?.id == topicFilter)?.name || 'All discussions'}</h5>
              <FilterDropdown
                onSelect={(filter) => onChangeSortFilter(filter)}
                className="mr-2"
                sortFilters={DISCISSIONS_SORT_FILTERS}
                // className="d-none d-sm-block"
                activeFilter={props.sortFilter}
              />
            </div>

            {/* <DiscussionList discussions={props.posts}/> */}

            {props.filteredIds.length
            ?props.filteredIds.map((id) => {
              return <DiscussionList discussions={props.posts.byIds[id]}/>
            })
            :<DiscussionList discussions={props.posts}/>}
          </div>
          <Paginator
            onClickForPreviousPage={handlePreviousPage}
            onClickForNextPage={handleNextPage}
            resultsPerPage={pageSize}
            dataCount={totalDiscussions}
            startNumber={startCount}
            // disableState={disableState}
            disablePrev={disablePrev}
            disableNext={disableNext}
          />
        </div>

        <div className="col-xs-12 col-lg-3 offset-lg-1">
            <Button
              type="primary"
              size="lg"
              text="Create A New Post +"
              onClick={() => handleClickCreate(true)}
              className="my-4 my-md-auto"
            />

            <CommunityWarning
              className="mt-4"
              showModal={() => setGuidelinesShow(true)}
            />

            <DiscussionFilterNav
              // onSelect={(selectedKey) => selectedTopic(selectedKey)}
              // activeKey={1}
              onSelect={(selectedKey) => selectedTopic(selectedKey)}
              activeKey={props.topicFilter}
              className="my-4 d-none d-lg-block"
              filters={props?.topics}
            />

            {/* <TopContributors
              className="d-none d-lg-block"
              topContributors={props.topContributors}
            /> */}
        </div>
      </div>

      <CommunityGuidelines
        show={guidelinesShow}
        onHide={() => setGuidelinesShow(false)}
        onContinue={onContinueGuidelines}
      />

      <PostActionsModal
        show={createpostShow}
        onHide={() => setCreatePostShow(false)}
        onActionComplete={handlePostCreated}
        topics={props.topics}
        actionType={"Create"}
        postContent={{
          topic: '',
          title: '',
          body: ''
        }}
        linkHandler={linkHandler}
        quillRef={quillRef}
      />

      <LoginModal
        show={loginShow}
        onHide={() => setLoginShow(false)}
      />
    {/* {console.log('filtered',props)} */}
      <AddLinkModal
        show={addLinkShow}
        onHide={() => setAddLinkShow(false)}
        text={selection}
        linkCallback={linkCallback}
        newTextLinkCallback={newTextLinkCallback}
      />
    </PageContent>
    <PageFooter/>
  </>
  );
}

const mapStateToProps = state => {
  const posts = getPosts(state);
  const topics = getTopics(state);
  const filteredIds = getFilteredDiscussionIds(state)
  const filteredDiscussion = getFilteredDiscussion(state, filteredIds)
  const currentUser = getLoginState(state);
  const topContributors = getContributors(state);
  const topicFilter = getTopicFilter(state);
  const sortFilter = getSortFilter(state);
  const searchFilter = getSearchFilter(state);
  return { currentUser, topics, topicFilter, sortFilter, searchFilter, posts, filteredIds, filteredDiscussion, topContributors };
};

export default connect(
  mapStateToProps,
  { addPost,clearPost, addTopic, setTopicFilter, setSearchFilter, setSortFilter, addContributor }
)(DiscussionHome);
