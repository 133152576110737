import classNames from 'classnames';
import {
  BrowserRouter as Router,
  useRouteMatch,
  Link
} from "react-router-dom";
import Icon from '../../components/Icon';
import ResourceSvg from '../../assets/icons/resources.svg';
import SourceLinkIcon from '../../assets/icons/source-link.svg';
import Button from '../../components/Button';
import Rectangle50 from '../../assets/images/Rectangle50.png';
import dateFromString from '../../utils/dateFromString';
import isExternalLink from '../../utils/isExternalLink';
import { useHistory } from "react-router-dom";
import { routes } from '../../routes';
import monthFromString from '../../utils/monthFromString';

const NewsItem = (props) => {
  // const limitTheCharacters = (body, title) => {
  //   if(body.length + title.length > 60) {
  //     const output = `${body.substring(0, 54)}....`
  //     console.log(output)
  //     return output
  //   }
  //   return body
  // }
  let className = classNames({
    "news-item col-xs-12 col-sm mb-4 ": true,
    [props.className]: props.className ? true : false
  });

  // const history = useHistory();
  // const handleOnClick = (id) => {
  //   history.push(routes.newsAndUpdates + `#news-${id}`);
  // }


  return (
    <div className={className}>
      {/* {props.url !== null ?
        <a href={props.url} target={isExternalLink(props.url) ? "_blank" : ""} className='news-container-with-link'>
          <NewsItemContent {...props} />
        </a>
        :
        <NewsItemContent {...props} />
      } */}
        <NewsItemContent {...props} />
    </div>
  )
}

function NewsItemContent(props) {

  const getNewsItemLink = (id) => {
    return routes.newsAndUpdates + "#news-" + (id+1);
  }

  return (
    <div className="d-flex justify-content-start align-items-start">
      <div>
        <Link to={getNewsItemLink(props?.id)} relative="path" className='news-container-with-link'>
          <div className='image-container img-responsive'>
            <img src={props.picture || Rectangle50} className="" alt="news thumbnail" />
          </div>
          {/* <span>{props.category.name}</span> */}
          <p className="p3 news-title mt-0 mt-sm-auto">{props.title}</p>
          <p className="p4">{props?.content} </p>
        </Link>

        {
          props.url !== null ?
            <div class="d-flex flex-row-reverse flex-sm-column justify-content-between align-items-center align-items-sm-start">
              <a
                href={props.url ? props.url : null}
                target={isExternalLink(props.url) ? "_blank" : "_self"}
                key={props?.id}
                className='d-flex align-items-center source-link'
              >
                Source
                <img
                  src={SourceLinkIcon}
                  className='source-link-icon'
                />
              </a>
              <span className="s1">{monthFromString(props.updated_at)}</span>
            </div>
          :
            <span className="s1">{monthFromString(props.updated_at)}</span>
        }
      </div>
      {/* <div className="d-block d-sm-none" style={{ height: "162px", marginBottom: "20px" }}>
        <img src={props.picture || Rectangle50} className="img-responsive" alt="news thumbnail" />
      </div> */}
    </div>
  )
}

export default function NewsSection(props) {
  const { newsList = [
    {
      'category': 'News',
      'title': 'A guide to family planning for community health workers and their clients',
      'content': 'Two new chapters of the Family Planning Handbook are now available at fphandbook.org  Photo Credit: Karen Kasmauski/MCSP and JhpiegoOne chapter will offer practical advice on providing family planning to women and girls in high HIV burden settings, and assure the Global Handbook remains in alignment with WHO recommendations.',
      'thumbnail': ''
    },
    {
      'category': 'Module Update',
      'title': 'A guide to family planning for community health workers and their clients',
      'content': 'Two new chapters of the Family Planning Handbook are now available at fphandbook.org  Photo Credit: Karen Kasmauski/MCSP and JhpiegoOne chapter will offer practical advice on providing family planning to women and girls in high HIV burden settings, and assure the Global Handbook remains in alignment with WHO recommendations.',
      'thumbnail': ''
    },
    {
      'category': 'News',
      'title': 'A guide to family planning for community health workers and their clients',
      'content': 'Two new chapters of the Family Planning Handbook are now available at fphandbook.org  Photo Credit: Karen Kasmauski/MCSP and JhpiegoOne chapter will offer practical advice on providing family planning to women and girls in high HIV burden settings, and assure the Global Handbook remains in alignment with WHO recommendations.',
      'thumbnail': ''
    },
    {
      'category': 'Module Update',
      'title': 'A guide to family planning for community health workers and their clients',
      'content': 'Two new chapters of the Family Planning Handbook are now available at fphandbook.org  Photo Credit: Karen Kasmauski/MCSP and JhpiegoOne chapter will offer practical advice on providing family planning to women and girls in high HIV burden settings, and assure the Global Handbook remains in alignment with WHO recommendations.',
      'thumbnail': ''
    }
  ] } = props;
  const history = useHistory();
  const handleOnClick = () => {
    history.push(routes.newsAndUpdates)
  }

  return (
    <div className="home-news-section">
      <div className="container-xl pb-5">
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <h4 className="m-0">News and Updates</h4>
          <Button size="md" type="outline" text="View All Updates" minWidth className="d-none d-sm-block" onClick={handleOnClick} />
        </div>

        <div className="home-news row d-flex justify-content-between align-items-start">
          {newsList.map((item, idx) => <NewsItem {...item} key={item?.id} />)}
        </div>
        <Button size="md" type="outline" text="View All Updates" className="d-block d-sm-none mt-2 mb-0" onClick={handleOnClick} />
      </div>
    </div>
  );
}
