import { useState } from "react";
import { Modal } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import api from "../../adaptors/xhr/api";
import Button from "../Button/Button";

function DeleteActivityModal({ activityContent, activityType, onDeleteComplete, ...props }) {

  const [isSubmitting, setSubmitting] = useState(false);

  const submitHandle = async () => {
    setSubmitting(true);

    try {
      // delete / comment
      if (activityType === "post") {
        var response = await api.Forum.deletePost(activityContent?.id);
      } else {
        var response = await api.Forum.deleteComment(activityContent?.id);
      }
      if (response.status === 204) {
        // succesfully deleted post
        // setEmailSent(values.email);
        // resetForm();
        // close modal
        onDeleteComplete();
      }
      props.onHide();
    }
    catch (error) {
      // read server validation errors
      // if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      console.error(error);
    }
    setSubmitting(false);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="community-guidelines-modal delete-activity-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header>
      <Form className="form" noValidate>
        <Modal.Body>
          <div className="d-flex justify-content-start align-items-start title-section">
            <h4>{`Are you sure you want to delete your ${activityType === "post" ? 'post' : 'comment'}?`}</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            <div className="col px-0 d-flex align-items-between justify-content-center">
              <Button size="lg" onClick={props.onHide} text='Cancel' className="mr-2" />
              <Button type="primary" size="lg" text={
                isSubmitting ? "Deleting..." : "Delete"
              } onClick={submitHandle} />
            </div>
          </div>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default DeleteActivityModal;