import React, { useEffect, useState, useRef } from "react";
import Navbar from "react-bootstrap/Navbar";
import { connect } from "react-redux";
import { NavDropdown } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import UserThumb from "../../components/UserThumb";
import Brand from "../../assets/images/Brand.svg";
import SearchIcon from "../../assets/icons/search-2.svg";
import SearchBlueIcon from "../../assets/icons/search-blue.svg";
import CloseIcon from "../../assets/icons/close.svg";
import MenuIcon from "../../assets/icons/menu.svg";
import { routes } from "../../routes";
import { getLoginState } from "../../pages/Common/ducks/selectors";
import { logoutUser, addSearchResults, isExpandedToggle } from "../../pages/Common/ducks/actions";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

import LocalStorageService from "../../adaptors/storage/localStorageService";
import SiteSearch from "../SiteSearch/SiteSearch";
import ContactModal from "../ContactModal";

const localStorageService = LocalStorageService.getService();

function BSNavbar(props) {
  const [contactShow, setContactShow] = useState(false);
  const handleContactClick = () => {
    setContactShow(true);
  };

  const [showSiteSearch, setShowSiteSearch] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleMenu = (expanded) => {
    setIsExpanded(expanded);
    props.isExpandedToggle();
    if (showSiteSearch) { setShowSiteSearch(false) }
  }

  // const dispatch = useDispatch();

  const handleClickLogout = () => {
    localStorageService.clearToken();
    props.logoutUser();
  };


    const history = useHistory();
    const location = useLocation();

  const toggleSearch = () => {
    setShowSiteSearch((val) => !val);
    if (location.pathname == routes.searchResults) {
        history.push(routes.home);
    }
  };

  const saveResult = (data) => {
    props.addSearchResults(data);
  };

  useEffect(() => {
    if (props.siteSearch) setShowSiteSearch(true);
  }, [props]);


  const navRef = useRef(null);

  // useEffect(() => {
  //   if (navRef && props?.bcHt) {
  //     console.log("navRef", props.bcHt);
  //     navRef.current.style.top = props.bcHt + 'px';
  //   }
  // }, [props?.bcHt]);

  useEffect(() => {
    if (props?.setHt && navRef && navRef?.current) {
      console.log("navRef", navRef?.current?.clientHeight);
      props?.setHt(navRef?.current?.clientHeight);
    }
  }, [navRef?.current?.clientHeight]);

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        bg="light"
        variant="light"
        className={`navbar-main ${isExpanded ? 'h-100' : ''}`}
        // fixed="top"
        sticky={props.nosticky ? null : "top"}
        ref={navRef}
        onToggle={toggleMenu}
      >
        <div className="container-xl">
          <LinkContainer to={routes.home}>
            <Navbar.Brand>
            {!isExpanded ?
              <img
                alt=""
                src={Brand}
                width="44"
                height="44"
                className="d-inline-block align-top"
              />
              : null}
            </Navbar.Brand>
          </LinkContainer>
          <LinkContainer to={routes.home}>
            <Navbar.Brand
              href={routes.home}
              className="site-name d-none d-lg-block"
            >
              Training Resource Package for Family Planning
            </Navbar.Brand>
          </LinkContainer>
          <div
            className={`d-flex align-items-center justify-content-center ${isExpanded ? '' : ''}`}
            style={{gap: '21px'}}>
            {!isExpanded ? <img
              src={showSiteSearch ? CloseIcon : SearchBlueIcon}
              className={`img-responsive d-md-none search-img search-img-mobile ${showSiteSearch ? 'search-img-close' : ''}`}
              alt="search icon"
              onClick={toggleSearch}
            /> : null}
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              children={
                <img
                  src={isExpanded ? CloseIcon : MenuIcon}
                  className={`img-responsive mx-auto d-block menu-img ${isExpanded ? 'menu-img-close' : ''}`}

                />
              }
              className={`${isExpanded ? 'position-absolute menu-close' : ''}`}
            />
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className={`ml-auto ${isExpanded ? '' : ''}`}>
              <LinkContainer to={routes.training} >
                <Nav.Link activeClassName="active">Training Modules</Nav.Link>
              </LinkContainer>
              <LinkContainer exact to={routes.trainingGuides}>
                <Nav.Link>Training Guides</Nav.Link>
              </LinkContainer>
              <LinkContainer to={routes.discussions}>
                <Nav.Link>Community</Nav.Link>
              </LinkContainer>
              <LinkContainer to={routes.about}>
                <Nav.Link>About TRP</Nav.Link>
              </LinkContainer>
              <div className="d-md-none">
                <Nav className="ml-auto">
                  <LinkContainer to={routes.spanishMaterials}>
                    <Nav.Link eventKey="spanishMaterials">
                      Materiales en Español
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={routes.frenchMaterials}>
                    <Nav.Link eventKey="frenchMaterials">
                      Matériaux en Français
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to={routes.gettingStartedFaq}>
                    <Nav.Link eventKey="faq">FAQs</Nav.Link>
                  </LinkContainer>
                  <Nav.Link onClick={handleContactClick}>Contact Us</Nav.Link>
                </Nav>
              </div>
              {!props.currentUser.email ? (
                <LinkContainer to={routes.login} style={{marginRight: '14px'}}>
                  <Nav.Link>Account</Nav.Link>
                </LinkContainer>
              ) : null}

              {props.currentUser.email ? (
                <NavDropdown
                  title={
                    props.currentUser.first_name?.length
                      ? props.currentUser.first_name
                      : props.currentUser.email
                  }
                  id="basic-nav-dropdown"
                  className="nav-profile-name d-md-none mobile-view-dropdown"
                >
                  <LinkContainer to={`/community/user/${props.currentUser.pk}`}>
                    <NavDropdown.Item>My Profile</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={routes.profileSettings}>
                    <NavDropdown.Item>Settings</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleClickLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : null}
            </Nav>
            {props.currentUser.email ? (
              <div>
                <NavDropdown
                  title={
                    props.currentUser.profile_picture?.length ? (
                      <div className="pull-left user-dropdown">
                        <img
                          className="thumbnail-image"
                          src={props.currentUser?.profile_picture}
                          alt="user pic"
                        />
                      </div>
                    ) : (
                      <UserThumb
                        className="thumbnail-image"
                        thumbnail={props.currentUser?.profile_picture}
                        firstName={props.currentUser?.first_name}
                        lastName={props.currentUser?.last_name}
                      />
                    )
                  }
                  id="basic-nav-dropdown"
                  className="nav-profile-name d-none d-md-block"
                >
                  <LinkContainer to={`/community/user/${props.currentUser.pk}`}>
                    <NavDropdown.Item>My Profile</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to={routes.profileSettings}>
                    <NavDropdown.Item>Settings</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={handleClickLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            ) : null}
            <img
              src={showSiteSearch ? CloseIcon : SearchIcon}
              className="img-responsive mx-auto d-md-block d-none search-img search-img-desktop"
              alt="search icon"
              onClick={toggleSearch}
            />
          </Navbar.Collapse>
        </div>
      </Navbar>
      {showSiteSearch ? <SiteSearch saveResult={saveResult} /> : null}

      <ContactModal show={contactShow} onHide={() => setContactShow(false)} />
    </>
  );
}

const mapStateToProps = (state) => {
  const currentUser = getLoginState(state);
  return { currentUser };
};

export default connect(mapStateToProps, { logoutUser, addSearchResults, isExpandedToggle })(
  BSNavbar
);
