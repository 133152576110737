import { combineReducers } from "redux";
import types from "../types";

const moduleInitialState = {
    byIds: {},
    allIds: [],
    moduleId: null
};


export function moduleLearningObjectiveReducer(state = moduleInitialState, action) {
  switch (action.type) {
    case types.ADD_MODULE_LEARNING_OBJECTIVES: {
      const { id, data, moduleId } = action.payload;
      data['moduleId'] = moduleId
      return {
        ...state,
        allIds: Array.from(new Set([...state.allIds, id])),
        byIds: {
          ...state.byIds,
          [id]: {
            ...data
          }
        }
      };
    }
    default:
      return state;
  }

}