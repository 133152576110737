import React, { useEffect, useState, useRef } from 'react';
import {
  useParams,
  useLocation,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import NavbarTabs from '../../components/NavbarTabs';
import TrainingSection from '../../components/TrainingSection';
import TrainingSectionSmall from '../../components/TrainingSectionSmall';
import {
  getModuleDetailService,
  getModuleDetailServiceBySlug,
  getModuleSessionPlan,
  getPresentationSlide,
  getEvaluationTools,
  getHandouts,
  getReferences,
  getVideosByModuleId,
  downloadPresentationSlides
} from '../../adaptors/xhr/trainingService';
import {
  getModuleById,
  getSessionprepSections,
  getSessionpreps,
  getBookmarksFolders,
  getBookmarkItemById,
  getBookmarkItems,
  getBookmarkItemsList,
  getBookmarkContextState,
  getSessionprepById,
  getDefaultBookmarkFolderId,
} from './ducks/selectors';
import { addColorGuides } from '../Common/ducks/actions';
import { getLoginState, getColorGuidesContent } from '../Common/ducks/selectors';
import { addModule, addSessionFile, addBookmarkFolder, addBookmarkContext,
  addBookmarkItem, removeBookmarkItem, addVideo, addModuleDiscussionId,
  addDefaultBookmarkFolder ,
} from './ducks/actions';
import TrainingAccordian from '../../components/TrainingAccordian';
import TopNavbar from '../../components/TopNavbar';
import PageFooter from '../../components/PageFooter/PageFooter';
import BookmarkModal from '../../components/BookmarkModal';
import api from '../../adaptors/xhr/api';
import ColorGuide from '../../components/ColorGuide';
import FilePreviewModal from '../../components/FilePreviewModal';
import LoginModal from '../../components/LoginModal';
import VideoBlock from '../../components/VideoBlock/VideoBlock';
import VideoBlockSmall from '../../components/VideoBlockSmall';
import colorGuide from '../../adaptors/xhr/colorGuideService';
import { findDiscussion } from '../../adaptors/xhr/communityService'
import { addPost } from '../Community/ducks/actions'

function Sessions(props) {

  const [ id, setId ] = useState(null);
  const [ modalShow, setModalShow ] = useState(false);
  const [ colorGuideShow, setColorGuideShow ] = useState(false);
  const [ previewModalShow, setPreviewModalShow ] = useState(false);
  const [ previewFileObj, setPreviewFileObj ] = useState(null);
  const [ loginShow, setLoginShow ] = useState(false);
  const [moduleSessionPlanCount, setModuleSessionPlanCount] = useState(0)
  const [evaluationToolsCount, setEvaluationToolsCount] = useState(0)
  const [presentationSlidesCount, setPresentationSlidesCount] = useState(0)
  const [handoutsCount, setHandoutsCount] = useState(0)
  const [referencesCount, setReferencesCount] = useState(0)

  const videoRef = useRef(null);

  const showColorGuides = () => {
    setColorGuideShow(true)
  }
  const [videos, setVideos] = useState([])

  let { slug } = useParams();
  const module = useSelector((state) => getModuleById(state, id));

  const fetchVideo = async (id) => {
    const response = await getVideosByModuleId(id)
    setVideos(response.data)
  }

  const fetchBookmarks = async () => {
    try {
    const response = await api.Bookmarks.folders();
    response.data?.map(bookmark => {
      props.addBookmarkFolder(bookmark);

      if (bookmark.is_default == true) props.addDefaultBookmarkFolder(bookmark);

      // fetch modules
      bookmark.modules?.map(item => {
        props.addBookmarkItem(bookmark.id, `module-${item.id}`, item, "modules");
      })

      // fetch module_session_plans
      bookmark.module_session_plans?.map(item => {
        props.addBookmarkItem(bookmark.id, `module_session_plan-${item.module.id}-${item.id}`, item, "module_session_plans");
      })

      // fetch presentation_slides
      bookmark.presentation_slides?.map(item => {
        props.addBookmarkItem(bookmark.id, `presentation_slides-${item.module.id}-${item.id}`, item, "presentation_slides");
      })

      // fetch evaluation_tools
      bookmark.evaluation_tools?.map(item => {
        props.addBookmarkItem(bookmark.id, `evaluation_tools-${item.module.id}-${item.id}`, item, "evaluation_tools");
      })

      // fetch references
      bookmark.references?.map(item => {
        props.addBookmarkItem(bookmark.id, `references-${item.module.id}-${item.id}`, item, "references");
      })

      // fetch handouts
      bookmark.handouts?.map(item => {
        props.addBookmarkItem(bookmark.id, `handouts-${item.module.id}-${item.id}`, item, "handouts");
      })

    })
    } catch (err) {
        console.error(err);
    }
  }

  useEffect(() => {

    getModuleDetailServiceBySlug(slug)
    .then(response => {
      console.log('Success:', response.data);
      setId(response.data.id);
      props.addModule(response.data, response.data.category?.id);
    })
    .catch((error) => {
      console.error('Error:', error);
    })

    fetchColorGuide();

  }, []);

  const [loading, setLoading] = useState(false);
  const [loadedSections, setLoadedSections] = useState(false);

  useEffect(() => {
    if (id != null ) {
      getModuleSessionPlan(id)
      .then(response => {
        response.data.results.map(sessionfile => {
          props.addSessionFile(id, "module_session_plan", `module_session_plan-${id}-${sessionfile.id}`, sessionfile)
        })
        setModuleSessionPlanCount(response.data.results.length)
        setLoadedSections((prev) => prev+1)
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      getPresentationSlide(id)
      .then(response => {
        response.data?.results?.map(sessionfile => props.addSessionFile(id, "presentation_slides", `presentation_slides-${id}-${sessionfile.id}`, sessionfile))
        setPresentationSlidesCount(response.data.results.length)
        setLoadedSections((prev) => prev+1)
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      getEvaluationTools(id)
      .then(response => {
        response.data?.results?.map(sessionfile =>
          props.addSessionFile(id, "evaluation_tools", `evaluation_tools-${id}-${sessionfile.id}`, sessionfile))
          setEvaluationToolsCount(response.data.results.length)
          setLoadedSections((prev) => prev+1)
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      getHandouts(id)
      .then(response => {
        response.data?.results?.map(sessionfile =>
          props.addSessionFile(id, "handouts", `handouts-${id}-${sessionfile.id}`, sessionfile))
          setHandoutsCount(response.data.results.length)
          setLoadedSections((prev) => prev+1)
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      getReferences(id)
      .then(response => {
        response.data?.results?.map(sessionfile =>
          props.addSessionFile(id, "references", `references-${id}-${sessionfile.id}`, sessionfile))
          setReferencesCount(response.data.results.length)
          setLoadedSections((prev) => prev+1)
      })
      .catch((error) => {
        console.error('Error:', error);
      })

      findDiscussion(module.name)
      .then((values) => {
          values.data?.results?.map((post) => {
              props.addPost(post)
              props.addModuleDiscussionId(post.id, id)
          })
      }).catch((err) => console.log(err))

      fetchVideo(module.id);

    }

  }, [id])

  useEffect(() => {
    fetchBookmarks();
  }, [props.currentUser.email])

  const addBookmark = (section, fileId, fileObj) => {

    if (props.currentUser.email) {
      props.addBookmarkContext(section, fileId, fileObj);
      setModalShow(true);
    }
    else
      setLoginShow(true);

  }

  const scope = this;

  const removeBookmark = async (section, fileId, fileObj) => {
    props.addBookmarkContext(section, fileId, fileObj);
    // props.removeBookmarkItem(fileObj.folderId, fileObj.itemId, fileObj);
    console.log(">>section", section)
    console.log(">>fileId", fileId)
    console.log(">>fileObj", fileObj)
    const bookmarkItem = await props.bookmarkItems.find(item => item.itemId == fileObj.sessionfileId)
    const response = await api.Bookmarks.removebookmarkedItem(
      bookmarkItem.folderId,
      bookmarkItem.sessionType,
      bookmarkItem.pk
    )
    if (response.status === 200) {
      props.removeBookmarkItem(bookmarkItem.folderId, bookmarkItem.itemId, bookmarkItem);
      setModalShow(false);
    }
  }

  const previewFile = (fileObj) => {
    setPreviewFileObj(fileObj)
    setPreviewModalShow(true);
  }

   const fetchColorGuide = async () => {
    const response = await colorGuide.presentationSlide();
    if(response.data) {
      props.addColorGuides(response.data);
    }
  }

  const location = useLocation();
  useEffect(() => {
    if (loadedSections == 5) {
        if (location.hash) {
            const hashValue = location.hash.slice(1)
            if (hashValue === "videos") {
                if(videoRef?.current) {
                    const barHeight = "104px"
                    videoRef.current.style.scrollMargin = barHeight
                    videoRef.current?.scrollIntoView({ behavior: "smooth" })
                }
            }
        }
    }
  }, [loadedSections, location])

  const [bcHt, setBcHt] = useState(0);

  const setHt = (value) => {
    setBcHt(value);
  }

  return (
  <>
    <TopNavbar sticky />
    <Navbar nosticky />
    <Breadcrumb
        breadcrumbs={["Home", "Training Modules", module.name]}
        links={["/","/training/",`/training/${module.slug}/`]}
        sticky
        setHt={setHt}
    />
    <PageHeader heading={ module.name }/>
   {module.relatedDiscussionsId && module.relatedDiscussionsId.length
    ?<NavbarTabs discussionsNumbers = {module.relatedDiscussionsId.length} bcHt={bcHt}/>
  :<NavbarTabs discussionsNumbers = {[0]} bcHt={bcHt}/>}

    <div className="py-4 d-block d-sm-none">

      <TrainingAccordian>
        <TrainingSectionSmall
          moduleId={id}
          eventKey="0"
          title="Module Session Plan"
          sectionId="module_session_plan"
          description={module.module_session_plan_desc || `The session plan describes the subject content,
          method of instruction, learning activities,
          resources and materials that will support
          you in preparing for and conducting the session.
          It includes a training schedule that provides
          a breakdown of the module by day, time, and topic.`  }
          files={useSelector((state) => getSessionpreps(state, module.module_session_plan)) || []}
          count = {moduleSessionPlanCount}
          bookmarks={props.bookmarks}
          bookmarkItemsIds={props.bookmarkItemsIds}
          addBookmark={addBookmark}
          removeBookmark={removeBookmark}
          showColorGuides={showColorGuides}
          previewFile={previewFile}
        />

        <TrainingSectionSmall
          moduleId={id}
          eventKey="1"
          title="Presentation slides"
          sectionId="presentation_slides"
          description={module.presentation_slides_desc || `The presentation slides provide technical information
          on the subject matter. The text for these slides
          can be found in the speaker notes.` }
          files={useSelector((state) => getSessionpreps(state, module.presentation_slides)) || []}
          count={presentationSlidesCount}
          bookmarks={props.bookmarks}
          bookmarkItemsIds={props.bookmarkItemsIds}
          addBookmark={addBookmark}
          removeBookmark={removeBookmark}
          showColorGuides={showColorGuides}
          previewFile={previewFile}
        />

        <TrainingSectionSmall
          moduleId={id}
          eventKey="2"
          title="Evaluation Tools"
          sectionId="evaluation_tools"
          description={module.evaluation_tools_desc || `The module contains tools for evaluating
          the knowledge and skill of trainees. These include pre- and
          post-knowledge assessment questionnaires having
          objective questions, with instructions for scoring
          the tests and  competency-based skills checklists
          for assessing the acqired skills during and after
          the training. It also contains the course evaluation tool.` }
          files={useSelector((state) => getSessionpreps(state, module.evaluation_tools)) || []}
          count = {evaluationToolsCount}
          bookmarks={props.bookmarks}
          bookmarkItemsIds={props.bookmarkItemsIds}
          addBookmark={addBookmark}
          removeBookmark={removeBookmark}
          showColorGuides={showColorGuides}
          previewFile={previewFile}
        />

        <TrainingSectionSmall
          moduleId={id}
          eventKey="3"
          title="Handouts"
          sectionId="handouts"
          description={module.handouts_desc || `The module has a set of handouts with
          specific information and key take away messages
          and Job aids to support learning at workplace.
          Handouts should be printed and distributed to the learners.` }
          files={useSelector((state) => getSessionpreps(state, module.handouts)) || []}
          count = {handoutsCount}
          bookmarks={props.bookmarks}
          bookmarkItemsIds={props.bookmarkItemsIds}
          addBookmark={addBookmark}
          removeBookmark={removeBookmark}
          showColorGuides={showColorGuides}
          previewFile={previewFile}
        />

        <TrainingSectionSmall
          moduleId={id}
          eventKey="4"
          title="References"
          sectionId="references"
          description={module.references_desc || `The main references for the implants module
          as well as for other modules of the TRP are the
          World Health Organization’s four cornerstones
          of family planning guidance.` }
          files={useSelector((state) => getSessionpreps(state, module.references)) || []}
          count = {referencesCount}
          bookmarks={props.bookmarks}
          bookmarkItemsIds={props.bookmarkItemsIds}
          addBookmark={addBookmark}
          removeBookmark={removeBookmark}
          showColorGuides={showColorGuides}
          previewFile={previewFile}
        />

        {videos?.length && videos?.length > 0 ? <VideoBlockSmall
          moduleId={id}
          eventKey="5"
          title="Videos"
          sectionId="videos"
          description={module?.video_desc ? module?.video_desc : "Short description that can be added for media block. It can be short upto 150 characters."}
          videoData={videos}
        />: null}

      </TrainingAccordian>
    </div>

    <div className="bg-grey py-5 d-none d-sm-block">
            <TrainingSection
              moduleId={id}
              sectionId="module_session_plan"
              title="Module Session Plan"
              description={module.module_session_plan_desc}
              files={props.sessionprepfiles}
              bookmarks={props.bookmarks}
              bookmarkItemsIds={props.bookmarkItemsIds}
              addBookmark={addBookmark}
              removeBookmark={removeBookmark}
              showColorGuides={showColorGuides}
              previewFile={previewFile}
              downloadPresentationSlides={downloadPresentationSlides}
            />
          </div>

      <div className="bg-grey py-5 d-none d-sm-block">
            <TrainingSection
              moduleId={id}
              sectionId="presentation_slides"
              title="Presentation slides"
              description={module.presentation_slides_desc }
              files={props.sessionprepfiles}
              bookmarks={props.bookmarks}
              bookmarkItemsIds={props.bookmarkItemsIds}
              addBookmark={addBookmark}
              removeBookmark={removeBookmark}
              showColorGuides={showColorGuides}
              previewFile={previewFile}
              downloadPresentationSlides={downloadPresentationSlides}
            />
          </div>

        <div className="bg-grey py-5 d-none d-sm-block">
            <TrainingSection
              moduleId={id}
              sectionId="evaluation_tools"
              title="Evaluation Tools"
              description={module.evaluation_tools_desc }
              files={props.sessionprepfiles}
              bookmarks={props.bookmarks}
              bookmarkItemsIds={props.bookmarkItemsIds}
              addBookmark={addBookmark}
              removeBookmark={removeBookmark}
              showColorGuides={showColorGuides}
              previewFile={previewFile}
              downloadPresentationSlides={downloadPresentationSlides}
            />
          </div>

    <div className="bg-grey py-5 d-none d-sm-block">
            <TrainingSection
              moduleId={id}
              sectionId="handouts"
              title="Handouts"
              description={module.handouts_desc }
              files={props.sessionprepfiles}
              bookmarks={props.bookmarks}
              bookmarkItemsIds={props.bookmarkItemsIds}
              addBookmark={addBookmark}
              removeBookmark={removeBookmark}
              showColorGuides={showColorGuides}
              previewFile={previewFile}
              downloadPresentationSlides={downloadPresentationSlides}
            />
          </div>

          <div className="bg-grey py-5 d-none d-sm-block">
            <TrainingSection
              moduleId={id}
              sectionId="references"
              title="References"
              description={module.references_desc }
              files={props.sessionprepfiles}
              bookmarks={props.bookmarks}
              bookmarkItemsIds={props.bookmarkItemsIds}
              addBookmark={addBookmark}
              removeBookmark={removeBookmark}
              showColorGuides={showColorGuides}
              previewFile={previewFile}
              downloadPresentationSlides={downloadPresentationSlides}
            />
          </div>
      {/* {props.sessionprepSections.map((section, index) => {
        console.log("section", section)
        return (
          <div className={`${index != 0 ? 'bg-grey ': null} py-5 d-none d-sm-block`}>
            <TrainingSection
              moduleId={id}
              sectionId={section.sectionId}
              title={section.name}
              description={section.description}
              files={props.sessionprepfiles}
              bookmarks={props.bookmarks}
              bookmarkItemsIds={props.bookmarkItemsIds}
              addBookmark={addBookmark}
              removeBookmark={removeBookmark}
              showColorGuides={showColorGuides}
              previewFile={previewFile}
              downloadPresentationSlides={downloadPresentationSlides}
            />
          </div>
        )
      })} */}

      {/* video block */}


      {videos?.length && videos.length > 0 ? <div id="videos" className='bg-grey py-5 d-none d-sm-block' ref={videoRef}>
        <VideoBlock videoData={videos} videoDecs={module.video_desc} id="videos"/>
      </div> : null}


    <PageFooter/>
    <BookmarkModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      folders={props.bookmarksFolders}
      defaultFolderId={props.bookmarkFolderDefaultId}
      bookmarkContext={props.bookmarkContext}
      // bookmarkInfo={{sessionType: bookmarksessionType, itemId: bookmarkItemId}}
      onCreatedFolder={props.addBookmarkFolder}
      bookmarkSuccess={props.addBookmarkItem} // action
    />
    <ColorGuide
      show={colorGuideShow}
      onHide={() => setColorGuideShow(false)}
      content={props.colorGuide}
    />

    <FilePreviewModal
      show={previewModalShow}
      onHide={() => setPreviewModalShow(false)}
      fileObj={previewFileObj}
    />

    <LoginModal
      show={loginShow}
      onHide={() => setLoginShow(false)}
    />
  </>
  );
}

const mapStateToProps = state => {
  const sessionprepfiles = getSessionpreps(state);
  const sessionprepSections = getSessionprepSections(state);
  const bookmarksFolders = getBookmarksFolders(state);
  const bookmarkFolderDefaultId = getDefaultBookmarkFolderId(state);
  const bookmarks = getBookmarkItems(state);
  const bookmarkItemsIds = getBookmarkItemsList(state);
  const bookmarkItems = getBookmarkItems(state);
  const bookmarkContext = getBookmarkContextState(state);
  const bookmarkContextFileObj = getSessionprepById(state, bookmarkContext?.fileId)
  const currentUser = getLoginState(state);
  const colorGuide = getColorGuidesContent(state);
  return {
    sessionprepSections,
    sessionprepfiles,
    bookmarksFolders,
    bookmarkFolderDefaultId,
    bookmarks,
    bookmarkItemsIds,
    bookmarkItems,
    bookmarkContext,
    bookmarkContextFileObj,
    currentUser,
    colorGuide
  };
};

export default connect(
  mapStateToProps,
  { addModule,
    addSessionFile,
    addBookmarkFolder,
    addBookmarkContext,
    addBookmarkItem,
    removeBookmarkItem,
    addVideo,
    addColorGuides,
    addModuleDiscussionId,
    addPost,
    addDefaultBookmarkFolder,
    getBookmarkItemById
  }
)(Sessions);
