import classNames from 'classnames';


export default function Button(props) {
  let className = classNames({
    'button-default': true,
    'button-primary btn-cool': props.type === "primary" ? true : false,
    'button-outline': props.type === "outline" ? true : false,
    'button-tertiary': !props.type,
    'button-warning': props.type === "warning" ? true : false,
    'btn-lg': props.size && props.size === "lg",
    'btn-sm': props.size && props.size === "sm",
    'btn-md': props.size && props.size === "md",
    'align-middle': true,
    'd-none d-sm-block': props.hideOnXs ? true : false,
    'w-100': !props.minWidth ? true : false,
    [props.className]: props.className ? true : false
  });

  return (
    <button onClick={props.onClick} className={className} type={props.submit ? "submit" : "button"}>
      {/* <span>{props.text}</span> */}
      {props.text}
      {props.icon ? <img src={props.icon} className="img-responsive btn-icon align-middle" alt="action icon" /> : null}
    </button>
  );
}