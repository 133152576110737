import types from "../types";

const initialState = {
    byIds: {},
    allIds: [],
    topicFilter: null,
    sortFilter: null,
    searchFilter: null,
    discussionFilter: []
};

export function postsReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_POST: {
      const { id, post } = action.payload;
      return {
        ...state,
        allIds: Array.from(new Set([...state.allIds, id])),
        byIds: {
          ...state.byIds,
          [id]: {
            ...state.byIds[id],
            ...post,
            // comments: [],
            // relatedDiscussions: []
          }
        }
      };
    }
    case types.CLEAR_POST: {
      return {
       ...initialState
      }
    }
    case types.ADD_COMMENT: {
      const { postId, commentId, comment } = action.payload;
      // Look up the correct post
      const post = state.byIds[postId]
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [postId]: {
            ...post,
            comments: post.comments?.indexOf(commentId) === -1 ? post.comments.concat(commentId) : [commentId]
          }
        }
      };
    }
    // filtering
    case types.SET_TOPIC_FILTER: {
      const { topic } = action.payload
      return {
        ...state,
        byIds: {},
        allIds: [],
        topicFilter: topic
      }
    }

    case types.SET_SORT_FILTER: {
      const { filter } = action.payload
      return {
        ...state,
        byIds: {},
        allIds: [],
        sortFilter: filter
      }
    }

    case types.SET_DISCUSSION_SEARCH_FILTER: {
      const { searchText } = action.payload
      // console.log(state.discussionFilter)
      return {
        ...state,
        byIds: {},
        allIds: [],
        topicFilter: null,
        searchFilter: searchText
      }
    }
    case types.ADD_POST_RELATED_DISCUSSION: {
      const { mainPostId, relatedPostId } = action.payload;
      // Look up the correct post
      const post = state.byIds[mainPostId]
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [mainPostId]: {
            ...post,
            relatedDiscussions:  post.relatedDiscussions?.indexOf(relatedPostId) === -1 ? post.relatedDiscussions.concat(relatedPostId) : [relatedPostId]
          }
        }
      };
    }
    default:
      return state;
  }
}