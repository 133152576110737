import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  useRouteMatch, 
  Link
} from "react-router-dom";
import { connect } from "react-redux";
import Navbar from '../../components/Navbar';
import TopNavbar from '../../components/TopNavbar';

import PageFooter from '../../components/PageFooter/PageFooter';
import HeroSection from '../../components/HeroSection';
import PartnerLogos from '../../components/PartnerLogos';
import HighlightsSection from '../../components/HighlightsSection';
import NewsSection from '../../components/NewsSection';
import SubscribeSection from '../../components/SubscribeSection';

import api from '../../adaptors/xhr/api';
import { addNews } from './ducks/actions';
import { getNews } from './ducks/selectors';
import { addHomeContent } from '../Common/ducks/actions';
import { getHomeContent } from '../Common/ducks/selectors';
import { HeroSectionMobile } from '../../components/HeroSection/HeroSectionMobile'

function CommunityGuidelines(props) {

  const fetchNews = async () => {
    const response = await api.News.list();
    response.data?.results?.map(news => {
      props.addNews(news)
    })
  }

  const fetchContent = async () => {
    const response = await api.Content.homepage();
    if(response.data)  props.addHomeContent(response.data);
  }

  useEffect(() => {
    fetchNews();
    fetchContent();
  }, [])

  return (
    <>
      <TopNavbar/>
      <Navbar />
      <div className="container-xl">
      <div className="mb-5 community-guidelines-container">
       <h3 className="my-5">Guidelines for Participating in the TRP Community</h3>

       <h5>Relevant content only</h5>
       <p className="p3">All content must relate to the TRP. Avoid unrelated 
       current affairs, memes, social issues, advertising or political posts. 
       Make sure your posts do not contain unprofessional or inappropriate content. </p>

       <h5>Only respond if necessary</h5>
       <p className="p3">Keep each post to have relevant comments, questions, or content. 
        Don't respond unless it is required/your comment adds value (e.g., don't join a sea of “thank you's”)</p>

       <h5>Privacy and confidentiality</h5>
       <p className="p3">Ensure that no confidential client information or photographs are shared. 
        Don't post content that invades someone's privacy or shares personal information of others.</p>

       <h5>Removal from the Community</h5>
       <p className="p3">the TRP Admin reserve the right to remove any member who 
       does not adhere to these guidelines. If there is an issue with 
       another member or post, please report it to the TRP Admin to handle, 
       at info@fptraining.org.</p>
       <p><i>Guidelines adapted from The Digital Law Co, by Emma Sadleir</i></p>
      </div>
      </div>

      <SubscribeSection/>
      <PageFooter nonav/>
    </>
    );
  }


const mapStateToProps = state => {
  const newsList = getNews(state);
  const homeContent = getHomeContent(state);
  return { newsList, homeContent };
};

export default connect(
  mapStateToProps,
  { addNews, addHomeContent }
)(CommunityGuidelines);