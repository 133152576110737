import classNames from 'classnames';


export default function Icon(props) {
  let className = classNames({
    "img-responsive": true,
    [props.className]: props.className ? true : false
  });

  return (
    <img src={props.icon} className={className} alt="action icon" width={props.width} height={props.height} />
  );
}