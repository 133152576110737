const LOGIN_USER = "common/duck/LOGIN_USER";
const LOGOUT_USER = "common/duck/LOGOUT_USER";
const ADD_NEWS = "common/duck/ADD_NEWS";
const ADD_GETTINGSTARTED = "common/duck/ADD_GETTINGSTARTED";
const ADD_CONTENT_ABOUT = "common/duck/ADD_CONTENT_ABOUT";
const ADD_CONTENT_HOME = "common/duck/ADD_CONTENT_HOME";
const ADD_SEARCH_TEXT = "common/duck/ADD_SEARCH_TEXT";
const ADD_SEARCH_RESULTS = "common/duck/ADD_SEARCH_RESULTS";
const ADD_COLOR_GUIDES = "common/duck/ADD_COLOR_GUIDES"
const ADD_NAVIGATING_THE_WEBSITE = "common/duck/ADD_NAVIGATING_THE_WEBSITE"

export default {
  LOGIN_USER,
  LOGOUT_USER,
  ADD_NEWS,
  ADD_GETTINGSTARTED,
  ADD_NAVIGATING_THE_WEBSITE,
  ADD_CONTENT_ABOUT,
  ADD_CONTENT_HOME,
  ADD_SEARCH_TEXT,
  ADD_SEARCH_RESULTS,
  ADD_COLOR_GUIDES,
};