import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import NavbarTabs from '../../components/NavbarTabs';
import PageContent from '../../components/PageContent';
import TrainingSection from '../../components/TrainingSection';
import FileAlert from '../../components/FileAlert';
import NumberedList from '../../components/NumberedList';
import TrainingSectionSmall from '../../components/TrainingSectionSmall';
import classNames from 'classnames';
import { 
  getModuleDetailService, 
  getModuleSessionPlan,
  getPresentationSlide,
  getEvaluationTools,
  getHandouts,
  getReferences
} from '../../adaptors/xhr/trainingService';
import { getModuleById } from './ducks/selectors';
import { addModule } from './ducks/actions';
import TrainingAccordian from '../../components/TrainingAccordian';
import TopNavbar from '../../components/TopNavbar';
import PageFooter from '../../components/PageFooter/PageFooter';
import api from '../../adaptors/xhr/api';
import { addGuide } from './ducks/actions';
import { getGuides } from './ducks/selectors';
import FilePreviewModal from '../../components/FilePreviewModal';


function Guides(props) {

  const [trainingGuidesCount, setTrainingGuidesCount] = useState(0)
  const [additionalLinksCount, setAdditionalLinksCount] = useState(0)
  const [trainingGuidesDesc, setTrainingGuidesDesc] = useState()
  const [additionalLinksDesc, setAdditionalLinksDesc] = useState()

  const [ previewModalShow, setPreviewModalShow ] = useState(false);
  const [ previewFileObj, setPreviewFileObj ] = useState(null);


  let { id } = useParams(); 
  const module = useSelector((state) => getModuleById(state, id));

  const guideSections = {
    generalTrainingGuides: 'general-training-guides',
    trainingGuides: 'training-guide',
    additionalLinks: 'additional-links',
    adaptationChecklists: 'adaptation-checklists'
  }

  const previewFile = (fileObj) => {
    setPreviewFileObj(fileObj)
    setPreviewModalShow(true);
  }

  const fetchDesc = async () => {
    const descData = await api.Guides.trainingGuidesDesc();
    setTrainingGuidesDesc(descData.data.training_guides_desc)
    setAdditionalLinksDesc(descData.data.additional_links_desc)
  }

  useEffect(() => {
    fetchDesc()
  }, [])

  const fetchGuides = async () => {
    // const generalTrainingResponse = await api.Guides.generalTrainingGuides();
    // generalTrainingResponse?.data?.results?.map(guide => props.addGuide(guideSections.generalTrainingGuides, guide))

    const trainingResponse = await api.Guides.trainingGuides();
    trainingResponse?.data?.results?.map(guide => props.addGuide(guideSections.trainingGuides, guide))
    setTrainingGuidesCount(trainingResponse.data.results.length)
    
    const additionalLinksResponse = await api.Guides.additionalLinks();
    additionalLinksResponse?.data?.results?.map(guide => props.addGuide(guideSections.additionalLinks, guide))
    setAdditionalLinksCount(additionalLinksResponse.data.results.length)

    // const adaptationChecklistsResponse = await api.Guides.adaptationChecklists();
    // adaptationChecklistsResponse?.data?.results?.map(guide => props.addGuide(guideSections.adaptationChecklists, guide))
    // setAdaptationChecklistsCount(adaptationChecklistsResponse.data.results.length)
  }

  useEffect(() => {

    // setModuleSessionPlan([
    //   {"id":1,"module":"Contraceptive Implants","title":"Quick Start Guide","details":"","file":"https://trp-storage-bucket.s3.amazonaws.com/uploads/Contraceptive_Implants-Training_Schedule.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS2HPUMRUM4UWHSPM%2F20210609%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210609T085355Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=122809b6804582e15b0270dce5585d15b85accf17c37cb86875429b5b450002b","file_size":0,"file_type":"PDF","created_at":"2021-05-17T16:29:53.149000Z","updated_at":"2021-05-31T09:24:11.742000Z"},
    //   {"id":2,"module":"Contraceptive Implants","title":"WHO Medical Eligibility Criteria (MEC) for Contraceptive Use","details":"","file":"https://trp-storage-bucket.s3.amazonaws.com/uploads/Contraceptive_Implants-Session_Plan_ooYukUf.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS2HPUMRUM4UWHSPM%2F20210609%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210609T085355Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=20958a3a8654cba994591ef92a7f84371a7d6c3917d667ce1952efd07586f138","file_size":0,"file_type":"PDF","created_at":"2021-05-17T16:29:53.150000Z","updated_at":"2021-06-02T08:11:08.824000Z"}
    // ])

    // setPresentationSlides([
    //   {"id":1,"module":"Contraceptive Implants","title":"General Facilitator's Guide","details":"","file":"https://trp-storage-bucket.s3.amazonaws.com/uploads/Contraceptive_Implants-Training_Schedule.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS2HPUMRUM4UWHSPM%2F20210609%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210609T085355Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=122809b6804582e15b0270dce5585d15b85accf17c37cb86875429b5b450002b","file_size":0,"file_type":"PDF","created_at":"2021-05-17T16:29:53.149000Z","updated_at":"2021-05-31T09:24:11.742000Z"},
    //   {"id":2,"module":"Contraceptive Implants","title":"Adult Learning","details":"","file":"https://trp-storage-bucket.s3.amazonaws.com/uploads/Contraceptive_Implants-Session_Plan_ooYukUf.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS2HPUMRUM4UWHSPM%2F20210609%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210609T085355Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=20958a3a8654cba994591ef92a7f84371a7d6c3917d667ce1952efd07586f138","file_size":0,"file_type":"PDF","created_at":"2021-05-17T16:29:53.150000Z","updated_at":"2021-06-02T08:11:08.824000Z"}
    // ])

    // setEvaluationTools([
    //   {"id":1,"module":"Contraceptive Implants","title":"Instructional Design Learning Resource Package (Jhpiego, 2012) ","details":"","file":"https://trp-storage-bucket.s3.amazonaws.com/uploads/Contraceptive_Implants-Training_Schedule.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS2HPUMRUM4UWHSPM%2F20210609%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210609T085355Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=122809b6804582e15b0270dce5585d15b85accf17c37cb86875429b5b450002b","file_size":0,"file_type":"PDF","created_at":"2021-05-17T16:29:53.149000Z","updated_at":"2021-05-31T09:24:11.742000Z"},
    //   {"id":2,"module":"Contraceptive Implants","title":"Training Works! (Jhpiego, Population Leadership Program, TRG, IntraHealth International, FHI)","details":"","file":"https://trp-storage-bucket.s3.amazonaws.com/uploads/Contraceptive_Implants-Session_Plan_ooYukUf.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS2HPUMRUM4UWHSPM%2F20210609%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210609T085355Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=20958a3a8654cba994591ef92a7f84371a7d6c3917d667ce1952efd07586f138","file_size":0,"file_type":"PDF","created_at":"2021-05-17T16:29:53.150000Z","updated_at":"2021-06-02T08:11:08.824000Z"}
    // ])

    // setHandouts([
    //   {"id":1,"module":"Contraceptive Implants","title":"Adaptation Checklist","details":"","file":"https://trp-storage-bucket.s3.amazonaws.com/uploads/Contraceptive_Implants-Training_Schedule.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAS2HPUMRUM4UWHSPM%2F20210609%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20210609T085355Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=122809b6804582e15b0270dce5585d15b85accf17c37cb86875429b5b450002b","file_size":0,"file_type":"PDF","created_at":"2021-05-17T16:29:53.149000Z","updated_at":"2021-05-31T09:24:11.742000Z"}
    // ])

    fetchGuides();

  }, []);


  // console.log(props.guides)
  
  return (
  <>
    <TopNavbar />
    <Navbar />
    {/* <Breadcrumb breadcrumbs={["Home", "Guides", module.name]}/> */}
    <PageHeader 
      lg 
      heading="Training Guides"
    >
      <p className="p2">
        Tools and tips for preparing to conduct a training.
      </p>
    </PageHeader>

    <div className="py-4 d-block d-sm-none">
      
      <TrainingAccordian>
        {/* <TrainingSectionSmall
          eventKey="0"
          title="General Training Guides"
          description="Familiarize yourself with these documents prior to the training:" 
          files={moduleSessionPlan || []}
        /> */}
        
        <TrainingSectionSmall
          eventKey="1"
          title="Training Guides"
          description={trainingGuidesDesc}
          files={props.guides || []}
          sectionId={guideSections.trainingGuides}
          count = {trainingGuidesCount}
          previewFile={previewFile}
        />
        
        <TrainingSectionSmall
          eventKey="2"
          title="Additional Links"
          description={additionalLinksDesc}
          files={props.guides || []}
          sectionId={guideSections.additionalLinks}
          count={additionalLinksCount}
          previewFile={previewFile}
        />

        {/* Remove Adaptation Checklist from Training Guides as it is already under Getting Started with TRP. */}
        {/* <TrainingSectionSmall
          eventKey="3"
          title="Adaptation Checklist"
          description="The module has a set of handouts with 
          specific information and key take away messages  
          and Job aids to support learning at workplace. 
          Handouts should be printed and distributed to the learners." 
          files={props.guides || []}
          sectionId={guideSections.adaptationChecklists}
          count={adaptationChecklistsCount}
          previewFile={previewFile}
        /> */}

      </TrainingAccordian>
    </div>
    


    {/* {----} */}

    {/* <div className="bg-grey py-5 d-none d-sm-block">
      <TrainingSection 
        title="General Training Guides"
        description="Familiarize yourself with these documents prior to the training:" 
        sectionId={guideSections.generalTrainingGuides}
        files={props.guides || []}
        previewFile={previewFile}
      />
    </div> */}
    <div className="py-5 d-none d-sm-block">
      <TrainingSection 
        title="Training Guides"
        description={trainingGuidesDesc}
        sectionId={guideSections.trainingGuides}
        files={props.guides || []}
        previewFile={previewFile}
      />
    </div>
    <div className="bg-grey py-5 d-none d-sm-block">
      <TrainingSection 
        title="Additional Links"
        description={additionalLinksDesc}
        sectionId={guideSections.additionalLinks}
        files={props.guides || []}
        previewFile={previewFile}
      />
    </div>

    {/* Remove Adaptation Checklist from Training Guides as it is already under Getting Started with TRP. */}
    {/* <div className="py-5 d-none d-sm-block">
      <TrainingSection 
        title="Adaptation Checklist"
        description="The TRP is made for use globally. You may need to adapt or customize the content to align  with your training landscapes, resources,  protocols and accessibility. " 
        sectionId={guideSections.adaptationChecklists}
        files={props.guides || []}
        previewFile={previewFile}
      />
    </div> */}

    <FilePreviewModal
      show={previewModalShow}
      onHide={() => setPreviewModalShow(false)}
      fileObj={previewFileObj}
    />

    <PageFooter/>
  </>
  );
}

const mapStateToProps = state => {
  const guides = getGuides(state);
  return { 
    guides, 
  };
};

export default connect(
  mapStateToProps,
  { addModule, addGuide }
)(Guides);