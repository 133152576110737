import types from "../types";

const initialState = {
  byId: {},
  allIds: []
};

export function frenchVideosReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_FRENCH_VIDEO: {
      const {
        videos,
        sectionId
      } = action.payload;
      return {
        ...state,
        allIds: Array.from(new Set(state.allIds.concat(sectionId))),
        byId: {
          ...state.byId,
          [sectionId]: videos
        }
      };
    }
    default:
      return state;
  }
}
