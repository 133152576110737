import { axiosInstance, axiosAuthenticate } from './xhrConfig';

// contributors
export function getContributors() {
    return axiosInstance.get('/api/community/contributors/')
  }

export function findDiscussion(text) {
  return axiosInstance.get('api/community/discussions/?search='+text)
}

const Forum = {

  topics: () =>
    axiosInstance.get(`/api/community/discussion-topics/`),
  discussions: (sortFilter, pageSize, page) =>
    axiosInstance.get(`/api/community/discussions/?sort=${sortFilter}&page_size=${pageSize}&page=${page}`),
  discussionsByTopic: (topicId, sortFilter, pageSize, page) =>
    axiosInstance.get(`/api/community/discussions/?category=${topicId}&sort=${sortFilter}&page_size=${pageSize}&page=${page}`),
  createPost: (category, title, body) =>
    axiosAuthenticate.post(`/api/community/discussions/`, {category, title, body}),
  updatePost: (id, category, title, body) =>
    axiosAuthenticate.put(`/api/community/discussion/${id}/`, {category, title, body}),
  deletePost: (id) =>
    axiosAuthenticate.delete(`/api/community/discussion/${id}/`),
  postById: (id) =>
    axiosInstance.get(`/api/community/discussion/${id}/`),
  postBySlug: (slug) =>
    axiosInstance.get(`/api/community/discussion/${slug}/`),
  comments: (discussionId) =>
    axiosInstance.get(`/api/community/discussion/${discussionId}/comments/`),
  postComment: (discussionId, comment) =>
    axiosAuthenticate.post(`/api/community/discussion/${discussionId}/comments/`, {comment}),
  updateComment: (commentId, comment) =>
    axiosAuthenticate.put(`/api/community/comment-details/${commentId}`, {comment}),
  deleteComment: (commentId) =>
    axiosAuthenticate.delete(`/api/community/comment-details/${commentId}`),
  reportAbuse: (post, comment, abuse_reported, content_target, message) =>
    axiosAuthenticate.post(`/api/community/report/`, { post, 
      comment, abuse_reported, content_target, message }),
  // useractivity: () =>
  //   axiosAuthenticate.get(`/api/community/activities/`),
  useractivityById: (userId) => axiosInstance.get(`api/community/activities/${userId}`),
  topContributors: () =>
    axiosInstance.get(`/api/community/contributors/`),
  weeklyContributors: () =>
    axiosInstance.get(`/api/community/contributors/?weekly`),
  findDiscussions: (searchText) =>
    axiosInstance.get(`/api/community/discussions/?search=${searchText}`),
};

export default Forum;
