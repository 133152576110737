import { act } from "@testing-library/react";
import { combineReducers } from "redux";
import types from "../types";

const contentInitialState = {
    gettingStarted: null,
    navigatingTheWebsite: null,
    about: null,
    home: null,
    colorGuides: {}
};


export function contentReducer(state = contentInitialState, action) {
  switch (action.type) {
    case types.ADD_GETTINGSTARTED: {
      const { data } = action.payload;
      return {
        ...state,
        gettingStarted: data
      };
    }
    case types.ADD_NAVIGATING_THE_WEBSITE: {
      const { data } = action.payload;
      return {
        ...state,
        navigatingTheWebsite: data
      };
    }
    case types.ADD_CONTENT_ABOUT: {
      const { data } = action.payload;
      return {
        ...state,
        about: data
      };
    }
    case types.ADD_CONTENT_HOME: {
      const { data } = action.payload;
      return {
        ...state,
        home: data
      };
    }
    case types.ADD_COLOR_GUIDES: {
      const { data } = action.payload;
      return {
        ...state,
        colorGuides: data
      }
    }
    default:
      return state;
  }

}