import { combineReducers } from "redux";
import training from "../../pages/Training/ducks/reducers/";
import faq from "../../pages/Faq/ducks/reducers/";
import common from "../../pages/Common/ducks/reducers/";
import community from "../../pages/Community/ducks/reducers/";

export default combineReducers({ 
  training,
  faq,
  common,
  community
});