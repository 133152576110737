import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from '../Button';
import FileList from '../FileList';
import SlidesBanner from '../SlidesBanner';
import ArrowIcon from '../../assets/icons/arrow.svg';
import DownloadIcon from '../../assets/icons/download.svg';

function  TrainingSectionSmall(props) {
  return (
    <Card>
      <Accordion.Toggle as={Card.Header} eventKey={props.eventKey}>
        <div className="accordion-button d-flex justify-content-between">
          <div>
          <span>{props.title}</span>
          <p className="subtext">{`${props.count || 0} Documents`}</p>
          </div>
          <div className="d-flex align-items-center">
            <img src={ArrowIcon} className="img-responsive" alt="arrow icon" width="16" height="13.4" />
          </div>
        </div>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={props.eventKey}>
        <Card.Body>
          <p className="p3 mx-0">{props.description}</p>
          {props.title == "Presentation slides" ? 
            <>
            <SlidesBanner showColorGuides={props.showColorGuides} className="my-4"/>
            {/* <Button 
              type="outline" 
              size="md" 
              text="Download All Slides"
              className="min-width-btn"
              icon={DownloadIcon}
            /> */}
            </>
            : null
          }
          {props.files?.filter(file => file.sectionId === props.sectionId && file.moduleId === props.moduleId).map(file => 
            <FileList 
              className=""
              title={file.title}
              description={file.detail}
              fileId={file.id}
              fileType={file.file_type}
              fileSize={file.size} 
              fileUrl={file.file}
              bookmarkItemsIds={props.bookmarkItemsIds}
              bookmarks={props.bookmarks}
              addBookmark={props.addBookmark}
              removeBookmark={props.removeBookmark}
              sectionId={file.sectionId}
              sessionfileId={file.sessionfileId}
              fileObj={file}
              previewFile={props.previewFile}
            />
          )}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default TrainingSectionSmall;