import React from 'react';
import {
  Link,
  useLocation
} from "react-router-dom";
import Button from '../Button';
import ButtonXs from '../ButtonXs';
import BookmarkButton from '../BookmarkButton';
import filenameFromUrl from '../../utils/filenameFromUrl';
import { download } from '../../adaptors/xhr/trainingService';
import DownloadIcon from '../../assets/icons/download.svg';
import BookmarkIcon from '../../assets/icons/bookmark.svg';
import { routes } from '../../routes';

function TrainingToolsList(props) {

  function openFile() {
    // window.open(props.file.file, "_blank");
    props.previewFile(props.file);
  }

  function downloadFile() {
    const fileName = filenameFromUrl(props.file.file)
    download(props.file.file, fileName);
  }

  return (
      
        
        <li href="#" className="list-group-item list-group-item-action active" aria-current="true">
          <div className="d-flex w-100 justify-content-between align-items-center">
            
            <div>
              <p onClick={openFile} className="additional-tools-link p3 mb-1 title">{props.file.title}</p>
              <p className="p4 mb-1 d-none d-md-block">{props.file.details}</p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Button 
                type="outline" 
                size="sm" text="Download" 
                icon={DownloadIcon} 
                onClick={downloadFile} 
                hideOnXs
              />
              <ButtonXs type="outline" size="sm" text="View" className="mx-2" onClick={openFile} icon={DownloadIcon} />
            </div>
          </div>
        </li>
  );
}

export default TrainingToolsList;