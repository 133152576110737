import React, { useEffect, useState } from 'react';
import {connect, useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from '../../components/Button';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import SittingIllustration from '../../assets/images/sitting.svg';
import FormInput from '../FormInput';
import FormInputLarge from '../FormInputLarge';
import FormSelect from '../FormSelect';
import api from '../../adaptors/xhr/api';
import { loginUser } from '../../pages/Common/ducks/actions';
import { loginSchema } from '../../pages/Common/ducks/validations';
import LocalStorageService from '../../adaptors/storage/localStorageService';
import { routes } from '../../routes';

const localStorageService = LocalStorageService.getService();

function LoginModal(props) {

  const dispatch = useDispatch();
  const [ nonFieldErrors, setNonFieldErrors ] = useState([]);

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, resetForm}) => {
    setSubmitting(true);
    const { email, password } = values;
    try {
      // try login user
      const response = await api.Auth.login(email, password);
      if (response.status === 200) {
        // login success
        localStorageService.setToken(response.data);
        dispatch(loginUser(response.data.user));
        props.onHide();
      }
    }
    catch (error) {
      // read server validation errors
      if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      console.error(error);
    }
    setSubmitting(false);
  }


  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="login-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-start align-items-start title-section">
          <h4 className="mx-auto">Log in to continue</h4>
        </div>
        {nonFieldErrors.length ? 
        <Alert variant="danger" className="mt-3">
          {nonFieldErrors?.map((error, index) => `${error}`)}
        </Alert>
        : null
        }
        <Formik
          validationSchema={loginSchema}
          onSubmit={submitHandle}
          initialValues={{
            email: '',
            password: '',
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
          <Form className="form" noValidate onSubmit={handleSubmit}>
          <FormInput 
            type="email"
            name="email"
            label="Email Address" 
            value={values.email} 
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder=""
            // isValid={touched.email && !errors.email}
            isInvalid={touched.email && !!errors.email}
            feedback={errors.email}
            required
          />
           <FormInput 
            type="password"
            label="Password"
            name="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder=""
            // isValid={touched.password && !errors.password}
            isInvalid={touched.password && !!errors.password}
            feedback={errors.password}
            required
          />
          <Button 
            type="primary" 
            size="lg"  
            text={isSubmitting ? "Please wait..." : "Continue"} 
            submit 
            disabled={isSubmitting}  
          />
        </Form>
          )}
        </Formik>
        <p className="p4 text-center my-4"><Link to={routes.resetPassword} className="">Forgot your password?</Link></p>
      </Modal.Body>
      <Modal.Footer>
        <div className="row">
          <div className="col px-0">
            <p className="p4 text-center">
              Don't have an account? <Link to={routes.signup}>Sign up</Link>.
            </p>
          </div>
        </div>
        
       
      </Modal.Footer>
    </Modal>
  );
}

export default LoginModal;