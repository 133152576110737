import { axiosInstance, axiosAuthenticate } from './xhrConfig';

const Auth = {

  login: (email, password) =>
    axiosInstance.post(`/api/auth/login/`, { email, password }),

  register: (firstName, lastName, email, password) => {
    const json = JSON.stringify({
      username: email,
      email: email,
      first_name: firstName,
      last_name: lastName,
      password1: password,
      password2: password
    });
    return axiosInstance.post(`/api/auth/registration/`, json);
  },

  registerResendEmail: (email) => {
    const json = JSON.stringify({
      email: email,
    });
    return axiosInstance.post(`/api/auth/registration/resend-email/`, json);
  },

  verifyToken: (token) =>
    axiosInstance.post(`/api/auth/token/verify/`, { token }),

  refreshToken: (refresh) =>
    axiosInstance.post(`/api/auth/token/refresh/`, { refresh }),

  user: () =>
    axiosAuthenticate.get('/api/auth/user/'),
  updateUserPreference: ({emailCommunityResponse, emailNewModule, emailOccasionalCommunication}) => {
    const formData = new FormData();
    console.log("community", emailCommunityResponse,emailNewModule,emailOccasionalCommunication);
    if(typeof emailCommunityResponse !== `undefined`){
      formData.append('email_community_responses', emailCommunityResponse)
       return axiosAuthenticate.patch('/api/auth/user/', formData);
    }
    if(typeof emailNewModule  !== `undefined`){
      formData.append('email_new_module', emailNewModule)
       return axiosAuthenticate.patch('/api/auth/user/', formData);
    }
    if(typeof emailOccasionalCommunication  !== `undefined`){
      formData.append('email_occassional_updates', emailOccasionalCommunication)
       return axiosAuthenticate.patch('/api/auth/user/', formData);
    }
   
  },

  updateUser: (firstName, lastName, profilePhoto, timezone, bio, linkedinUrl) => {
    console.log('profilePhoto', profilePhoto)
    let formData = new FormData();
    if (profilePhoto != null)
      formData.append('profile_picture', profilePhoto);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('timezone', timezone);
    formData.append('bio', bio);
    formData.append('linkedin_url', linkedinUrl)
    // const data = {
    //   first_name: firstName,
    //   last_name: lastName,
    //   profile_picture: profilePhoto
    // }
    return axiosAuthenticate.patch('/api/auth/user/', formData);
  },

  resetPassword: (email) =>
    axiosInstance.post(`/api/auth/password/reset/`, { email }),

  // resetPasswordConfirm: (new_password1, new_password2, uid, token) =>
  //   axiosInstance.post(`/user/reset-password/confirm/${uid}/${token}/`, { new_password1, new_password2, uid, token }),
  resetPasswordConfirm: (new_password1, new_password2, uid, token) =>
    axiosInstance.post(`/api/auth/password/reset/confirm/`, { new_password1, new_password2, uid, token }),
   userProfile: (userId) => {
    return axiosInstance(`api/user/${userId}`)
   }

};

export default Auth;