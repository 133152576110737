
/** Topics */
export const getTopicsState = store => store.community.topics;

export const getTopicsList = store =>
  getTopicsState(store) ? getTopicsState(store).allIds : [];

export const getTopicById = (store, id) =>
  getTopicsState(store) ? { ...getTopicsState(store).byId[id], id } : {};

export const getTopics = store =>
  getTopicsList(store).map(id => getTopicById(store, id));


/** Forum Posts */
export const getPostsState = store => store.community.posts;

export const getPostsList = store =>
  getPostsState(store) ? getPostsState(store).allIds : [];

export const getPostById = (store, id) =>
  getPostsState(store) ? { ...getPostsState(store).byIds[id], id } : {};

export const getPosts = store =>
  getPostsList(store).map(id => getPostById(store, id));


/** Topic filter */
export const getTopicFilter = store => store.community.posts.topicFilter;

/** Posts sort filter */
export const getSortFilter = store => store.community.posts.sortFilter;

/** Posts search filter */
export const getSearchFilter = store => store.community.posts.searchFilter;

/** Comments */
export const getCommentsState = store => store.community.comments;

export const getCommentsList = store =>
  getCommentsState(store) ? getCommentsState(store).allIds : [];

export const getCommentsById = (store, id) =>
  getCommentsState(store) ? { ...getCommentsState(store).byId[id], id } : {};

export const getComments = store =>
  getCommentsList(store).map(id => getCommentsById(store, id));

export const getCommentsByIds = (store, commentIdList) =>
  commentIdList?.map(id => getCommentsById(store, id));


/** users selector*/
export const getUserstate = store => store.community.users;

export const getUsersList = store =>
  getUserstate(store) ? getUserstate(store).allIds : [];

export const getUserById = (store, id) =>
  getUserstate(store) ? { ...getUserstate(store).byId[id], id } : {};

/** activities selector*/
export const getActivitiesState = store => store.community.activities;

export const getActivitiesList = store =>
  getActivitiesState(store) ? getActivitiesState(store).allIds : [];

export const getActivityById = (store, id) =>
  getActivitiesState(store) ? { ...getActivitiesState(store).byId[id], id } : {};

export const getActivities = store =>
  getActivitiesList(store).map(id => getActivityById(store, id));

export const getActivitiesListByUserId = (store, userId) => 
  getActivitiesState(store).byUserId[userId] ? getActivitiesState(store).byUserId[userId] : [];

export const getActivitiesByUserId = (store, userId) =>
  getActivitiesListByUserId(store, userId)?.map(id => getActivityById(store, id));

export const getFilteredDiscussionIds = (store) => 
  store.community.posts.discussionFilter;

export const getFilteredDiscussion = (store,id) => 
  getPostById(store, id)

export const getActivitiesPost = (store) => {
  let post = []
  Object.values(store.community.activities.byId).map((activity) => {
    if(activity.title){
      post = [...post,activity]
    }
  })
  return post
}

export const getActivitiesComment = (store) => {
  let comment = []
  Object.values(store.community.activities.byId).map((activity) => {
    if(activity.comment){
      comment = [...comment,activity]
    }
  })
  return comment
}

/** Contributors */
export const getContributorsState = store => store.community.contributors;

export const getContributorsList = store =>
  getContributorsState(store) ? getContributorsState(store).allIds : [];

export const getContributorById = (store, id) =>
  getContributorsState(store) ? { ...getContributorsState(store).byId[id], id } : {};

export const getContributors = store =>
  getContributorsList(store).map(id => getContributorById(store, id));

export const getRelatedDiscussions = (store, postId) =>
  getPostById(store, postId)?.relatedDiscussions?.map(id => getPostById(store, id));