import Auth from './authService';
import Forum from './communityService';
import News from './newsService';
import Subscription from './subscriptionService';
import Bookmarks from './bookmarksService';
import Guides from './guidesService';
import { Training } from './trainingService';
import Content from './contentService';


export default {
  Auth,
  Forum,
  News,
  Subscription,
  Bookmarks,
  Guides,
  Training,
  Content
};