import api from '../../../adaptors/xhr/api';
import { loginUser, logoutUser } from './actions';
import LocalStorageService from '../../../adaptors/storage/localStorageService';

const localStorageService = LocalStorageService.getService();

export const getProfileFetch = () => {
  return async dispatch => {
    // const token = localStorage.token;
    const token = localStorageService.getAccessToken();
    if (token) {
      console.log("Verifying token")
      try {
        const response = await api.Auth.verifyToken(token);
        if (response.status === 200) {
          // token verified successfully
          // get user 
          const user = await api.Auth.user();
          dispatch(loginUser(user.data))
        }
      }
      catch (error) {
        // refresh token
        try {
          console.log(">>>REFRESH TOKEN")
          const refresh = localStorageService.getRefreshToken();
          const response = await api.Auth.refreshToken(refresh);
          if (response.status === 200) {
            // set new token
            localStorage.setItem("token", response.data.access);
            const user = await api.Auth.user();
            dispatch(loginUser(user.data))
          }
          else {
            console.log("Refresh Token Expired")
            // auth failure; clear tokens
            localStorageService.clearToken();
            dispatch(logoutUser())
          }
        }
        catch (error) {
          console.log("Refresh Token Expired")
          localStorageService.clearToken();
          dispatch(logoutUser())
        }
        
      }
    }
  }
}