import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  withRouter
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { passwordResetSchema } from './ducks/validations';
import Alert from 'react-bootstrap/Alert';
import TopNavbar from '../../components/TopNavbar';
import FormInput from '../../components/FormInput';
import FormInputLarge from '../../components/FormInputLarge';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';
import AuthPageHolder from '../../components/AuthPageHolder';
import EmailVerify from './EmailVerify';
import PaswordResetSuccess from './PaswordResetSuccess';
import api from '../../adaptors/xhr/api';
import { routes } from '../../routes';


function ResetPasswordConfirm(props) {

  const { uid, token } = useParams();
  console.log("PARAMS", uid, token)

  const [ passwordChanged, setPasswordChanged ] = useState();
  const [ emailSent, setEmailSent ] = useState();
  const [ nonFieldErrors, setNonFieldErrors ] = useState([]);

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, resetForm}) => {
    setSubmitting(true);
    const { password1, password2 } = values;
    try {
      const response = await api.Auth.resetPasswordConfirm(password1, password2, uid, token);
      if (response.status === 200) {
        console.log(response.data);
        setPasswordChanged(true);
        // props.history.push('verify-email')
      }
    }
    catch (error) {
      console.error(error);
      // read server validation errors
      if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      
    }
    setSubmitting(false);
  }

  if(passwordChanged) {
    return <PaswordResetSuccess email={emailSent} />
  }

  return (
  <>
  <AuthPageHolder>
    <div className="auth-page h-100 w-100 mx-auto">
      <div className="content mx-auto">
        <h4 className="text-center">Reset your password</h4>
        <div className="section-middle">
          <p className="p2">
            Fill in your new password.
          </p>
          <Formik
            validationSchema={passwordResetSchema}
            onSubmit={submitHandle}
            initialValues={{
              password1: null,
              password2: null
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form className="form" noValidate onSubmit={handleSubmit}>
                <FormInput 
                  type="password"
                  name="password1"
                  label="Password"
                  value={values.password1} 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder=""
                  // isValid={touched.email && !errors.email}
                  isInvalid={touched.password1 && !!errors.password1}
                  feedback={errors.password1}
                  required
                  help="Use at least 8 characters including a number and a uppercase letter."
                />
                <FormInput 
                  type="password"
                  name="password2"
                  label="Confirm Password"
                  value={values.password2} 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder=""
                  // isValid={touched.email && !errors.email}
                  isInvalid={touched.password2 && !!errors.password2}
                  feedback={errors.password2}
                  required
                  // help="Use at least 8 characters including a number and a uppercase letter."
                />
                <Button 
                  size="lg" 
                  type="primary" 
                  // text="Send password reset email" 
                  // onClick={() => props.history.push('verify-email')} 
                  text={isSubmitting ? "Please wait..." : "Change Password"} 
                  submit 
                  disabled={isSubmitting}  
                />
                <div className="frame text-center">
                  <p className="p4"><Link to={routes.login}>Back to Log in</Link></p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  </AuthPageHolder>
  </>
  );
}

const mapStateToProps = state => {
  return { };
};

export default connect(
  mapStateToProps,
  {  }
)(withRouter(ResetPasswordConfirm));