import React from 'react';
import Nav from 'react-bootstrap/Nav';
import classNames from 'classnames';

function FilterNav(props) {

  let className = classNames({
    'filter-nav': true,
    [props.className]: props.className ? true : false
  });

  return (

    <div className={className}>
      <p className="c1 title">Filter Modules by category</p>
      <Nav 
        defaultActiveKey={props.activeKey || "all"}
        className="flex-column module-nav"
        onSelect={(selectedKey) => props.onSelect(selectedKey)}>
          <Nav.Link eventKey="all">All Modules</Nav.Link>
          {props.filters?.map(f => <Nav.Link key={`filter-${f.id}`} eventKey={f.id}>{f.name}</Nav.Link> )}
      </Nav>
    </div>
  );
}

export default FilterNav;