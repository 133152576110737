import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import EmptyuserIcon from '../../assets/icons/empty-user-image.svg'



export default class ProfilePhotoPreview extends React.Component {

  className = classNames({
    'user-thumb ': true,
    [this.props.className]: this.props.className ? true : false
  });

  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) { return; }

    this.setState({ loading: true }, () => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) { return null; }

    if (loading) { return <p>loading...</p>; }

    return (
    // <img src={thumb}
    //   alt={file.name}
    //   className="img-thumbnail mt-2"
    //   height={200}
    //   width={200} />
      

    <div  className={this.className}>
      <img  src={thumb} alt={file.name} className="img-responsive btn-icon" width={this.props.width || "32"} height={this.props.height || "32"} />
    </div>
      
      );
  }
}





// export default function ProfilePhotoPreview(props) {
//   let className = classNames({
//     'user-thumb ': true,
//     [props.className]: props.className ? true : false
//   });

//   return (
//     <div  className={className}>
//       <img src={props.thumbnail || EmptyuserIcon} className="img-responsive btn-icon" alt="user thumbnail" width={props.width || "32"} height={props.height || "32"} />
//     </div>
//   );
// }