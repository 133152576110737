//Reducer for handeling categories of the modules in training
import { combineReducers } from "redux";
import types from "../types";


const categoryInitialState = {
  byIds: {},
  allIds: [],
  categoryFilter: null
};


export function moduleCategoryReducer(state = categoryInitialState, action) {
  switch (action.type) {
    case types.ADD_MODULE_CATEGORY: {
      const { id, data } = action.payload;
      return {
        ...state,
        allIds: Array.from(new Set([...state.allIds, id])),
        byIds: {
          ...state.byIds,
          [id]: {
            ...data
          }
        }
      };
    }
    // filtering
    case types.SET_MODULE_CATEGORY_FILTER: {
      const { category } = action.payload
      return {
        ...state,
        categoryFilter: category
      }
    }

    default:
      return state;
  }
}
