import types from "../types";
import { combineReducers } from "redux";

function newsById(state = {}, action) {
  switch (action.type) {
    case types.ADD_NEWS: {
      const { payload } = action
      const { newsId, newsObj } = payload
      return {
        ...state,
        [newsId]: newsObj
      }
    }
      
    default:
      return state
  }
}

function allNews(state = [], action) {
  switch (action.type) {
    case types.ADD_NEWS: {
      const { payload } = action
      const { newsId } = payload
      return Array.from(new Set(state.concat(newsId)))
    }
    default:
      return state
  }
}

const newsReducer = combineReducers({
  byId: newsById,
  allIds: allNews
})

export default newsReducer;