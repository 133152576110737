import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  withRouter
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import { subscribeSchema } from './ducks/validations';
import Alert from 'react-bootstrap/Alert';
import TopNavbar from '../../components/TopNavbar';
import FormInput from '../../components/FormInput';
import FormInputLarge from '../../components/FormInputLarge';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';
import AuthPageHolder from '../../components/AuthPageHolder';
import EmailVerify from './EmailVerify';
import api from '../../adaptors/xhr/api';




function ResetPassword(props) {

  const [ emailSent, setEmailSent ] = useState();
  const [ nonFieldErrors, setNonFieldErrors ] = useState([]);

  async function sendEmail(email) {
    try {
      const response = await api.Auth.resetPassword(email);
      if (response.status === 200) {
        console.log(response.data);
        setEmailSent(email);
        // props.history.push('verify-email')
      }
    }
    catch (error) {
      // read server validation errors
      if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      console.error(error);
    }
  }

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, resetForm}) => {
    setSubmitting(true);
    const { email } = values;
    sendEmail(email);
    // try {
    //   const response = await api.Auth.resetPassword(email);
    //   if (response.status === 200) {
    //     console.log(response.data);
    //     setEmailSent(values.email);
    //     // props.history.push('verify-email')
    //   }
    // }
    // catch (error) {
    //   // read server validation errors
    //   if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
    //   console.error(error);
    // }
    setSubmitting(false);
  }

  if(emailSent) {
    return <EmailVerify email={emailSent} type="Password reset link" resendEmail={sendEmail}  />
  }

  return (
  <>
  <AuthPageHolder>
    <div className="auth-page h-100 w-100 mx-auto">
      <div className="content mx-auto">
        <h4 className="text-center">Reset your password</h4>
        <div className="section-middle">
          <p className="p2">
            Fill in your registered email and we’ll send you a link to reset your password.
          </p>
          <Formik
            validationSchema={subscribeSchema}
            onSubmit={submitHandle}
            initialValues={{
              email: '',
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form className="form" noValidate onSubmit={handleSubmit}>
                <FormInput 
                  type="email"
                  name="email"
                  label="Email Address" 
                  value={values.email} 
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder=""
                  // isValid={touched.email && !errors.email}
                  isInvalid={touched.email && !!errors.email}
                  feedback={errors.email}
                  required
                />
                <Button 
                  size="lg" 
                  type="primary" 
                  // text="Send password reset email" 
                  // onClick={() => props.history.push('verify-email')} 
                  text={isSubmitting ? "Please wait..." : "Send password reset email"} 
                  submit 
                  disabled={isSubmitting}  
                />
                <div className="frame text-center">
                  <p className="p4"><Link to="login">Back to Log in</Link></p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  </AuthPageHolder>
  </>
  );
}

const mapStateToProps = state => {
  return { };
};

export default connect(
  mapStateToProps,
  {  }
)(withRouter(ResetPassword));