import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {LinkContainer} from 'react-router-bootstrap';

export default function AuthPageHolder(props) {
  useEffect(() => {
    // document.body.style.backgroundColor = '#E9EBED';
  }, []);

  return (
    <>
    <Link className="auth-page-brand" to="/"></Link>
    {props.children}
    </>
    )
}
