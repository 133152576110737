import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../Button';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import SittingIllustration from '../../assets/images/sitting.svg';
import FormInput from '../FormInput';
import FormInputLarge from '../FormInputLarge';
import FormSelect from '../FormSelect';
import { discussionSchema, validateDiscussion, validateDiscussionBody } from '../../pages/Community/ducks/validations';
import api from '../../adaptors/xhr/api';
import { routes } from '../../routes';
import ReactQuill from 'react-quill';
import LinkIcon from '../../assets/icons/link.svg';
import AddLinkModal from '../AddLinkModal/AddLinkModal';

function PostActionsModal({ quillRef, linkHandler, topics, onActionComplete, actionType, postContent, ...props}) {

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, resetForm}) => {
    setSubmitting(true);
    const { topic, title, body } = values;
    console.log(topic, title, body);
    try {
      if (actionType === "Create") {
        // create user
        var response = await api.Forum.createPost(topic, title, body);
        if (response.status === 201) {
          // succesfully created post
          // setEmailSent(values.email);

          // close modal
          onActionComplete(response.data);
        }
      } else {
        var response = await api.Forum.updatePost(postContent?.id, topic, title, body);
        if (response.status === 201) {
          // succesfully created post
          // setEmailSent(values.email);

          // close modal
          onActionComplete(response.data);
        }
      }

      resetForm();
      props.onHide();
    }
    catch (error) {
      // read server validation errors
      // if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      console.error(error);
    }
    setSubmitting(false);
  }

  useEffect(() => {
    var icons = ReactQuill.Quill.import('ui/icons');
    icons['link'] = "<p>Add Link <img src=" + LinkIcon + "></p>";
  }, []);

  return (
    <>
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="community-guidelines-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Formik
            // validationSchema={discussionSchema}
            validate={validateDiscussion}
            onSubmit={submitHandle}
            initialValues={{
              topic: postContent?.topic,
              title: postContent?.title,
              body: postContent?.body,
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form className="form" noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="d-flex justify-content-start align-items-start title-section">
                  <h4>{`${actionType === "Create" ? "Create A New" : "Edit"} Post`}</h4>
                </div>

                <FormSelect
                  name="topic"
                  label="Select Topic"
                  placeholder="Add title for your post..."
                  value={values.topic}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // isValid={touched.topic && !errors.topic}
                  isInvalid={touched.topic && !!errors.topic}
                  feedback={errors.topic}
                  required
                  options={topics}
                />
                <FormInput
                  name="title"
                  label={`${actionType === "Create" ? "" : "Edit "}Title (enter upto 250 characters)`}
                  placeholder="Add title for your post..."
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // isValid={touched.title && !errors.title}
                  isInvalid={touched.title && !!errors.title}
                  feedback={errors.title}
                  required
                />
                <FormInputLarge
                  name="body"
                  label={`${actionType === "Create" ? "Add more" : "Edit"} details`}
                  placeholder="Type your comment..."
                  value={values.body}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // isValid={touched.body && !errors.body}
                  isInvalid={touched.body && !!errors.body}
                  feedback={errors.body}
                  formType={"Post"}
                  linkHandler={linkHandler}
                  quillRef={quillRef}
                />

                <p className="p3 mb-4">
                  While making new posts, please follow <a href='https://docs.google.com/document/d/1SJbCMd-iGfVJvciNJl-fOxPyoMRYlElF5IzXC2XcdPc/edit?usp=sharing' target="_blank" rel="noreferrer">community guidelines</a>
                </p>
              </Modal.Body>
              <Modal.Footer>
                <div className="row">
                  <div className="col px-0 d-flex align-items-between justify-content-center">
                    <Button size="lg"  onClick={props.onHide} text='Cancel' className="mr-2"/>
                    <Button
                      type="primary"
                      size="lg"
                      text={
                        isSubmitting ?
                          actionType === "Create" ? "Creating Post..." : "Saving..." :
                          actionType === "Create" ? "Submit Post" : "Save"
                      }
                      submit
                    />
                  </div>
                </div>


              </Modal.Footer>
          </Form>
          )}
        </Formik>
      </Modal>
    </div>

    </>
  );
}

export default PostActionsModal;
