import React from 'react';
import {
  withRouter
} from "react-router-dom";
import classNames from 'classnames';
import PresentationSlidesImage from '../../assets/images/presentation_slides.png';
import Button from '../Button';
import { routes } from '../../routes';



function SectionCard(props) {
  let className = classNames({
    "section-card": true,
    "alert d-md-flex justify-content-between align-items-center": true,
    [props.className]: props.className ? true : false
  });
  return (
    <div className={className}>
      <h4>
        Looking for further help or have an idea to share? 
        Join TRP community
      </h4>
      <Button 
        type="primary" 
        size="lg" 
        text="Explore Community" 
        className="ml-0 ml-lg-5 mt-3 mt-lg-0"
        onClick={() => props.history.push(routes.discussions)}
      />
    </div>
  );
}

export default withRouter(SectionCard);