import React from 'react';
import classNames from 'classnames';


function PageContent(props) {
  let className = classNames({
    'container-xl page-content': true,
    [props.className]: props.className ? true : false
  });
  return (
    <div>
      <div className={className}>
        {props.children}
      </div>
    </div>
  );
}

export default PageContent;