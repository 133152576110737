import React from 'react';
import Accordion from 'react-bootstrap/Accordion';

function FaqAccordian(props) {
  return (
    <div className="container-xl">
      <div className="row faq-accordion">
        <h5 className="mb-4">{props.section}</h5>
        <Accordion 
          className="w-100"
        >
          {props.children}
        </Accordion>

      </div>
    </div>
  );
}

export default FaqAccordian;