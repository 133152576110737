import React from 'react';
import {
  Link,
  useLocation
} from "react-router-dom";
import filesize from 'filesize';
import Button from '../Button';
import ButtonXs from '../ButtonXs';
import BookmarkButton from '../BookmarkButton';
import filenameFromUrl from '../../utils/filenameFromUrl';
import { download } from '../../adaptors/xhr/trainingService';
import DownloadIcon from '../../assets/icons/download.svg';
import BookmarkIcon from '../../assets/icons/bookmark.svg';
import { routes } from '../../routes';

function FileList(props) {
  const fileUrl = props.fileObj.preview_file? props.fileObj.preview_file: props.fileObj.url? props.fileObj.url: props.fileObj.file
  const file_type = props.fileObj.preview_file? "PDF Document": props.fileObj.file_type;
  let fileType = "";
        console.log("filelist",props )
  switch(props.fileType) {
    case "Word Document":
      fileType = "Word Doc";
      break;
    case "PDF Document":
      fileType = "PDF";
      break;
    case "Presentation Slide":
      fileType = "PPT"
      break;
    default:
      fileType = "Document"
      break
  }

  const location = useLocation();  
  console.log("PATH", location.pathname)

  function openFile() {
    console.log('openFile');
    if (props.fileObj?.path_type == "URL" ) 
      window.open(props.fileObj?.url, "_blank");
    else
      // props.previewFile({file: fileUrl, size: props.fileObj.size, title: props.fileObj.title, file_type: file_type, sourceFile: props.fileObj.file, sourceFileType: props.fileObj.file_type})
      props.previewFile(props.fileObj);

  }

  function downloadFile() {
    const fileName = filenameFromUrl(props.fileUrl)
    download(props.fileUrl, fileName);
  }

  return (
      <ul className="session-file-list list-group list-group-flush d-flex flex-row justify-content-start align-items-start">
        {location.pathname !== routes.trainingGuides && location.pathname !== routes.searchResults
          ? <BookmarkButton 
              bookmarked={props.bookmarkItemsIds?.includes(props.sessionfileId)}   
              addBookmark={() => props.addBookmark(props.sectionId, props.fileId, props.fileObj)}
              removeBookmark={() => props.removeBookmark(props.sectionId, props.fileId, props.fileObj)}
              className="d-none d-sm-block"
            />
          : null
        }
        
        <li href="#" className="list-group-item list-group-item-action active" aria-current="true">
          <div className="d-flex w-100 justify-content-between align-items-center">
            
            <div>
              <a href="#" onClick={openFile}><p className="p3 mb-1 title">{props.title}</p></a>
              <p className="p4 mb-1 d-none d-md-block">{props.description}</p>
              {props.fileObj?.path_type == "FILE" 
              ? <>
                <small className="s1">{fileType}</small>
                <small className="s1"> • </small>
                <small className="s1">{`${props.fileSize && !isNaN(props.fileSize) ? filesize(props.fileSize) : ''}`}</small>
                </>
              : <small className="s1">Reference Link</small>

              }
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Button 
                size="sm" 
                text="View" 
                className="mx-2 mr-1" 
                onClick={openFile} 
                hideOnXs
              />
              
              {props.fileObj?.file 
                ? <Button 
                  type="outline" 
                  size="sm" text="Download" 
                  icon={DownloadIcon} 
                  onClick={downloadFile} 
                  hideOnXs
                />
                : null
              }
              {/* <ButtonXs size="sm" text="View" className="mx-2" onClick={openFile} icon={BookmarkIcon} /> */}
              {location.pathname != routes.trainingGuides && location.pathname !== routes.searchResults
                ? <BookmarkButton 
                    bookmarked={props.bookmarkItemsIds?.includes(props.sessionfileId)}   
                    addBookmark={() => props.addBookmark(props.sectionId, props.fileId, props.fileObj)}
                    removeBookmark={() => props.removeBookmark(props.sectionId, props.fileId, props.fileObj)}
                    className="d-block d-sm-none"
                  />
                : null
              }
              {props.fileObj?.path_type == "FILE"  && props.fileObj?.file 
                ? <ButtonXs type="outline" size="sm" text="View" className="mx-2" onClick={downloadFile} icon={DownloadIcon} />
                : null
              }
            </div>
          </div>
        </li>
      </ul>
  );
}

export default FileList;