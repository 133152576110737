import * as Yup from 'yup';


export const discussionSchema = Yup.object().shape({
  topic: Yup.string()
  .required("Topic is required"),

  title: Yup.string()
  .min(5, "Title must have at least 5 characters")
  .max(250, "Title can't be longer than 250 characters")
  .required("Title is required"),

  body: Yup.string()
  // .min(50, "Detail must have at least 50 characters")
  // .max(600, "Detail can't be longer than 600 characters")
  .required("Detail is required"),

});

export const validateDiscussion = (values, props) => {
  const errors = {};

  if (!values.topic) {
    errors.topic = "Topic is required";
  }

  if (!values.title) {
    errors.title = "Title is required";
  } else if (values.title.length > 250) {
    errors.title = "Title can't be longer than 250 characters";
  } else if (values.title.length < 5) {
    errors.title = "Title must have at least 5 characters";
  }

  if (!values.body) {
    errors.body = "Detail is required";
  } else if (calculateWords(values.body) < 10) {
    errors.body = "Detail must have at least 10 words";
  } else if (calculateWords(values.body) > 200) {
    errors.body = "Detail cannot exceed 200 words in length";
  }

  return errors;
};

export const commentSchema = Yup.object().shape({
  comment: Yup.string()
  .min(5, "Comment must have at least 5 characters")
  .max(200, "Comment can't be longer than 200 characters")
  .required("Comment cannot be empty"),
});

export const calculateWords = (text) => {
  let words = text.trim().split(" ");

  // return length of words if non-empty
  return words.length === 1 ?
    words[0] === "" ? 0 : words.length
    : words.length;
}

export const validateComment = (values, props /* only available when using withFormik */) => {
  const errors = {};
  if (!values.comment) {
    errors.comment = "Comment cannot be empty";
  } else if (calculateWords(values.comment) > 200) {
    errors.comment = "Comment cannot exceed 200 words in length";
  }
  return errors;
};

export const reportSchema = Yup.object().shape({
  abuseType: Yup.string().required("Violation type is required"),
  message: Yup.string()
  .min(5, "Comment must have at least 5 characters")
  .max(200, "Comment can't be longer than 200 characters")
  // .required("Comment cannot be empty"),
});
