import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Navbar from '../../components/Navbar';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import NavbarTabs from '../../components/NavbarTabs';
import PageContent from '../../components/PageContent';
import DownloadSection from '../../components/DownloadSection';
import FileAlert from '../../components/FileAlert';
import NumberedList from '../../components/NumberedList';
import classNames from 'classnames';
import {
  getModuleDetailService,
  getModuleSessionPlan,
  getPresentationSlide,
  getEvaluationTools,
  getHandouts,
  getReferences,
  getDownloads,
  downloadFrenchMaterials,
  getFrenchMaterialVideosBySectionId
} from '../../adaptors/xhr/trainingService';
import {
  getModuleById,
  getDownloadsBySearchSortFilter,
  getFrenchSections,
  getFrenchContent,
  getFrenchVideos,
} from './ducks/selectors';
import {
  addModule,
  addDownload,
  setSortFilter,
  setSearchFilter,
  addBookmarkFolder,
  addBookmarkItem,
  addBookmarkContext,
  addSessionFile,
  removeBookmarkItem,
  addFrenchSection,
  addFrenchContent,
  addFrenchVideos
} from './ducks/actions';
import { addColorGuides } from '../Common/ducks/actions';
import { getLoginState, getColorGuidesContent } from '../Common/ducks/selectors';
import { session_types, getBookmarksFolders, getBookmarksFlat, getBookmarkItems,
  getBookmarkItemsList, getBookmarkContextState, getSessionpreps, getFrenchContentBySearchFilter,
} from './ducks/selectors';
import api from '../../adaptors/xhr/api';
import SearchBar from '../../components/SearchBar';
import FilterDropdown from '../../components/FilterDropdown/FilterDropdown';
import Button from '../../components/Button';
import Alert from '../../components/Alert';
import DownloadIcon from '../../assets/icons/download-white.svg';
import SearchEmpty from '../../components/SearchEmpty/SearchEmpty';
import BookmarkModal from '../../components/BookmarkModal';
import TopNavbar from '../../components/TopNavbar';
import {DOWNLOADS_SORT_FILTERS} from "../../constants";
import PageFooter from '../../components/PageFooter/PageFooter';
import FilePreviewModal from '../../components/FilePreviewModal';
import { routes } from '../../routes';
import LoginModal from '../../components/LoginModal';
import SlidesBanner from '../../components/SlidesBanner';
import colorGuide from '../../adaptors/xhr/colorGuideService';
import ColorGuide from '../../components/ColorGuide';
import VideoBlockFrench from '../../components/VideoBlockFrench/VideoBlockFrench';
import VideoBlockSmall from '../../components/VideoBlockSmall';
import { Accordion } from 'react-bootstrap';

function FrenchMaterials(props) {
  console.log('props', props)
  const [modalShow, setModalShow] = useState(false);
  const [ previewModalShow, setPreviewModalShow ] = useState(false);
  const [ previewFileObj, setPreviewFileObj ] = useState(null);
  const [ loginShow, setLoginShow ] = useState(false);
  const [ colorGuideShow, setColorGuideShow ] = useState(false);

  let { id } = useParams();
  const module = useSelector((state) => getModuleById(state, id));

  const previewFile = (fileObj) => {
    console.log("previewFile", fileObj)
    setPreviewFileObj(fileObj)
    setPreviewModalShow(true);
  }

  const fetchColorGuide = async () => {
    const response = await colorGuide.frenchContent();
    if(response.data) {
      props.addColorGuides(response.data);
    }
  }

   const showColorGuides = () => {
    setColorGuideShow(true)
  }

  const fetchBookmarks = async () => {
    const response = await api.Bookmarks.folders();
    response.data?.map(bookmark => {
      props.addBookmarkFolder(bookmark);
      // fetch modules
      bookmark.modules?.map(item => {
        props.addBookmarkItem(bookmark.id, `module-${item.id}`, item, "modules");
      })

      // fetch module_session_plans
      bookmark.module_session_plans?.map(item => {
        props.addBookmarkItem(bookmark.id, `module_session_plan-${item.module.id}-${item.id}`, item, "module_session_plans");
      })

      // fetch presentation_slides
      bookmark.presentation_slides?.map(item => {
        props.addBookmarkItem(bookmark.id, `presentation_slides-${item.module.id}-${item.id}`, item, "presentation_slides");
      })

      // fetch evaluation_tools
      bookmark.evaluation_tools?.map(item => {
        props.addBookmarkItem(bookmark.id, `evaluation_tools-${item.module.id}-${item.id}`, item, "evaluation_tools");
      })

      // fetch references
      bookmark.references?.map(item => {
        props.addBookmarkItem(bookmark.id, `references-${item.module.id}-${item.id}`, item, "references");
      })

      // fetch handouts
      bookmark.handouts?.map(item => {
        props.addBookmarkItem(bookmark.id, `handouts-${item.module.id}-${item.id}`, item, "handouts");
      })

    })
  }

  const fetchContent = async () => {
    const response = await api.Training.frenchMaterials();
    if (response.status === 200) {
      response.data.map(async (section) => {
        props.addFrenchSection(section);
        section.content.map(contentItem => {
          props.addFrenchContent(contentItem, section.id);
        })
        const resp = await getFrenchMaterialVideosBySectionId(section.id);
        if (resp.status === 200) {
          console.log(section.id, resp.data);
          props.addFrenchVideos(resp.data, section.id);
        }
      })
    }
  }

  useEffect(() => {

    // getModuleDetailService(id)
    // .then(response => {
    //   console.log('Success:', response.data);
    //   props.addModule(response.data);
    // })
    // .catch((error) => {
    //   console.error('Error:', error);
    // })

    // getModuleSessionPlan(id)
    // .then(response => {
    //   response.data.results.map(sessionfile => {
    //     props.addSessionFile(id, "module_session_plan", `module_session_plan-${id}-${sessionfile.id}`, sessionfile)
    //   })
    //   // setModuleSessionPlanCount(response.data.results.length)
    // })
    // .catch((error) => {
    //   console.error('Error:', error);
    // })

    // getPresentationSlide(id)
    // .then(response => {
    //   response.data?.results?.map(sessionfile => props.addSessionFile(id, "presentation_slides", `presentation_slides-${id}-${sessionfile.id}`, sessionfile))
    //   // setPresentationSlidesCount(response.data.results.length)
    // })
    // .catch((error) => {
    //   console.error('Error:', error);
    // })

    // getEvaluationTools(id)
    // .then(response => {
    //   response.data?.results?.map(sessionfile =>
    //     props.addSessionFile(id, "evaluation_tools", `evaluation_tools-${id}-${sessionfile.id}`, sessionfile))
    //     // setEvaluationToolsCount(response.data.results.length)
    // })
    // .catch((error) => {
    //   console.error('Error:', error);
    // })

    // getHandouts(id)
    // .then(response => {
    //   response.data?.results?.map(sessionfile =>
    //     props.addSessionFile(id, "handouts", `handouts-${id}-${sessionfile.id}`, sessionfile))
    //     // setHandoutsCount(response.data.results.length)
    // })
    // .catch((error) => {
    //   console.error('Error:', error);
    // })

    // getReferences(id)
    // .then(response => {
    //   response.data?.results?.map(sessionfile =>
    //     props.addSessionFile(id, "references", `references-${id}-${sessionfile.id}`, sessionfile))
    //     // setReferencesCount(response.data.results.length)
    // })
    // .catch((error) => {
    //   console.error('Error:', error);
    // })

    // fetchBookmarks();

    // if (props.downloads && props.downloads.length === 0) {
    //   getDownloads(id)
    //   .then(response => {
    //     console.log('Success:', response.data);
    //     response.data?.modulesessionplan_set?.map(file => {
    //       props.addDownload(file, session_types.module_session_plans);
    //     })
    //     response.data?.reference_set?.map(file => {
    //       props.addDownload(file, session_types.references);
    //     })
    //     response.data?.handout_set?.map(file => {
    //       props.addDownload(file, session_types.handouts);
    //     })
    //     response.data?.evaluationtool_set?.map(file => {
    //       props.addDownload(file, session_types.evaluation_tools);
    //     })
    //     response.data?.presentationslide_set?.map(file => {
    //       props.addDownload(file, session_types.presentation_slides);
    //     })
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   })
    // }


    // getModuleDetailService(id)
    // .then(response => {
    //   console.log('Success:', response.data);
    //   props.addModule(response.data);
    // })
    // .catch((error) => {
    //   console.error('Error:', error);
    // })
    clearSearch();
    fetchContent();
    fetchColorGuide()

  }, [])

  const bookmarks = [
      {
        "id": 11,
        "title": "Basic Slides - Implants Session I: Characteristics of Implants",
        "file": "url//",
        "file_type": "Word docs",
        "file_size": 45,
      }
    ]

  function setFilter(filter) {
    props.setSortFilter(filter);
  }

  function searchDownloads(text) {
    props.setSearchFilter(text);
  }

  function clearSearch() {
    props.setSearchFilter("");
  }

  const addBookmark = (section, fileId, fileObj) => {

    if (props.currentUser.email) {
      props.addBookmarkContext(section, fileId, fileObj);
      setModalShow(true);
    }
    else
      setLoginShow(true);

  }

  const removeBookmark = async (section, fileId, fileObj) => {
    props.addBookmarkContext(section, fileId, fileObj);
    // props.removeBookmarkItem(fileObj.folderId, fileObj.itemId, fileObj);
    const response = await api.Bookmarks.removebookmarkedItem(
      // folderSelected,
      props.bookmarkContext.sectionType,
      props.bookmarkContext.fileId
    )
    if (response.status === 200) {
      props.onHide();
      // props.bookmarkSuccess(folderSelected, props.bookmarkContext.fileObj.sessionfileId, props.bookmarkContext.fileObj);
    }
  }


  return (
  <>
    <TopNavbar />
    <Navbar nosticky />
    {/* <Breadcrumb breadcrumbs={["Home", "Training", module.name]} links={["/", "/training", "/"]}/> */}
    <PageHeader heading="Matériaux en Français"/>
    {/* <NavbarTabs/> */}
    <PageContent>
      <div className="row other-language-materials">
        <div className="col">
          <p className="p3">
          Nous cherchons à rendre le maximum du contenu des modules disponibles en français,
          mais seuls certains modules récemment mis à jour sont complets (par exemple, les DIU hormonaux, les implants ou la méthode à jours fixes).
          Pour les autres, vous ne trouverez que les diapositives ou quelques aspects.
          Nous vous invitons à nous contacter pour nous indiquer les modules prioritaires pour lesquels nous devrions compléter la traduction,
          sur <a href='mailto:info@fptraining.org'>info@fptraining.org.</a>
          </p>
        </div>
        <div className="col-sm-4">
          <SlidesBanner fr showColorGuides={showColorGuides} className="my-4"/>
        </div>
      </div>

      <div className="row">

        <div className="col-xs-12 col-md-6">
          <SearchBar
            className="w-100"
            placeholder="Search by file name or type..."
            onChange={searchDownloads}
            value={props.searchFilter}
          />
        </div>

        <div className="offset-lg-2 col mt-2 mt-md-0 d-flex justify-content-sm-end align-items-center">
          <div className="d-none d-md-block" >
          {/* <FilterDropdown
            onSelect={setFilter}
            className="mr-2"
            sortFilters={DOWNLOADS_SORT_FILTERS}
            activeFilter={props.sortFilter}
          /> */}
          </div>
          <div className="d-block d-md-none w-100" >
          {/* <FilterDropdown
            onSelect={setFilter}
            className="mr-2"
            sortFilters={DOWNLOADS_SORT_FILTERS}
            activeFilter={props.sortFilter}
          /> */}
          </div>
          <div className="" >
          <Button
            type="primary"
            size="md"
            text="Download All files"
            icon={DownloadIcon}
            className="min-width-btn"
            minWidth
            onClick={() => downloadFrenchMaterials()}
          />

          </div>
        </div>

      </div>
      <div className="bpy-5">
      <div className="downloads-section mt-4">
      <div className="col d-none d-md-block">
        <li className="row header d-flex align-items-center">
          <div className="col-md-7 mr-5 pl-0">
            <span className="">File Name</span>
          </div>
          <div className="col-md-2 ml-4">
            <span className="filetype">File Type</span>
          </div>
          <div className="col-md-1 ml-4">
            <span className="filesize">Size</span>
          </div>
          <div className="col-md-1">
              {/* <BookmarkButton  /> */}
            {/* <ButtonXs
              type="outline"
              size="sm"
              text="Download"
              icon={DownloadIcon}
              // onClick={downloadFile}
              noHide
            /> */}
          </div>
        </li>
        </div>

      </div>

      {/* {props.bookmarks.length
        ? <DownloadSection
          title="Bookmarked"
          files={props.bookmarks}
          previewFile={previewFile}
        />
        : null
      } */}

      {props.frenchSections && props.frenchSections.length && props.frenchContent.length
        ? props.frenchSections.map(section =>
          <div className="mb-5">
            <DownloadSection
            title={section.sectionName}
            // className="mb-5"
            // Avoid listing reference urls
            files={props.frenchContent.filter(file => file.sectionId === section.id)}
            previewFile={previewFile}
            // onBookmark={() => setModalShow(true)}
            // bookmarkItemsIds={props.bookmarkItemsIds}
            // addBookmark={addBookmark}
            // removeBookmark={removeBookmark}
            noBookmark={true}
          />
          { props.frenchVideos && props.frenchVideos[section.id] && props.frenchVideos[section.id].length > 0 ?
            <div id="videos" className='d-none d-sm-block mt-4'>
            <VideoBlockFrench videoData={
              props.frenchVideos[section.id]
            } videoDecs={""} id="videos"/>
          </div> : null}

          { props.frenchVideos && props.frenchVideos[section.id] && props.frenchVideos[section.id].length > 0 ?
            <Accordion className='w-100 materials-video-block-small'>
              <div className="d-block d-sm-none">
                <VideoBlockSmall
                  moduleId={section.id}
                  eventKey="5"
                  title="Videos"
                  sectionId="videos"
                  description=""
                  videoData={props.frenchVideos[section.id]}
                />
              </div>
            </Accordion>: null}
          </div>
        )
        : null
      }

      {props.frenchContent && props.frenchContent.length === 0 && props.searchFilter
        ? <SearchEmpty clearSearch={clearSearch} />
        : null
      }



    </div>
    <BookmarkModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      folders={props.bookmarksFolders}
      bookmarkContext={props.bookmarkContext}
      // bookmarkInfo={{sessionType: bookmarksessionType, itemId: bookmarkItemId}}
      onCreatedFolder={props.addBookmarkFolder}
      bookmarkSuccess={props.addBookmarkItem} // action
    />

  <ColorGuide
      show={colorGuideShow}
      onHide={() => setColorGuideShow(false)}
      content={props.colorGuide}
    />

    <FilePreviewModal
      show={previewModalShow}
      onHide={() => setPreviewModalShow(false)}
      fileObj={previewFileObj}
    />

    <LoginModal
      show={loginShow}
      onHide={() => setLoginShow(false)}
    />

    </PageContent>
    <PageFooter/>
  </>
  );
}

const mapStateToProps = state => {
  const sessionprepfiles = getSessionpreps(state);
  const sortFilter = state.training.sortFilter;
  const searchFilter = state.training.searchFilter;
  const downloads = getDownloadsBySearchSortFilter(state, sortFilter, searchFilter);
  const bookmarkContext = getBookmarkContextState(state);
  const bookmarkItemsIds = getBookmarkItemsList(state);
  const bookmarksFolders = getBookmarksFolders(state);
  const bookmarks = getBookmarkItems(state);
  const currentUser = getLoginState(state);
  const frenchSections = getFrenchSections(state);
  const frenchContent = getFrenchContentBySearchFilter(state, searchFilter);
  const frenchVideos = getFrenchVideos(state);
  const colorGuide = getColorGuidesContent(state);
  return { currentUser, searchFilter, sortFilter, downloads,
    bookmarksFolders, bookmarkItemsIds, bookmarkContext, bookmarks, sessionprepfiles,
    frenchSections, frenchContent, colorGuide, frenchVideos };
};

export default connect(
  mapStateToProps,
  { addModule,
    addSessionFile,
    addDownload,
    setSortFilter,
    setSearchFilter,
    addBookmarkFolder,
    addBookmarkContext,
    addBookmarkItem,
    removeBookmarkItem,
    addFrenchSection,
    addFrenchContent,
    addFrenchVideos,
    addColorGuides,
  }
)(FrenchMaterials);
