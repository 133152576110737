import { combineReducers } from "redux";
import { moduleReducer } from "./modules";
import { moduleCategoryReducer } from "./moduleCategories";
import { moduleLearningObjectiveReducer } from './moduleLearningObjectives';
import { downloadReducer } from "./downloads";
import { sortFilter } from "./sortFilter";
import { searchFilter } from "./searchFilter";
import bookmarksFolderReducer from './bookmarkFolders';
import bookmarkItemsReducer from './bookmarkItems';
import bookmarkFolderFilter from './bookmarkFolderFilter';
import { bookmarkContextReducer } from './bookmarkContext';
import { sessionprepSectionsReducer } from "./sessionprepSections";
import sessionFilesReducer from './sessionprepFiles';
import guidesReducer from './guides';
import toolsReducer from './tools';
import { frenchsectionsReducer } from './frenchsections';
import { frenchContentReducer } from './frenchcontent';
import { videoReducer } from "./video";
import { spanishContentReducer } from "./spanishcontent";
import { spanishSectionsReducer } from "./spanishsections";
import { frenchVideosReducer } from "./frenchvideos";
import { spanishVideosReducer } from "./spanishvideos";


export default combineReducers( {
  modules: moduleReducer,
  moduleCategories: moduleCategoryReducer,
  moduleLearningObjective : moduleLearningObjectiveReducer,
  downloads: downloadReducer,
  sortFilter: sortFilter,
  searchFilter: searchFilter,
  bookmarkFolders: bookmarksFolderReducer,
  bookmarkItems: bookmarkItemsReducer,
  bookmarkContext: bookmarkContextReducer,
  bookmarkFolderFilter: bookmarkFolderFilter,
  sessionprepSections: sessionprepSectionsReducer,
  sessionprepFiles: sessionFilesReducer,
  guides: guidesReducer,
  tools: toolsReducer,
  frenchSections: frenchsectionsReducer,
  frenchContent: frenchContentReducer,
  frenchVideos: frenchVideosReducer,
  videoContent: videoReducer,
  spanishSection: spanishSectionsReducer,
  spanishContent: spanishContentReducer,
  spanishVideos: spanishVideosReducer,
  frenchVideos: frenchVideosReducer
});
