import types from "./types";

export const addSection = section => ({
  type: types.ADD_SECTION,
  payload: {
    id: section.id,
    section: section
  }
});

export const addEntry = (entry, sectionId) => ({
  type: types.ADD_ENTRY,
  payload: {
    id: entry.id,
    entry: entry,
    sectionId: sectionId
  }
});

export const addSectionEntryRef = (sectionId, entryId) => ({
  type: types.ADD_SECTION_ENTRY_REF,
  payload: {
    id: sectionId,
    entryId: entryId,
  }
});

// export const setSortFilter = filter => ({ type: types.SET_SORT_FILTER, payload: { filter } });

// export const setSearchFilter = filter => ({ type: types.SET_SEARCH_FILTER, payload: { filter } });
