import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../components/Button';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import classNames from 'classnames';
import SittingIllustration from '../../assets/images/sitting.svg';
import SlidesGuide1 from '../../assets/images/slides-guide-1.svg'
import SlidesGuide2 from '../../assets/images/slides-guide-2.svg'
import SlidesGuide3 from '../../assets/images/slides-guide-3.svg'
import TrainingToolsList from '../TrainingToolsList';

function ColorGuide(props) {
  console.log(props)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="color-guide-training-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
       
          <div className="row title-section">
            <div className="col">
              <h4>{props.content?.title}</h4>
              <p className="p3">{props.content?.body}
              </p>
            </div>
          </div>

          {props.content?.color_guide_items?.map((item) => {
           return (
              <div className=" d-flex justify-content-between align-items-top mb-4">
            <div className="pr-3">
              <h5>{item.title}</h5>
              <p className="p3">{item.body}</p>
            </div>
            <div className="">
              <img src={item.image} className="color-guide-image px-0 mx-0"/>
            </div>
          </div>
           )
          })}

          

      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  );
}

export default ColorGuide;