import React from 'react';
import classNames from 'classnames';
import Button from '../../components/Button';
import ButtonXs from '../ButtonXs';
import BookmarkIcon from '../../assets/icons/bookmark.svg';
import BookmarkIconFill from '../../assets/icons/bookmark-fill.svg';


function BookmarkButtonRegular(props) {

  let className = classNames({
    'bookmark-btn mx-2': true,
    [props.className]: props.className ? true : false
  });

  return (
    <div className={className}>
    <Button 
      type="outline"
      size="md"
      text="Bookmark" 
      icon={props.bookmarked ? BookmarkIconFill : BookmarkIcon} 
      onClick={props.bookmarked ? props.removeBookmark : props.addBookmark}
    />
    </div>
  );
}

export default BookmarkButtonRegular;