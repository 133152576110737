import { DOWNLOADS_SORT_FILTERS } from "../../../constants";

export const getLoginState = store => store.common.auth.currentUser;




/** News */
export const getNewsState = store => store.common.news;

export const getNewsList = store =>
  getNewsState(store) ? getNewsState(store).allIds : [];

export const getNewsById = (store, id) =>
  getNewsState(store) ? { ...getNewsState(store).byId[id], id } : {};

export const getNews = store =>
  getNewsList(store).map(id => getNewsById(store, id));


/** Content */
export const getContentState = store => store.common.content;

export const getGettingStartedContent = store =>
  getContentState(store) ? getContentState(store).gettingStarted : {};

export const getNavigatingTheWebsiteContent = store =>
  getContentState(store) ? getContentState(store).navigatingTheWebsite : {};

export const getAboutContent = store =>
  getContentState(store) ? getContentState(store).about : {};

export const getHomeContent = store =>
  getContentState(store) ? getContentState(store).home : {};

export const getColorGuidesContent = store =>
  getContentState(store) ? getContentState(store).colorGuides : {};


/** Search */
export const getSearchState = store => store.common.search;

export const getSearchResultsRaw = store =>
  getSearchState(store) ? getSearchState(store).results : {};

export const getSearchResults = (store, sortFilter) => {
  console.log("Sorting>>>")
  const defaultResults = getSearchResultsRaw(store);

  switch (sortFilter) {
    case DOWNLOADS_SORT_FILTERS.FILESIZE:
      return {
        modules: defaultResults?.modules,
        resources: defaultResults?.resources?.sort((a,b) => (a.size > b.size) ? 1 : ((b.size > a.size) ? -1 : 0))
      } 
    case DOWNLOADS_SORT_FILTERS.FILEFORMAT:
      return {
        modules: defaultResults?.modules,
        resources: defaultResults?.resources?.sort((a,b) => (a.file_type > b.file_type) ? 1 : ((b.file_type > a.file_type) ? -1 : 0))
      } 
    case DOWNLOADS_SORT_FILTERS.DEFAULT:
      return {
        modules: defaultResults?.modules,
        resources: defaultResults?.resources?.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
      }
    default:
      return defaultResults;
  }
};

export const getSearchText = store =>
  getSearchState(store) ? getSearchState(store).text : "";

/** News */
export const getIsExpandedState = store => store.common.isExpanded;
export const getIsExpanded = store =>
  getIsExpandedState(store) ? getIsExpandedState(store).value : false;