import { combineReducers } from "redux";
import types from "../types";

const moduleInitialState = {
    byIds: {},
    allIds: []
};


export function moduleReducer(state = moduleInitialState, action) {
  switch (action.type) {
    case types.ADD_MODULE: {
      const { id, data, categoryId } = action.payload;
      return {
        ...state,
        allIds: Array.from(new Set([...state.allIds, id])),
        byIds: {
          ...state.byIds,
          [id]: {
            ...state.byIds[id],
            ...data,
            categoryId: categoryId,
            learningObjectivesId: []
          }
        }
      };
    }
    case types.SET_LEARNING_OBJECTIVES_IN_MODULE: {
      const learningObjectivesId = action.payload.data
      const moduleId = action.payload.moduleId
      return {
        allIds: [
          ...state.allIds
        ],
        byIds: {
          ...state.byIds,
          [moduleId]:{
            ...state.byIds[moduleId],
            learningObjectivesId: [...state.byIds[moduleId].learningObjectivesId, learningObjectivesId]
          }
          
        }
      };
    }

    case types.ADD_SESSION_FILE: {
      const { payload } = action
      const { moduleId, sectionId, sessionfileId, sessionfile } = payload;
      // Look up the correct module
      const module = state.byIds[moduleId];
      module[sectionId] = module.hasOwnProperty(sectionId) ? Array.from(new Set([...module[sectionId], sessionfileId])) : [sessionfileId]
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [moduleId]: {
            moduleId: moduleId,
            ...module,
          }
        }
      };
    }
    case types.ADD_MODULE_DISCUSSION_ID: {
      const { moduleId, id } = action.payload
      console.log('id', id, 'moduleid', moduleId)
      return {
        ...state,
        byIds: {
          ...state.byIds,
          [moduleId]:{
            ...state.byIds[moduleId],
            relatedDiscussionsId: state.byIds[moduleId].hasOwnProperty('relatedDiscussionsId') ? Array.from(new Set([...state.byIds[moduleId].relatedDiscussionsId, id])) : [id]
          }
        }
      }
    }

    default:
      return state;
  }

}