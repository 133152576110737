import classNames from 'classnames';
import SearchEmptyIcon from '../../assets/icons/search-empty.svg';


export default function SearchEmpty(props) {
  const { message = "Sorry, we couldn’t find any file for your search.", actionLabel="Clear Search" } = props;
  let className = classNames({
    "search-empty-alert": true,
    "d-flex flex-column justify-content-between align-items-center": true,
    [props.className]: props.className ? true : false
  });

  return (
    <div className={className}>
      <img src={SearchEmptyIcon} className="img-responsive" alt="search empty icon" />
      <p className="p3">{message}</p>
      {!props.noAction ? <p className="p3"><a href="#" onClick={props.clearSearch}>{actionLabel}</a></p> : null }
    </div>
  );
}