import types from "../types";

const initialState = {
    byId: {},
    allIds: []
};

export function frenchsectionsReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_FRENCH_SECTION: {
      const { id, sectionName } = action.payload;
      return {
        ...state,
        allIds: Array.from(new Set(state.allIds.concat(id))),
        byId: {
          ...state.byId,
          [id]: {
            id,
            sectionName
          }
        }
      };
    }
    // case types.ADD_SECTION_ENTRY_REF: {
    //   const { id, entryId } = action.payload;
    //   return {
    //     ...state,
    //     // allIds: [...state.allIds, id],
    //     byIds: {
    //       ...state.byIds,
    //       [id]: {
    //         ...state.byIds[id],
    //         entries: [...state.byIds[id]['entries'] || [], entryId]
    //       }
    //     }
    //   };
    // }
    default:
      return state;
  }
}