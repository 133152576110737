import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  withRouter
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import * as yup from 'yup';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import TopNavbar from '../../components/TopNavbar';
import FormInput from '../../components/FormInput';
import FormInputLarge from '../../components/FormInputLarge';
import FormSelect from '../../components/FormSelect';
import Button from '../../components/Button';
import AuthPageHolder from '../../components/AuthPageHolder';
import api from '../../adaptors/xhr/api';
import { loginUser } from './ducks/actions';
import { loginSchema } from './ducks/validations';
import { routes } from '../../routes';
import { getLoginState } from './ducks/selectors';
import { getProfileFetch } from './ducks/operations';
import LocalStorageService from '../../adaptors/storage/localStorageService';

const localStorageService = LocalStorageService.getService();



function Login(props) {

  const [ nonFieldErrors, setNonFieldErrors ] = useState([]);

  // useEffect(() => {
  //   props.getProfileFetch();
  // }, []);
  

  // `Formik` form submit handle
  const submitHandle = async (values, {setSubmitting, resetForm}) => {
    setSubmitting(true);
    const { email, password } = values;
    try {
      // try login user
      const response = await api.Auth.login(email, password);
      if (response.status === 200) {
        // login success
        console.log(response.data)
        localStorageService.setToken(response.data);
        props.loginUser(response.data.user);
      }
    }
    catch (error) {
      // read server validation errors
      if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors)
      else setNonFieldErrors(["Sorry. An error has occured."])
      console.error(error);
    }
    setSubmitting(false);
  }

  if (props.userObj?.email) {
    return (
      <Redirect to={`/community/user/${props.userObj?.pk}`} />
    )
  }


  return (
  <>
  <AuthPageHolder>
    <div className="auth-page h-100 w-100 mx-auto">
      <div className="content mx-auto">
        <h4 className="text-center">Log in to your account</h4>
        <div className="section-middle">
        {nonFieldErrors.length ? 
        <Alert variant="danger" className="mt-3">
          {nonFieldErrors?.map((error, index) => `${error}`)}
        </Alert>
        : null
        }
        <Formik
          validationSchema={loginSchema}
          onSubmit={submitHandle}
          initialValues={{
            email: '',
            password: '',
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
          <Form className="form" noValidate onSubmit={handleSubmit}>
            <FormInput 
              type="email"
              name="email"
              label="Email Address" 
              value={values.email} 
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder=""
              // isValid={touched.email && !errors.email}
              isInvalid={touched.email && !!errors.email}
              feedback={errors.email}
              required
            />
            <FormInput 
              type="password"
              label="Password"
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder=""
              // isValid={touched.password && !errors.password}
              isInvalid={touched.password && !!errors.password}
              feedback={errors.password}
              required
            />
            
            <Button 
              size="lg" 
              type="primary" 
              text={isSubmitting ? "Please wait..." : "Log in"} 
              submit 
              disabled={isSubmitting}  
            />
          </Form>
          )}
          </Formik>
          
          <p className="p4 my-4 text-center"><Link to="reset-password">Forgot password?</Link></p>
          <div className="frame text-center">
            <p className="p4">Don’t have an account? <Link to="signup">Sign up here</Link></p>
          </div>
        </div>
      </div>
    </div>
    </AuthPageHolder>
  </>
  );
}

const mapStateToProps = state => {
  const userObj = getLoginState(state);
  return { userObj };
};

export default connect(
  mapStateToProps,
  { loginUser, getProfileFetch }
)(withRouter(Login));