import React from 'react';
import Button from '../Button';
import FileList from '../FileList';
import SlidesBanner from '../SlidesBanner';
import DownloadIcon from '../../assets/icons/download.svg';

function TrainingSection(props) {
  // console.log('props props.files', props.files)
  // const module = useSelector((state) => getModuleById(state, id));
  return (
    <div className="container-xl">
      <div className="row training-section">
        <div className="col-md-4 pr-lg-5">
          <h4>{props.title}</h4>
          <p className="p3 mx-0">{props.description}</p>
            {props.title === "Presentation slides" ? 
              <>
              <SlidesBanner showColorGuides={props.showColorGuides} className="my-4"/>
              <Button 
                type="outline" 
                size="md" 
                text="Download All Slides"
                className="min-width-btn"
                icon={DownloadIcon}
                onClick={() => props.downloadPresentationSlides(props.moduleId)}
              />
              </>
              : null
            }
        </div>
        <div className="col pl-lg-4">
          {props.files?.filter(file => file.sectionId === props.sectionId && file.moduleId === props.moduleId).map(file => 
            <FileList 
              className=""
              title={file.title}
              description={file.details}
              fileId={file.id}
              fileType={file.file_type}
              fileSize={file.size}
              fileUrl={file.file}
              bookmarkItemsIds={props.bookmarkItemsIds}
              bookmarks={props.bookmarks}
              addBookmark={props.addBookmark}
              removeBookmark={props.removeBookmark}
              sectionId={file.sectionId}
              sessionfileId={file.sessionfileId}
              fileObj={file}
              previewFile={props.previewFile}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TrainingSection;