import React, { useEffect, useRef, useState } from 'react';
import { PopupButton } from '@typeform/embed-react'
import FeedbackIcon from "../../assets/icons/feedback-white.png";
import { useHistory } from 'react-router-dom';

function FeedbackButton(props) {
  const history = useHistory();
  const ref = useRef();
  const [lastLocation, setLastLocation] = useState('');
  const [visitCounter, setVisitCounter] = useState(1);
  const [feedbackShown, setFeedbackShown] = useState(false);

  const lGetItem = (name) => {
    return JSON.parse(localStorage.getItem(name))
  }
  const lSetItem = (name, state) => {
    localStorage.setItem(name, JSON.stringify(state))
  }

  const openPopup = () => {
    console.log("feedback: opening popup")
    ref.current?.open();
  }

  const unlisten = history.listen((location, action) => {
    if (visitCounter > 3 || feedbackShown || lGetItem('userCompletedSurvey') === 'true') {
      unlisten()
    } else {
      if (location.pathname !== lastLocation) {
        setVisitCounter(visitCounter + 1);
        if (visitCounter === 3) {
          setTimeout(() => {
              openPopup();
          }, 500);
          setFeedbackShown(true);
          unlisten();
        }
      }
      setLastLocation(location.pathname)
      unlisten();
    }
    console.log("feedback: visits: ", visitCounter)
  })

  // useEffect(() => {
  //   lSetItem('visitCounter', parseInt(lGetItem('visitCounter')) || 0)
  // }, [])

  return (
    <PopupButton
      id="lIQ1mnl8"
      className="tf-popup-button"
      onSubmit={() => {lSetItem('userCompletedSurvey', 'true')}}
      ref={ref}
    >
      <img src={FeedbackIcon} style={{ height: '22px', transform: 'rotate(90deg)' }} />
      <span style={{paddingLeft: '6px'}}>Feedback</span>
    </PopupButton>
  );
}

export default FeedbackButton;
