import { combineReducers } from "redux";
import { authReducer } from "./auth";
import newsReducer from './news';
import { contentReducer } from './content';
import searchReducer from './search';

const initialState = { value: false }; 

function isExpandedReducer(state = initialState, action) {
  if (action.type == "IS_EXPANDED_REDUCER/TOGGLE") {
    return {
      ...state,
      value: !state.value,
    }
  }
  return state
}

export default combineReducers({
  auth: authReducer,
  news: newsReducer,
  content: contentReducer,
  search: searchReducer,
  isExpanded: isExpandedReducer,
});