import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {LinkContainer} from 'react-router-bootstrap';
import classNames from 'classnames';
import { routes } from '../../routes';
// import modal
import ContactModal from '../ContactModal';
import { useState } from 'react';

export default function TopNavbar(props) {
  // define state for toggle contact modal
  const [contactShow, setContactShow] = useState(false);
  let className = classNames({
    "top-navbar d-none d-lg-block": true,
    "sticky-div": props.sticky ? true : false,
    [props.className]: props.className ? true : false
  });

// funcion for onclick, set state val to true here
const handleContactClick = () => {
    setContactShow(true);
}


  return (
    <>
      <Navbar className={className} expand="lg" bg="light" variant="light">
        <div className="container-xl">
          <Nav className="ml-auto">
            <LinkContainer to={routes.spanishMaterials}>
              <Nav.Link eventKey="spanishMaterials">Materiales en Español</Nav.Link>
            </LinkContainer>
            <LinkContainer to={routes.frenchMaterials}>
              <Nav.Link eventKey="frenchMaterials">Matériaux en Français</Nav.Link>
            </LinkContainer>
            <LinkContainer to={routes.gettingStartedFaq}>
              <Nav.Link eventKey="faq">FAQs</Nav.Link>
            </LinkContainer>
            <Nav.Link onClick={handleContactClick}>Contact Us</Nav.Link>
          </Nav>
        </div>
      </Navbar>
      {/* add modal component here */}
      <ContactModal
        show={contactShow}
        onHide={() => setContactShow(false)}
      />
    </>
  )
}
