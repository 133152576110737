import classNames from 'classnames';
import SearchIcon from '../../assets/icons/search.svg';
import ArrowLeft from '../../assets/icons/pagination-left.svg';
import ArrowRight from '../../assets/icons/pagination-right.svg';
import { disable } from 'workbox-navigation-preload';
import { useEffect, useState } from 'react';


export default function Paginator(props) {
  // const { placeholder = "Search modules..." } = props;
  const [disabledState] = useState(props.disableState)
  const resultsPerPage = props.resultsPerPage;
  const dataCount = props.dataCount;
  const startNumber = props.startNumber;
  const lastNumber = dataCount<startNumber+resultsPerPage-1?dataCount:startNumber+resultsPerPage-1
  let className = classNames({
    [props.className]: props.className ? true : false
  });
  const { disablePrev, disableNext } = props;

  useEffect(() => {
      console.log(disablePrev, disableNext)
  })

  return (
    <div className="paginator d-flex justify-content-end align-items-center">
    <p className="mb-0 px-3">{startNumber} - {lastNumber} of {dataCount}</p>
    <div className={className}>
      {/* <button onClick={props.onClickForPreviousPage} className={className} disabled = {disabledState === "previous" || disabledState === "all" ? true: false}> */}
      <button onClick={props.onClickForPreviousPage} className={className} disabled={disablePrev}>
        <img src={ArrowLeft} className="img-responsive btn-icon" alt="action icon" width="12" height="12"  />
      </button>

      {/* <button onClick={props.onClickForNextPage} className={className} disabled = { disabledState === "next" || disabledState === "all" ? true: false}> */}
     <button onClick={props.onClickForNextPage} className={className} disabled={disableNext}>
        <img src={ArrowRight} className="img-responsive btn-icon" alt="action icon" width="12" height="12" />
      </button>

    </div>
    </div>
  );
}
