const ADD_TOPIC = "community/duck/ADD_TOPIC";
const ADD_POST = "community/duck/ADD_POST";
const SET_TOPIC_FILTER = "community/duck/SET_TOPIC_FILTER";
const SET_SORT_FILTER = "community/duck/SET_SORT_FILTER";
const ADD_COMMENT = "community/duck/ADD_COMMENT";
const ADD_CONTRIBUTOR = "community/duck/ADD_CONTRIBUTOR";
const SET_DISCUSSION_SEARCH_FILTER = "community/duck/SET_DISCUSSION_SEARCH_FILTER";
const ADD_ACTIVITY = "community/duck/ADD_ACTIVITY";
const ADD_POST_RELATED_DISCUSSION = "community/duck/ADD_POST_RELATED_DISCUSSION";
const ADD_USER = "community/duck/ADD_USER";
const CLEAR_POST = "community/duck/CLEAR_POST";
const CLEAR_ACTIVITY = "community/duck/CLEAR_ACTIVITY";
const UPDATE_ACTIVITY = "community/duck/UPDATE_ACTIVITY";
const DELETE_ACTIVITY = "community/duck/DELETE_ACTIVITY";

export default {
  ADD_TOPIC,
  ADD_POST,
  CLEAR_POST,
  SET_TOPIC_FILTER,
  ADD_COMMENT,
  ADD_ACTIVITY,
  SET_DISCUSSION_SEARCH_FILTER,
  ADD_CONTRIBUTOR,
  ADD_POST_RELATED_DISCUSSION,
  SET_SORT_FILTER,
  ADD_USER,
  CLEAR_ACTIVITY,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY,
};