import types from "../types";
import commonTypes from "../../../Common/ducks/types";
import { combineReducers } from "redux";

function bookmarkFoldersById(state = {}, action) {
  switch (action.type) {
    case types.ADD_BOOKMARK_FOLDER: {
      const { payload } = action
      const { folderId, folderObj } = payload
      return {
        ...state,
        [folderId]: {
          ...folderObj,
          bookmarkItems: []
        } 
        
      }
    }

    case types.RENAME_BOOKMARK_FOLDER: {
      const { payload } = action
      const { folderId, newName } = payload
      const folderObj = Object.assign({}, state[folderId])
      return {
        ...state,
        [folderId]: {
          ...folderObj,
          folder_name: newName
        } 
        
      }
    }

    case types.DELETE_BOOKMARK_FOLDER: {
      const { payload } = action
      const { folderId } = payload
      const bookmarkFolders = {...state}
      delete bookmarkFolders[folderId]
      return {
        ...bookmarkFolders,
      }
    }

    case types.ADD_BOOKMARK_ITEM: {
      const { folderId, itemId } = action.payload;
      // Look up the correct folder
      const folder = state[folderId]
      console.log("folder", folderId)
      return {
        ...state,
        [folderId]: {
          ...folder,
          bookmarkItems: folder.bookmarkItems.concat(itemId)
        }
      };
    }

    case types.REMOVE_BOOKMARK_ITEM: {
      const { payload } = action;
      const { folderId, itemId } = payload;
      const folder = state[folderId]
      // return state.filter(item => item != itemId)
      return {
        ...state,
        [folderId]: {
          ...folder,
          bookmarkItems: folder.bookmarkItems.filter(item => item != itemId)
        }
      };
    }

    case commonTypes.LOGOUT_USER:
        return {}
      
    default:
      return state
  }
}

function allBookmarkFolders(state = [], action) {
  switch (action.type) {
    case types.ADD_BOOKMARK_FOLDER: {
      const { payload } = action
      const { folderId } = payload
      return Array.from(new Set(state.concat(folderId)))
    }

    case types.DELETE_BOOKMARK_FOLDER: {
      const { payload } = action
      const { folderId } = payload
      return state.filter(id => id != folderId)
    }

    case commonTypes.LOGOUT_USER:
        return []
    default:
      return state
  }
}

function defaultBookmarkFolder(state = [], action) {
  switch (action.type) {
    case types.ADD_DEFAULT_BOOKMARK_FOLDER: {
      const { payload } = action
      const { folderId } = payload
      return folderId;
    }
    default:
      return state
  }
}

const bookmarksReducer = combineReducers({
  byId: bookmarkFoldersById,
  allIds: allBookmarkFolders,
  defaultFolderId: defaultBookmarkFolder
})

export default bookmarksReducer;