import classNames from 'classnames';
import CommentsIconSvg from '../../assets/icons/comments.svg'


export default function CommentsIcon(props) {
  let className = classNames({
    'comments-icon': true,
    'button-tertiary': true,
    'btn-md': true,
    [props.className]: props.className ? true : false
  });

  return (
    <button onClick={props.onClick} className={className}>
      <img src={CommentsIconSvg} className="img-responsive btn-icon" alt="action icon" width="12" height="12" />
      {props.showText ?
        <span className="ml-2 align-middle">{props.count || "No"} {props.count === 1 ? 'Reply' : 'Replies'}</span>
      : <span className="ml-2 align-middle">{props.count}</span>
      }
      
    </button>
  );
}