import Alert from '../../components/Alert';
import React, { useEffect, useState } from 'react'
import {
  Link,
} from "react-router-dom";
// import GettingStartedFooterSection from '../../components/GettingStartedFooterSection';
import FileAlert from '../FileAlert';
import GettingStartedFooterSection from '../../components/GettingStartedFooterSection';
import FilePreviewModal from '../../components/FilePreviewModal';
import { routes } from '../../routes';



function GettingStartedFooter(props) {
 

  const [ previewModalShow, setPreviewModalShow ] = useState(false);
  const [ previewFileObj, setPreviewFileObj ] = useState(null);

  const previewFile = (fileObj) => {
    const fileUrl = fileObj.preview_file? fileObj.preview_file: fileObj.file;
    const file_type = fileObj.preview_file? "PDF Document": fileObj.file_type;
    setPreviewFileObj({file: fileUrl, title: fileObj.title, size: fileObj.size, file_type: file_type, sourceFile: fileObj.file, sourceFileType: fileObj.file_type})
    setPreviewModalShow(true);
  }

    return (
        <div className="getting-started-footer-div">
          <div className="container-xl">
            <div className="row">
              <div className="alert col-12 col-sm-10 mx-auto">
                {props.quick_start_guide 
                ? <GettingStartedFooterSection 
                    name={props.quick_start_guide?.title}
                    body={props.quick_start_guide?.details}
                    fileUrl={props.quick_start_guide?.file}
                    fileType={props.quick_start_guide?.file_type}
                    sourceFile={props.quick_start_guide?.file}
                    sourceFileType={props.quick_start_guide?.file_type}
                    previewFile={() => previewFile(props.quick_start_guide)}
                  /> 
                : null}

                {props.adaptation_checklist 
                ? <GettingStartedFooterSection 
                    name={props.adaptation_checklist?.title}
                    body={props.adaptation_checklist?.details}
                    fileUrl={props.adaptation_checklist?.file}
                    fileType={props.adaptation_checklist?.file_type}
                    sourceFile={props.adaptation_checklist?.file}
                    sourceFileType={props.adaptation_checklist?.file_type}
                    previewFile={() => previewFile(props.adaptation_checklist)}
                  /> 
                : null}
                {/* {props.files?.map(file =>  
                  <GettingStartedFooterSection 
                    name={file?.title}
                    body={file?.details}
                    fileUrl={file?.file}
                    fileType={file?.file_type}
                    sourceFile={file?.file}
                    sourceFileType={file?.file_type}
                    previewFile={() => previewFile(file)}
                  />
                )} */}
                <Alert>
                  Need further help? View <Link to={routes.gettingStartedFaq}>Frequently Asked Questions</Link> 
                  or <a href="#" onClick={props.handleContactClick}>Contact Us</a>
                </Alert>
              </div>
            </div>
        </div>
        <FilePreviewModal
          show={previewModalShow}
          onHide={() => setPreviewModalShow(false)}
          fileObj={previewFileObj}
        />
        
        </div>
    )
}

export default GettingStartedFooter
