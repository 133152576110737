import { Modal } from "react-bootstrap"
import { Formik } from "formik";
import Form from 'react-bootstrap/Form';
import Button from "../Button/Button";
import FormInput from "../FormInput/FormInput";

function AddLinkModal({ linkCallback, newTextLinkCallback, ...props }) {

  const testLink = (link) => {
    if (!/^https?:\/\/(\S+)\.(\S+)$/.test(link)) {
      return false;
    } else {
      return true;
    }
  }

  const submitHandle = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (!values.link.includes("http://") || !values.link.includes("https://")) {
        values.link = "https://" + values.link;
    }
    const { text, link } = values;
    console.log(text, link)
    try {
      // create user
      // const response = await api.Forum.createPost(topic, title, body);
      // if (response.status === 201) {
      //   // succesfully created post
      //   // setEmailSent(values.email);
      //   // close modal
      //   props.onCreatePost(response.data);
      // }
      console.log(text, link)
      if (props.text.trim() === "") {
        if (text.trim() !== "") {
          newTextLinkCallback(text, link);
        } else {
          newTextLinkCallback(link, link);
        }
      } else {
        linkCallback(link);
      }
      resetForm();
      props.onHide();
    }
    catch (error) {
      // read server validation errors
      // if (error.response.data?.non_field_errors) setNonFieldErrors(error.response.data?.non_field_errors);
      console.error(error);
    }
    setSubmitting(false);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="community-guidelines-modal add-link-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">

        </Modal.Title>
      </Modal.Header>
      <Formik
        onSubmit={submitHandle}
        initialValues={{
          text: props.text,
          link: '',
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Form className="form" noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="d-flex justify-content-start align-items-start title-section">
                <h4>Add Link</h4>
              </div>

              <FormInput
                name="text"
                label="Text to display"
                placeholder="Type here"
                value={values.text}
                onChange={handleChange}
                onBlur={handleBlur}
                // isValid={touched.title && !errors.title}
                isInvalid={touched.text && !!errors.text}
                feedback={errors.text}
                style={{ marginBottom: '24px' }}
              // required
              />
              <FormInput
                name="link"
                label="Link to"
                placeholder="URL"
                value={values.link}
                onChange={handleChange}
                onBlur={handleBlur}
                // isValid={touched.title && !errors.title}
                isInvalid={touched.link && !!errors.link}
                feedback={errors.link}
                // required
                style={{ marginBottom: '24px' }}
              />
              {/* <a className={`mb-4 test-link ${values.link && testLink(values.link) ? '' : 'text-grey hover-none'}`} href={testLink(values.link) && values.link} target="_blank">
                Test The Link
              </a> */}
              <a
                className={'mb-4 test-link'}
                href={values.link.includes("http://") || values.link.includes("https://") ? values.link : "https://" + values.link }
                target="_blank"
              >
                Test The Link
              </a>
            </Modal.Body>
            <Modal.Footer>
              <div className="row">
                <div className="col px-0 d-flex align-items-between justify-content-center">
                  <Button size="lg" onClick={props.onHide} text='Cancel' className="mr-2" />
                  <Button type="primary" size="lg" text={isSubmitting ? "Adding Link..." : "Add  Link"} submit />
                </div>
              </div>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddLinkModal;
