import types from "../types";
import commonTypes from "../../../Common/ducks/types";
import { combineReducers } from "redux";

function bookmarkItemsById(state = {}, action) {
  switch (action.type) {
    case types.ADD_BOOKMARK_ITEM: {
      const { payload } = action
      console.log("payload", payload)
      const { folderId, itemId, item, sessionType, moduleId } = payload;
      return {
        ...state,
        [itemId]: {
          ...item,
          itemId,
          folderId,
          sectionId: item.item,
          sessionType,
          moduleId,
        }
      }
    }

    case types.REMOVE_BOOKMARK_ITEM: {
      const { payload } = action
      const { itemId } = payload;
      const bookmarkItems = {...state}
      delete bookmarkItems[itemId]
      return {
        ...bookmarkItems,
      }
    }

    case commonTypes.LOGOUT_USER:
      return {};
      
    default:
      return state
  }
}

function allBookmarkItems(state = [], action) {
  switch (action.type) {
    case types.ADD_BOOKMARK_ITEM: {
      const { payload } = action;
      const { itemId } = payload;
      return Array.from(new Set(state.concat(itemId)))
    }

    case types.REMOVE_BOOKMARK_ITEM: {
      const { payload } = action;
      const { itemId } = payload;
      return state.filter(item => item != itemId)
    }

    case commonTypes.LOGOUT_USER:
      return [];

    default:
      return state
  }
}

const bookmarkItemsReducer = combineReducers({
  byId: bookmarkItemsById,
  allIds: allBookmarkItems
})

export default bookmarkItemsReducer;