import types from "../types";

const initialState = {
    byIds: {},
    allIds: []
};

export function faqsectionsReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_SECTION: {
      const { id, section } = action.payload;
      return {
        ...state,
        allIds: [...state.allIds, id],
        byIds: {
          ...state.byIds,
          [id]: {
            ...section,
          }
        }
      };
    }
    case types.ADD_SECTION_ENTRY_REF: {
      const { id, entryId } = action.payload;
      return {
        ...state,
        // allIds: [...state.allIds, id],
        byIds: {
          ...state.byIds,
          [id]: {
            ...state.byIds[id],
            entries: [...state.byIds[id]['entries'] || [], entryId]
          }
        }
      };
    }
    default:
      return state;
  }
}