import React from 'react';
import {
  withRouter,
  Link
} from "react-router-dom";
import classNames from 'classnames';
import PresentationSlidesImage from '../../assets/images/presentation_slides.png';
import Button from '../Button';
import Icon from '../Icon';




function Card1(props) {

  let className = classNames({
    "card-one": true,
    "d-flex": true,
    [props.className]: props.className ? true : false
  });


  return (
    <div className={className}>
      <div className="left"><Icon icon={props.icon} width={72} height={72} /></div>
      <div className="right">
        <h5>{props.title}</h5>
        <p className="p3">
          {props.body}
        </p>
      </div>
    </div>
  );
}

export default Card1;