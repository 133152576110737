import types from "./types";


export const loginUser= (user) => ({
  type: types.LOGIN_USER,
  payload: {
    user: user
  }
});

export const logoutUser= (user) => ({
  type: types.LOGOUT_USER,
});


export const addNews = (news) => ({
  type: types.ADD_NEWS,
  payload: {
    newsId: news.id,
    newsObj: news,
  }
});

export const addGettingStartedContent = (data) => ({
  type: types.ADD_GETTINGSTARTED,
  payload: {
    data: data,
  }
});

export const addNavigatingTheWebsiteContent = (data) => ({
  type: types.ADD_NAVIGATING_THE_WEBSITE,
  payload: {
    data: data,
  }
});

export const addAboutContent = (data) => ({
  type: types.ADD_CONTENT_ABOUT,
  payload: {
    data: data,
  }
});

export const addHomeContent = (data) => ({
  type: types.ADD_CONTENT_HOME,
  payload: {
    data: data,
  }
});

export const addSearchText = (value) => ({
  type: types.ADD_SEARCH_TEXT,
  payload: {
    value: value,
  }
});

export const addSearchResults = (data) => ({
  type: types.ADD_SEARCH_RESULTS,
  payload: {
    results: data,
  }
});

export const addColorGuides = (data) => ({
  type: types.ADD_COLOR_GUIDES,
  payload: {
    data: data,
  }
})

export const isExpandedToggle = () => {
  return {
    type: 'IS_EXPANDED_REDUCER/TOGGLE'
  }
}