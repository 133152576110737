import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import classNames from 'classnames';
import { routes } from '../../routes';

function CommunityWarning(props) {
  let className = classNames({
    'community-warning': true,
    [props.className]: props.className ? true : false
  });
  return (
    <div className={className}>
      <h6>Keep discussions respectful and constructive.<br />Read the <span onClick={props.showModal} className="read-more">Community Guidelines</span> </h6>
      <p className="p4">Any inappropriate behavior may result in account suspension. If you notice anything inappropriate, please report the post.</p>
    </div>
  );
}

export default CommunityWarning;