import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import filesize from 'filesize';
import Button from '../../components/Button';
import Nav from 'react-bootstrap/Nav';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import classNames from 'classnames';
import SittingIllustration from '../../assets/images/sitting.svg';
import PrintIcon from '../../assets/icons/print.svg';
import CloudDownloadIcon from '../../assets/icons/cloud-download.svg';
import CloseIcon from '../../assets/icons/remove.svg';
import ModalButton from '../ModalButton';
import IconButton from '../IconButton';
import filenameFromUrl from '../../utils/filenameFromUrl';
import { download } from '../../adaptors/xhr/trainingService';

function FilePreviewModal(props) {
  console.log("props", props);
  const [isLoading, setIsLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const fileUrl = encodeURIComponent(props.fileObj?.file);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handlePrint = (event) => {
    const execonload = () => {
      winPrint.print();
    };
    var url = `https://docs.google.com/gview?url=${fileUrl}&embedded=false`;
    var winPrint = window.open(url, '_blank');
    winPrint.addEventListener('load', (event) => {
      console.log('page is fully loaded');
      execonload();
    });
    winPrint.addEventListener('DOMContentLoaded', (event) => {
      // log.textContent = log.textContent + `DOMContentLoaded\n`;
      execonload();
    });
    // winPrint.onload = execonload;
    winPrint.focus();
    
  };

  
  const downloadFile = () => {
     var fileName;
 
    if(props.fileObj.sourceFile){
      fileName = filenameFromUrl(props.fileObj?.sourceFile)
      download(props.fileObj?.sourceFile, fileName);
    }
    else{
       fileName = filenameFromUrl(props.fileObj?.file)
        download(props.fileObj?.file, fileName);
    }
   
  }

  return (
    <Modal
      {...props}
      fullscreen="true"
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="file-preview-modal "
    >
      <Modal.Header  className="d-sm-flex flex-row   align-items-sm-center justify-content-between">
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="mb-0">{props.fileObj?.title}</h5>
          <span className="s1">{props.fileObj?.size ? filesize(props.fileObj?.size) : ''}</span>
        </Modal.Title>
        <IconButton icon={CloseIcon} width="24" height="24" className="close-btn d-sm-none" onClick={props.onHide} />
        <div className="d-none d-sm-block">
          <ModalButton size="md" text='Print' icon={PrintIcon} minWidth onClick={handlePrint} />
          <ModalButton size="md" text="Download" icon={CloudDownloadIcon} minWidth onClick={downloadFile} className="mr-4"/>
          <IconButton icon={CloseIcon} width="24" height="24" className="close-btn" onClick={props.onHide} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="embed-section d-flex justify-content-sm-start justify-content-lg-center title-section ">
          {props.fileObj?.file_type === "PDF Document"? 
        <Document
          file={{url: props.fileObj?.file}}
           loading="Loading File"
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from(
            new Array(numPages),
            (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ),
          )}
        </Document>
        : props.fileObj?.preview_file ? 
        <Document
            file={{url: props.fileObj?.preview_file}}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(
              new Array(numPages),
              (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ),
            )}
        </Document>
        : <iframe 
          id="fileview"
          name="previewFrame"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${fileUrl}&embedded=true&wdPrint=1&wdEmbedCode=0`}
          frameborder="0" height="500px" width="100%"
          className="iframe"
        ></iframe>}
        
        {/* <object data={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`} type="application/pdf" width="100%" height="100%">
        </object> */}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FilePreviewModal;