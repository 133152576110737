import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import CommentsIcon from '../CommentsIcon';
import timeagoFromDate from '../../utils/timeagoFromDate';
import UserThumb from '../UserThumb';
import SearchEmpty from '../SearchEmpty/SearchEmpty';
import { routes } from '../../routes';

function DiscussionList(props) {
  return (
      <ul className="discuss-list-group list-group list-group-flush">
        {props.discussions?.map((item, index) => {
          return (
            <li href="#" key={`numlist-${index}`} className="list-group-item list-group-item-action active" aria-current="true">
              <div className="d-flex w-100 mt-3 justify-content-start align-items-start" >
                <div className="thumbnail h-100 d-flex justify-content-center align-items-start">
                  <Link to={`/community/user/${item?.author?.id}`}>
                    <UserThumb 
                    thumbnail={item.author.profile_picture} 
                    firstName={item?.author?.first_name} 
                    lastName={item?.author?.last_name} 
                  />
                  </Link>
                </div>
                <div className="individualDiscussion"> 
                  <Link to={`${routes.discussions}/${item.slug}/`}><h6 className="mb-1">{item.title}</h6></Link>
                  <p className="p4 mb-1">{item.body}</p>

                  <div className="author-info d-flex justify-content-between align-items-center">
                  <div>
                    <Link to={`/community/user/${item?.author?.id}`}>
                        <span className="author">{item.author.first_name} {item.author.last_name}</span>
                    </Link>
                    
                    <span className="timestamp-info">{timeagoFromDate(item.created_at)} in Using the platform</span>
                  </div>
                  <Link to={`${routes.discussions}/${item.slug}/`}><CommentsIcon count={item.comment_count} /></Link>
                </div>
                </div>
              </div>
            </li>
          )
        })}

        {!props.discussions.length 
        ? <SearchEmpty noAction message="Couldn't find any discussions under this category" />
        : null
      }
        
      </ul>
  );
}

export default DiscussionList;