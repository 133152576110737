import classNames from 'classnames';
import { useHistory } from "react-router-dom";
import Icon from '../../components/Icon';
import ResourceSvg from '../../assets/icons/resources.svg';
import Placeholder from '../../assets/icons/placeholder.svg';
import Button from '../../components/Button';
import { routes } from '../../routes';

export default function HighlightsSection(props) {
  const history = useHistory();
  return (
    <div className="home-highlight-section">
        <div className="container-xl">
          <div className="row px-sm-3 home-highlights justify-content-center align-items-center">
            {props.cards?.map(card => 
            <div className="col-12 col-md-4 mb-3 mb-sm-0">
              <Icon icon={card.icon} width={112} height={112} />
              <h4>{card.title}</h4>
              <p className="p2">
                {card.body}
              </p>
              <Button size="md" type="outline" text={card.button_text} onClick={() => history.push(routes[card.button_link])} className="mt-4" />
            </div>
            )}

            {/* <div className="col-11 col-sm  mb-3 mb-sm-0">
              <Icon icon={Placeholder} width={112} height={112} />
              <h4>Guides for Trainers</h4>
              <p className="p2">
                This section contains links to resources for trainers adapting and implementing TRP materials
              </p>
              <Button size="md" type="outline" text="View Guides"  onClick={() => history.push(routes.trainingGuides)} />
            </div>

            <div className="col-11 col-sm mb-3 mb-sm-0">
              <Icon icon={Placeholder} width={112} height={112} />
              <h4>Connect with community</h4>
              <p className="p2">
                Join the TRP Community and connect with trainers from around the globe
              </p>
              <Button size="md" type="outline" text="View Forum"  onClick={() => history.push(routes.discussions)} />
            </div> */}
        </div>
        <p className="p2">
          The TRP contains curriculum components and tools needed to design, 
          implement, and evaluate training. It offers essential resources 
          for family planning (FP) and reproductive health trainers, supervisors, 
          and program managers. The entire package is designed to support up-to-date 
          training on family planning and reproductive health. 
        </p>
        </div>
      </div>
  );
}