import { axiosInstance } from './xhrConfig';

const colorGuide = {
    presentationSlide: () => 
        axiosInstance.get(`api/content/colorguides/presentation-slides`),
    spanishContent: () => 
        axiosInstance.get(`api/content/colorguides/spanish-content`),
    frenchContent: () => 
        axiosInstance.get(`api/content/colorguides/french-content`),
    
}

export default colorGuide;