import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../Button";
import Nav from "react-bootstrap/Nav";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import classNames from "classnames";
import SittingIllustration from "../../assets/images/sitting.svg";
import { routes } from "../../routes";
import IconButton from "../IconButton";
import CopyIcon from "../../assets/icons/copy.png";

function ContactModal(props) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = (copyText) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="contact-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="d-flex justify-content-start align-items-start title-section">
            <h4>Contact Us</h4>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="p3">
          For all queries, please email us at <a href="mailto:info@fptraining.org">info@fptraining.org</a>
          {/* <span className="mx-2 copy-text">
            {isCopied ? (
              "Copied!"
            ) : (
              <IconButton
                icon={CopyIcon}
                width="14"
                height="16"
                onClick={() => handleCopyClick("info@fptraining.org")}
                title='click to copy'
                placement='top'
                toggle='tooltip'
              />
            )}
          </span> */}
        </p>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}

export default ContactModal;
