import { DOWNLOADS_SORT_FILTERS } from "../../../constants";

// modules selectors
export const getModuleState = store => store.training.modules;

export const getModuleList = store =>
  getModuleState(store) ? getModuleState(store).allIds : [];

export const getModuleById = (store, id) =>
  getModuleState(store) ? { ...getModuleState(store).byIds[id], id } : {};

export const getModules = store =>
  getModuleList(store).map(id => getModuleById(store, id));

export const getModuleSearchText = (store) =>  {
  return store.training.searchFilter
}

export const getModuleSearch = (store, searchText) => {
  let searchedModules = getModules(store)
  if(searchText && searchText != null && searchText.length != 0){
    searchedModules = searchedModules.filter(data => data.name.toLowerCase().includes(searchText.toLowerCase()));
  }
  return searchedModules
}

// module overview
export const getModuleLearningObjectives = (store) => store.training.moduleLearningObjective

export const getModuleLearningObjectivesList = store =>
  getModuleLearningObjectives(store) ? getModuleLearningObjectives(store).allIds : [];

export const getLearningObjectivesById = (store, id) =>
  getModuleLearningObjectives(store) ? { ...getModuleLearningObjectives(store).byIds[id], id } : {};

export const getLearningObjectives = store => {
  return getModuleLearningObjectivesList(store).map(id=> getLearningObjectivesById(store, id))

}

// module session `sections` selectors
export const getSessionprepSectionState = store => store.training.sessionprepSections;

export const getSessionprepSectionList = store =>
  getSessionprepSectionState(store) ? getSessionprepSectionState(store).allIds : [];

export const getSessionprepSectionById = (store, id) =>
  getSessionprepSectionState(store) ? { ...getSessionprepSectionState(store).byId[id], id } : {};

export const getSessionprepSections = (store) =>
  getSessionprepSectionList(store)?.map(id => getSessionprepSectionById(store, id));


// module session selectors
export const getSessionprepState = store => store.training.sessionprepFiles;

export const getSessionprepList = store =>
  getSessionprepState(store) ? getSessionprepState(store).allIds : [];

export const getSessionprepById = (store, id) =>
  getSessionprepState(store) ? { ...getSessionprepState(store).byId[id]} : {};

export const getSessionpreps = (store) =>
  getSessionprepList(store)?.map(id => getSessionprepById(store, id));


  export const getSessionprepsBySearchSortFilter = (store, sortFilter, searchText) => {
    console.log("Sorting>>>")
    let allDownloads = getSessionpreps(store);
    if (searchText) {
      allDownloads = allDownloads.filter(file => file.title.toLowerCase().includes(searchText.toLowerCase()));
    }
    switch (sortFilter) {
      case DOWNLOADS_SORT_FILTERS.FILESIZE:
        return allDownloads.sort((a,b) => (a.size > b.size) ? 1 : ((b.size > a.size) ? -1 : 0));
      case DOWNLOADS_SORT_FILTERS.FILEFORMAT:
        return allDownloads.sort((a,b) => (a.file_type > b.file_type) ? 1 : ((b.file_type > a.file_type) ? -1 : 0))
      case DOWNLOADS_SORT_FILTERS.DEFAULT:
        return allDownloads.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0))
      default:
        return allDownloads;
    }
  };


//modulecategory selectors
export const getModuleCategoryState = store => store.training.moduleCategories;

export const getModuleCategoryList = store =>
  getModuleCategoryState(store) ? getModuleCategoryState(store).allIds : [];

export const getModuleCategoryById = (store, id) =>
  getModuleCategoryState(store) ? { ...getModuleCategoryState(store).byIds[id], id } : {};

export const getModuleCategories = store =>
  getModuleCategoryList(store).map(id => getModuleCategoryById(store, id));

export const getModuleCategoryFilter = store => store.training.moduleCategories.categoryFilter;

export const getModuleCategoriesByFilter = (store, categoryFilter ) => {
  if(categoryFilter != null){
    return Array(getModuleCategoryById(store, categoryFilter))
  }
  return getModuleCategories(store);
}


// Downloads selectors
export const getDownloadsState = store => store.training?.downloads;

export const getDownloadList = store =>
  getDownloadsState(store) ? getDownloadsState(store).allIds : [];

export const getDownloadById = (store, id) =>
  getDownloadsState(store) ? { ...getDownloadsState(store).byIds[id], id } : {};

export const getDownloads = store =>
  getDownloadList(store).map(id => getDownloadById(store, id));

export const getDownloadsBySearchSortFilter = (store, sortFilter, searchText) => {
  let allDownloads = getDownloads(store);
  if (searchText) {
    allDownloads = allDownloads.filter(file => file.title.toLowerCase().includes(searchText.toLowerCase()));
  }
  switch (sortFilter) {
    case DOWNLOADS_SORT_FILTERS.FILESIZE:
      return allDownloads.sort((a,b) => (a.size > b.size) ? 1 : ((b.size > a.size) ? -1 : 0));
    case DOWNLOADS_SORT_FILTERS.FILEFORMAT:
      return allDownloads.sort((a,b) => (a.file_type > b.file_type) ? 1 : ((b.file_type > a.file_type) ? -1 : 0))
    case DOWNLOADS_SORT_FILTERS.DEFAULT:
    default:
      return allDownloads;
  }
};

export const session_types = {
  module_session_plans: "module_session_plans",
  presentation_slides: "presentation_slides",
  references: "references",
  handouts: "handouts",
  evaluation_tools: "evaluation_tools"
}


// Bookmarks Folders
export const getBookmarksState = store => store.training.bookmarkFolders;

export const getBookmarksList = store =>
  getBookmarksState(store) ? getBookmarksState(store).allIds : [];

export const getBookmarkFolderById = (store, id) =>
  getBookmarksState(store) ? { ...getBookmarksState(store).byId[id], id } : {};

export const getBookmarksFolders = store =>
  getBookmarksList(store).map(id => getBookmarkFolderById(store, id));

export const getDefaultBookmarkFolderId = store => getBookmarksState(store).defaultFolderId;

export const getBookmarksFlat = store => {
  let flatList = [];
    getBookmarksFolders(store).map(folder => {
      flatList = folder.module_session_plans?.length ? [...flatList, ...folder.module_session_plans] : flatList;
      flatList = folder.presentation_slides?.length ? [...flatList, ...folder.presentation_slides] : flatList;
      flatList = folder.references?.length ? [...flatList,  ...folder.references] : flatList;
      flatList = folder.handouts?.length ? [...flatList,  ...folder.handouts] : flatList;
      flatList = folder.evaluation_tools?.length ? [...flatList, ...folder.evaluation_tools] : flatList;
    })
  return flatList;
}

// Bookmark Items
export const getBookmarkItemsState = store => store.training.bookmarkItems;

export const getBookmarkItemsList = store =>
  getBookmarkItemsState(store) ? getBookmarkItemsState(store).allIds : [];

export const getBookmarkItemById = (store, id) =>
  getBookmarkItemsState(store) ? { ...getBookmarkItemsState(store).byId[id], pk: getBookmarkItemsState(store).byId[id]['id'], id } : {};

export const getBookmarkItems = store =>
  getBookmarkItemsList(store).map(id => getBookmarkItemById(store, id));

// Bookmarks context
export const getBookmarkContextState = store => store.training.bookmarkContext;


export const getBookmarksBySearchSortFilter = (store, sortFilter, searchText) => {
  let allBookmarks = getBookmarkItems(store);
  if (searchText) {
    allBookmarks = allBookmarks.filter(file => file.title?.toLowerCase().includes(searchText.toLowerCase()));
  }
  switch (sortFilter) {
    case DOWNLOADS_SORT_FILTERS.FILESIZE:
      return allBookmarks.sort((a,b) => (a.size > b.size) ? 1 : ((b.size > a.size) ? -1 : 0));
    case DOWNLOADS_SORT_FILTERS.FILEFORMAT:
      return allBookmarks.sort((a,b) => (a.file_type > b.file_type) ? 1 : ((b.file_type > a.file_type) ? -1 : 0))
    case DOWNLOADS_SORT_FILTERS.DEFAULT:
    default:
      return allBookmarks;
  }
};

export const getBookmarkItemsByFolderFilter = (store, folderId) => {
  if (folderId != null) {
    const bookmarkItemIds = getBookmarkFolderById(store, folderId) ? getBookmarkFolderById(store, folderId).bookmarkItems : [];
    return bookmarkItemIds.map(id => getBookmarkItemById(store, id));
  }
  else {
    return getBookmarkItems(store);
  }

}


// Training guides selectors
export const getGuidesState = store => store.training.guides;

export const getGuidesList = store =>
  getGuidesState(store) ? getGuidesState(store).allIds : [];

export const getGuideById = (store, id) =>
  getGuidesState(store) ? { ...getGuidesState(store).byId[id], id } : {};

export const getGuides = store =>
  getGuidesList(store).map(id => getGuideById(store, id));


// Training tools selectors
export const getToolsState = store => store.training.tools;

export const getToolsList = store =>
  getToolsState(store) ? getToolsState(store).allIds : [];

export const getToolById = (store, id) =>
  getToolsState(store) ? { ...getToolsState(store).byId[id], id } : {};

export const getTools = store =>
  getToolsList(store).map(id => getToolById(store, id));

// French Materials selectors
export const getFrenchSectionsState = store => store.training.frenchSections;
export const getFrenchContentState = store => store.training.frenchContent;
export const getFrenchVideosState = store => store.training.frenchVideos;

export const getFrenchSectionsList = store =>
  getFrenchSectionsState(store) ? getFrenchSectionsState(store).allIds : [];

export const getFrenchSectionsById = (store, id) =>
  getFrenchSectionsState(store) ? { ...getFrenchSectionsState(store).byId[id], id } : {};

export const getFrenchSections = store =>
  getFrenchSectionsList(store).map(id => getFrenchSectionsById(store, id));


export const getFrenchContentList = store =>
  getFrenchContentState(store) ? getFrenchContentState(store).allIds : [];

export const getFrenchContentById = (store, id) =>
  getFrenchContentState(store) ? { ...getFrenchContentState(store).byId[id], id } : {};

export const getFrenchContent = store =>
  getFrenchContentList(store).map(id => getFrenchContentById(store, id));

export const getFrenchVideos = store =>
  getFrenchVideosState(store) ? getFrenchVideosState(store).byId : {};

export const getFrenchContentBySearchFilter = (store, searchText) => {
    let allContent = getFrenchContent(store);
    if (searchText) {
      allContent = allContent.filter(file => file.title.toLowerCase().includes(searchText.toLowerCase()));
    }
    return allContent;
};

// Spanish Materials selectors
export const getSpanishSectionsState = store => store.training.spanishSection;
export const getSpanishContentState = store => store.training.spanishContent;
export const getSpanishVideosState = store => store.training.spanishVideos;

export const getSpanishSectionsList = store =>
  getSpanishSectionsState(store) ? getSpanishSectionsState(store).allIds : [];

export const getSpanishSectionsById = (store, id) =>
  getSpanishSectionsState(store) ? { ...getSpanishSectionsState(store).byId[id], id } : {};

export const getSpanishSections = store =>
  getSpanishSectionsList(store).map(id => getSpanishSectionsById(store, id));


export const getSpanishContentList = store =>
  getSpanishContentState(store) ? getSpanishContentState(store).allIds : [];

export const getSpanishContentById = (store, id) =>
  getSpanishContentState(store) ? { ...getSpanishContentState(store).byId[id], id } : {};

export const getSpanishContent = store =>
  getSpanishContentList(store).map(id => getSpanishContentById(store, id));

// export const getSpanishVideosList = store =>
//   getSpanishVideosState(store) ? getSpanishVideosState(store).allIds : [];

// export const getSpanishVideosById = (store, id) =>
//   getSpanishVideosState(store) ? { [id]: getSpanishVideosState(store).byId[id] } : {};

export const getSpanishVideos = store =>
  getSpanishVideosState(store) ? getSpanishVideosState(store).byId : {};

export const getSpanishContentBySearchFilter = (store, searchText) => {
    let allContent = getSpanishContent(store);
    if (searchText) {
      allContent = allContent.filter(file => file.title.toLowerCase().includes(searchText.toLowerCase()));
    }
    return allContent;
};
