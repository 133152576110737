import React from 'react';
import {
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import filesize from 'filesize';
import Button from '../Button';
import ButtonXs from '../ButtonXs';
import BookmarkButton from '../BookmarkButton';
import filenameFromUrl from '../../utils/filenameFromUrl';
import { download } from '../../adaptors/xhr/trainingService';
import DownloadIcon from '../../assets/icons/download.svg';
import BookmarkIcon from '../../assets/icons/bookmark.svg';
import { routes } from '../../routes';

function UserBookmarkItem(props) {
  const history = useHistory();
  const fileUrl = props.item.preview_file? props.item.preview_file: props.item.url? props.item.url: props.item.file
  const file_type = props.item.preview_file? "PDF Document": props.item.file_type;
  let fileType = "";
  switch(props.item.file_type) {
    case "Word Document":
      fileType = "Word Doc";
      break;
    case "PDF Document":
      fileType = "PDF";
      break;
    case "Presentation Slide":
      fileType = "PPT"
      break;
    default:
      fileType = "Document"
      break
  }

  const location = useLocation();  
  console.log("PATH", location.pathname)

  function openFile() {
    console.log('openFile');
    if (props.item.type === "module") history.push(`${routes.training}/${props.item.pk}/`);
    else if (props.item?.url) 
      window.open(props.item?.url, "_blank");
    else
      // props.previewFile({file: fileUrl, size: props.item.size, title: props.item.title, file_type: file_type, sourceFile: props.item.url, sourceFileType: props.item.file_type})
      props.previewFile(props.item);

  }

  function downloadFile() {
    const fileName = filenameFromUrl(props.item.file)
    download(props.item.file, fileName);
  }

  return (
      <ul className="bookmark-item-list list-group list-group-flush d-flex flex-row justify-content-start align-items-start">
        <BookmarkButton 
          bookmarked={true}   
          // addBookmark={() => props.addBookmark(props.sectionId, props.fileId, props.item)}
          removeBookmark={() => props.removeBookmark(props.item.folderId, props.item.itemId, props.item)}
          className=""
        />
        
        <li href="#" className="list-group-item list-group-item-action active" aria-current="true">
          <div className="d-flex w-100 justify-content-between align-items-center">

            {props.type==="module"
            ? <div>
                <a href="#" onClick={openFile}><p className="p3 mb-1 title">{props.item.name}</p></a>
                <small className="s1">Module</small>
              </div>
            :  <div>
                <a href="#" onClick={openFile}><p className="p3 mb-1 title">{props.item.title}</p></a>
                {!props.item?.url 
                ? <>
                  <small className="s1">{fileType}</small>
                  <small className="s1"> • </small>
                  <small className="s1">{`${props.item.size && !isNaN(props.item.size) ? filesize(props.item.size) : ''}`}</small>
                  </>
                : <small className="s1">Reference Link</small>
                }
          </div>
            }
            
           
            <div className="d-flex justify-content-center align-items-center">
              <Button 
                size="sm" 
                text="View" 
                className="mx-2 mr-1" 
                onClick={openFile} 
                hideOnXs
              />

              
              
              {props.type!="module" && !props.item?.url 
                ? <Button 
                  type="outline" 
                  size="sm" text="Download" 
                  icon={DownloadIcon} 
                  onClick={downloadFile} 
                  hideOnXs
                />
                : null
              }
               {/* <BookmarkButton 
                    bookmarked={props.bookmarkItemsIds?.includes(props.sessionfileId)}   
                    addBookmark={() => props.addBookmark(props.sectionId, props.fileId, props.item)}
                    removeBookmark={() => props.removeBookmark(props.sectionId, props.fileId, props.item)}
                    className=""
                  /> */}
              {!props.item?.url 
                ? <ButtonXs type="outline" size="sm" text="View" className="mx-2" onClick={downloadFile} icon={DownloadIcon} />
                : null
              }
            </div>
          </div>
        </li>
      </ul>
  );
}

export default UserBookmarkItem;